import React, { memo, useEffect } from 'react';
import { useStorageValue } from '../../../hooks/useStorageValue';
import { StorageField } from '../../../types';
import { useSelector } from '../../../hooks/useSelector';
import vkBridge from '@vkontakte/vk-bridge';
import { useAppContext } from 'src/components/AppContext';

/**
 * Если пользователь уже посещал приложение, предлагаем ему добавить приложение
 * на домашний экран, при условии, что оно еще не добавлено и функция поддерживается
 */
const AddToHomeScreenSuggest: React.FC = memo(() => {
  const [suggestShown, setSuggestShown] = useStorageValue(StorageField.AddToHomeScreenSuggestShown);
  const isFirstVisit = useSelector((state) => state.user.isFirstVisit);

  const { statEvents } = useAppContext();

  useEffect(() => {
    if (isFirstVisit || suggestShown) return;

    vkBridge
      .send('VKWebAppAddToHomeScreenInfo')
      .catch(() => ({
        is_feature_supported: false,
        is_added_to_home_screen: false,
      }))
      .then((homeScreenInfo) => {
        if (!homeScreenInfo.is_feature_supported) return;

        setSuggestShown(true);

        if (!homeScreenInfo.is_added_to_home_screen) {
          statEvents.push('add_to_home_screen_shown', {});
          vkBridge
            .send('VKWebAppAddToHomeScreen')
            .then(() => statEvents.push('add_to_home_screen_accepted', {}))
            .catch(() => null);
        }
      });
  }, []); // eslint-disable-line

  return null;
});

export default AddToHomeScreenSuggest;
