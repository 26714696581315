import React, { memo, useState, useCallback, useEffect } from 'react';
import { Subtract } from '../../types/utility';
import {convertNewsFeedToMiniPost, isMiniPost, MiniPost} from '../../types/miniPost';
import { useSelector } from '../../hooks/useSelector';
import { useActions } from '../../hooks/useActions';
import { currencyActions } from '../../redux/reducers/currency';
import { useAppContext } from '../AppContext';
import {NewsfeedGetResponse} from "@vkontakte-internal/api-schema-typescript"
import * as Sentry from '@sentry/browser';

const PAGE_SIZE = 15;

export interface WithFeedInjectedProps {
  feed: MiniPost[];
  feedLoading: boolean;
  feedError: boolean;
  refetchFeed(): void;
  loadFeedNextPage(): void;
}

export function withFeed<P extends WithFeedInjectedProps>(
  Component: React.FC<P>
): React.FC<Subtract<P, WithFeedInjectedProps>> {
  return memo((props) => {
    const { vkClient } = useAppContext();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [nextFrom, setNextFrom] = useState<string|boolean>("");

    const feed = useSelector((state) => state.currency.feed);
    const setFeed = useActions(currencyActions.setFeed);
    const pushFeed = useActions(currencyActions.pushFeed);

    const fetchFeed = useCallback(
      (startFrom: string, count: number): Promise<null | MiniPost[]> => {
        setLoading(true);

        return vkClient
          .call('execute.getFinancesFeed', { startFrom, count })
          .then((data) => {
            const res = data as NewsfeedGetResponse;

            // Признак окончания ленты -- пустой массив постов
            setNextFrom( res.items?.length ? res.next_from||"" : false);
            const posts = convertNewsFeedToMiniPost(res);
            const isValidPosts = Array.isArray(posts) && posts.every(isMiniPost);

            if (!isValidPosts) {
              throw new Error('Unknown response type');
            }
            setError(false);
            return posts as MiniPost[];
          })
          .catch(e => {
            setError(true);
            Sentry.captureException(e, {
              tags: {
                feed:"1",
              }
            })
            return null;
          })
          .finally(() => setLoading(false));
      },
      [vkClient]
    );

    const refetchFeed = useCallback(() => {
      fetchFeed("", PAGE_SIZE).then((posts) => {
        posts && setFeed(posts);
      });
    }, [fetchFeed, setFeed]);

    const loadMore = useCallback(() => {
      // Лента может кончится, поэтому дальше не грузим
      if (typeof nextFrom !== 'boolean') {
        fetchFeed(nextFrom, PAGE_SIZE).then((posts) => {
          posts && pushFeed(posts);
        });
      }
    }, [fetchFeed, pushFeed, nextFrom]);

    useEffect(() => {
      feed.length === 0 && refetchFeed();
    }, []); // eslint-disable-line

    return (
      <Component
        {...(props as P)}
        refetchFeed={refetchFeed}
        loadFeedNextPage={loadMore}
        feedError={error}
        feedLoading={loading}
        feed={feed}
      />
    );
  });
}
