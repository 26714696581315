import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import CurrencyIcon from './CurrencyIcon';
import c from 'classnames';

const useStyle = makeStyles({
  title: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    width: '100%',
    justifyContent: 'flex-end',
  },
  title__content: {
    marginLeft: '0.625em',
    overflow: 'hidden',
  },
  title__name: {},
  title_large: {
    '& $title__content': {
      marginLeft: 12,
    },
    '& $title__name': {
      fontSize: 17,
      lineHeight: '22px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  title__description: {
    fontSize: 13,
    lineHeight: '16px',
    color: 'var(--segmented_control_tint)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

interface CurrencyTitleProps extends React.HTMLAttributes<HTMLDivElement> {
  abbr: string;
  name?: string;
}

const CurrencyTitle: React.FC<CurrencyTitleProps> = memo(({ abbr, name, className, ...props }) => {
  const isLarge = typeof name === 'string';
  const mc = useStyle();

  return (
    <div className={c(mc.title, { [mc.title_large]: isLarge }, className)} {...props}>
      <div className={mc.title__content}>
        <div className={mc.title__name}>{abbr}</div>
        {isLarge && <div className={mc.title__description}>{name}</div>}
      </div>
      <CurrencyIcon id={abbr} size={isLarge ? 40 : '1.25em'} />
    </div>
  );
});

export default CurrencyTitle;
