import { createContext } from 'react';
import { StatEventsInstance } from '../../utils/lib/statEvents/types';
import { CurrencyEvents } from '../../types/statEvents';
import { LaunchParams } from 'src/types';
import { Config } from 'src/config';
import { VkClientInstance } from 'src/utils/lib/vkClient/types';
import { ApolloClient } from '@apollo/client';

const empty = () => null as any;

export interface AppContext {
  statEvents: StatEventsInstance<CurrencyEvents>;
  vkClient: VkClientInstance;
  launchParams: LaunchParams;
  config: Config;
  isOKClient: boolean;
  apolloClient: ApolloClient<any>;
}

export default createContext<AppContext>({
  statEvents: {
    destroy: empty,
    send: empty,
    push: empty,
  },
  vkClient: {
    call: () => null as any,
  },
  apolloClient: {} as any,
  launchParams: {
    accessTokenSettings: [],
    appId: 0,
    areNotificationsEnabled: false,
    isAppUser: false,
    isFavorite: false,
    language: 'ru',
    platform: 'desktop_web',
    ref: 'other',
    restrictions: [],
    userId: 0,
    groupId: null,
    viewerGroupRole: null,
    sign: '',
    ts: null,
    client: null,
  },
  config: {} as any,
  isOKClient: false,
});
