import TabsItem, { TabsItemProps } from '@vkontakte/vkui/dist/components/TabsItem/TabsItem';
import styled from '@material-ui/styles/styled';

export default styled(TabsItem)({
  maxWidth: '70vw!important',
  cursor: 'pointer',
  '--text_secondary': 'var(--steel_gray_400)',
  flexGrow: '0!important' as any,
  '--accent': 'var(--blue_400)',
  '& .TabsItem__in': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '14px 0',
    fontWeight: 400,
    fontSize: 15,
  },
  '&:after': {
    bottom: '0px!important',
    zIndex: 2,
    borderRadius: '0!important',
  },
  '&:first-child': {
    paddingLeft: 24,
    '&:after': {
      width: 'calc(100% - 40px)',
      left: 24,
    },
  },
  '&:last-child': {
    paddingRight: 24,
    '&:after': {
      width: 'calc(100% - 40px)',
    },
  },
  '&:not(.TabsItem--selected):hover:after': {
    backgroundColor: 'var(--steel_gray_140)',
  },
}) as React.FC<TabsItemProps>;
