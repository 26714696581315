import config from '../config';
import queryString from 'query-string';

interface OERProxyApi {
  // возвращает актуальный список курсов валют
  'latest.json': {
    params: {
      // валюта, относительно которой показывать остальной курс (по умолчанию USD)
      base?: string;
      // добавить альренативные валюты
      show_alternative?: boolean;
    };
    response: {
      // время обновления данных (в секундах)
      timestamp: number;
      rates: {
        [symbol: string]: number;
      };
    };
  };
  // возвращает список курсов валют указанного дня
  'historical/:date.json': {
    params: {
      // наступившая дата (в формате YYYY-MM-DD по UTC), не ранее 1 января 1999
      date: string;
      // валюта, относительно которой показывать остальной курс (по умолчанию USD)
      base?: string;
      // добавить альренативные валюты
      show_alternative?: boolean;
    };
    response: {
      // время обновления данных (в секундах)
      timestamp: number;
      rates: {
        [symbol: string]: number;
      };
    };
  };
  // динамика курсов валют за указанный интервал
  'time-series.json': {
    params: {
      // начало интервала (в формате YYYY-MM-DD по UTC), не ранее 32 дней от start
      start: string;
      // конец интервала (в формате YYYY-MM-DD по UTC), end > start
      end: string;
      // аббревиатуры запрашиваемых валют (через запятую)
      symbols?: string;
      // валюта, относительно которой показывать остальной курс (по умолчанию USD)
      base?: string;
      // добавить альренативные валюты
      show_alternative?: boolean;
    };
    response: {
      // начало интервала (в формате YYYY-MM-DD по UTC)
      start_date: string;
      // конец интервала (в формате YYYY-MM-DD по UTC)
      end_date: string;
      // время обновления данных (в секундах)
      timestamp?: number;
      rates: {
        [date: string]: {
          [symbol: string]: number;
        };
      };
    };
  };
}

const ENDPOINT_PATTERN = '#{endpoint}';

// URL запроса со вставкой ${endpoint}, которая заменится на нужный эндпоинт
const baseUrl =
  config.oerProxyHttpUrl.indexOf(ENDPOINT_PATTERN) === -1
    ? `${config.oerProxyHttpUrl}/${ENDPOINT_PATTERN}`
    : config.oerProxyHttpUrl;

export const oerProxyApi = <M extends keyof OERProxyApi, P = OERProxyApi[M]['params'], R = OERProxyApi[M]['response']>(
  endpoint: M,
  params: P
): Promise<R> => {
  const usingParams: { [key: string]: any } = { ...params };

  const rawUrl = baseUrl.replace(ENDPOINT_PATTERN, endpoint).replace(/:(\w+)/, (_, urlParam) => {
    const result = usingParams[urlParam];
    delete usingParams[urlParam];
    return result;
  });

  const urlComponents = queryString.parseUrl(rawUrl);
  const url = queryString.stringifyUrl({ url: urlComponents.url, query: { ...urlComponents.query, ...usingParams } });

  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        throw new Error(`${data.status} ${data.message}`);
      }

      return data;
    });
};
