import { useState, useEffect } from 'react';
import { useRouteNode } from 'react-router5';
import useThrottle from './useThrottle';
import usePrevious from './usePrevious';

const QUERY_KEY = 'q';

const useSearchQuery = (routeNode: string) => {
  const { route, router } = useRouteNode(routeNode);
  const q = decodeURIComponent(route.params[QUERY_KEY] || '');

  const [searchValue, setSearchValue] = useState<string>(q);

  const newSearchValue = useThrottle(searchValue, 500);
  const prevSearchValue = usePrevious(newSearchValue) || '';

  useEffect(() => {
    setSearchValue(q);
  }, [q]);

  useEffect(() => {
    if (q === newSearchValue) return;
    const isSearchMode = prevSearchValue !== '';

    if (newSearchValue === '' && isSearchMode) {
      window.history.back();
    } else {
      router.navigate(
        route.name,
        { ...route.params, [QUERY_KEY]: encodeURIComponent(newSearchValue) },
        { replace: isSearchMode }
      );
    }
  }, [newSearchValue, prevSearchValue]); // eslint-disable-line

  return [searchValue, setSearchValue] as const;
};

export default useSearchQuery;
