import { CurrencyDictionaries } from '../utils/exchangeRatesAPI';
import config from '../config';

const baseCurrencyIdDetector = (countryId: number, language: string, data: CurrencyDictionaries): string => {
  const detectedCountry = Object.entries(data.currency_countries).find(([, countryIds]) =>
    countryIds.includes(countryId)
  );

  if (detectedCountry) {
    return detectedCountry[0];
  }

  const detectedLanguage = Object.entries(data.currency_languages).find(([, languages]) =>
    languages.includes(language)
  );

  if (detectedLanguage) {
    return detectedLanguage[0];
  }

  return config.fallbackBaseCurrencyId;
};

export default baseCurrencyIdDetector;
