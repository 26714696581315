import React, { memo, useCallback, useEffect } from 'react';
import { List } from '@vkontakte/vkui';
import {
  Search,
  Group,
  SimpleCell,
  Placeholder,
  Div,
  Header,
  Cell,
  isDesktop,
  getPlatformClassName,
  Separator,
} from '@overrided-vkui';
import { L } from '../../../lang/L';
import CurrencyLabel from '../../atomic/CurrencyLabel';
import { makeStyles } from '@material-ui/styles';
import useBaseCurrency from '../../../hooks/currency/useBaseCurrency';
import CurrenciesSearchSelectList from '../../partial/CurrenciesSearchSelectList';
import useSelectedCurrencies from '../../../hooks/currency/useSelectedCurrencies';
import Icon24ChevronCompactRight from '@vkontakte/icons/dist/24/chevron_compact_right';
import useAsync from '../../../hooks/useAsync';
import { throttle } from 'throttle-debounce';
import { Taptic } from '../../../utils/taptic';
import { useAppContext } from 'src/components/AppContext';

const SEPARATOR_MARGIN = 8;

interface StyleProps {
  currenciesCount: number;
  widgetCurrenciesCount: number;
}

const useStyles = makeStyles<unknown, StyleProps>({
  search: {
    position: 'sticky',
    top: 0,
    zIndex: 3,
  },
  header: {
    '&--desktop': {
      height: 'initial!important',
      '& .Header__content': {
        fontSize: '14px!important',
        lineHeight: '20px!important',
      },
      paddingTop: 16,
      paddingBottom: 4,
    },
  },
  description: {
    fontSize: 14,
    lineHeight: '18px',
    color: 'var(--text_secondary)',
    paddingTop: 0,
    '&--desktop': {
      color: 'var(--gray_500)',
      fontSize: 13,
      lineHeight: '16px',
      paddingBottom: 14,
    },
  },
  baseCurrencyCell: {
    marginBottom: 12,
  },
  separatedList: {
    position: 'relative',
  },
  separatedList__list: ({ widgetCurrenciesCount }) => ({
    marginBottom: 16,
    '&--mobile .Cell__main': {
      padding: '11px 8px 11px 0',
    },
    '& .Cell': {
      [`&[data-index="${widgetCurrenciesCount - 1}"]`]: {
        marginTop: 0,
        marginBottom: SEPARATOR_MARGIN * 2,
      },
      [`&[data-index="${widgetCurrenciesCount - 1}"][style*="translateY(100%)"]`]: {
        marginTop: SEPARATOR_MARGIN * 2,
        marginBottom: 0,
      },
      [`&[data-index="${widgetCurrenciesCount}"][style*="translateY(-100%)"]`]: {
        marginTop: -SEPARATOR_MARGIN * 2,
        marginBottom: SEPARATOR_MARGIN * 2,
      },
    },
    '& .Cell__aside, & .Icon': {
      paddingTop: '0!important',
      paddingBottom: '0!important',
    },
  }),
  sepatatedList__separator: ({ currenciesCount, widgetCurrenciesCount }) => ({
    display: widgetCurrenciesCount > 0 && widgetCurrenciesCount < currenciesCount ? 'block' : 'none',
    transform: 'none!important',
    position: 'absolute',
    zIndex: -1,
    top: widgetCurrenciesCount * (isDesktop ? 60 : 62),
    marginTop: SEPARATOR_MARGIN,
    left: 0,
    right: 0,
    height: 1,
  }),
  list: {
    margin: '4px 0',
    '&--desktop': {
      margin: '10px 0',
    },
  },
});

interface SettingsContentProps {
  openSelectBaseCurrencyModal(): void;
  scrollParentEl?: HTMLElement;
  searchValue: string;
  setSearchValue(searchValue: string): void;
  className?: string;
}

const SettingsContent: React.FC<SettingsContentProps> = memo((props) => {
  const { openSelectBaseCurrencyModal, className, scrollParentEl, searchValue, setSearchValue } = props;
  const { baseCurrency } = useBaseCurrency();
  const { isOKClient, statEvents, config } = useAppContext();

  const widgetCurrenciesCount = isOKClient ? 0 : config.widgetCurrenciesCount;

  const throttledSendStatEvents = useCallback(throttle(500, statEvents.push), []);

  useEffect(() => {
    statEvents.push('navigation_go', { screen: 'settings' });
  }, []); // eslint-disable-line

  const {
    setSelectedCurrencyIds,
    selectedCurrencyIds,
    selectedCurrencies,
    unselectCurrencyId,
  } = useSelectedCurrencies();

  const [replaceSelectedCurrency] = useAsync(
    (from: number, to: number) => {
      const updatedCurrencies = [...selectedCurrencyIds];
      updatedCurrencies.splice(from, 1);
      updatedCurrencies.splice(to, 0, selectedCurrencyIds[from]);

      return setSelectedCurrencyIds(updatedCurrencies).then(() => {
        statEvents.push('settings_item_swap', { name: selectedCurrencyIds[from], from, to });
      });
    },
    { retry: 'snackbar' }
  );

  const dragFinishCurrencyHandler = useCallback(
    ({ from, to }: { from: number; to: number | undefined }) => {
      if (typeof to !== 'number') return;
      Taptic.impactOccured('light');
      replaceSelectedCurrency(from, to).catch(() => null);
    },
    [replaceSelectedCurrency]
  );

  const [removeCurrencyId] = useAsync(
    (currencyId: string) =>
      unselectCurrencyId(currencyId).then(() => {
        statEvents.push('item_remove', { screen: 'settings', name: currencyId });
      }),
    {
      retry: 'snackbar',
    }
  );

  /* Поиск */

  const isSearchMode = searchValue !== '';

  /* Рендер */

  const mc = useStyles({ currenciesCount: selectedCurrencies.length, widgetCurrenciesCount });

  return (
    <div className={className}>
      <Search
        className={mc.search}
        placeholder={L.t('common:search_placeholder')}
        after={L.t('common:cancel')}
        value={searchValue}
        onFocus={() => statEvents.push('search_click', { screen: 'settings' })}
        onChange={(e): void => {
          setSearchValue(e.currentTarget.value);
          throttledSendStatEvents('search', { screen: 'settings', text: e.currentTarget.value });
        }}
      />
      {isSearchMode ? (
        <CurrenciesSearchSelectList
          className={getPlatformClassName(mc.list)}
          search={searchValue}
          skipBaseCurrency
          scrollParentEl={scrollParentEl}
        />
      ) : (
        <>
          <Group
            header={
              <Header className={getPlatformClassName(mc.header)} mode="secondary">
                {L.t('title_base_currency')}
              </Header>
            }
          >
            <Div className={getPlatformClassName(mc.description)}>{L.t('title_base_currency_info')}</Div>
            <SimpleCell
              expandable
              className={mc.baseCurrencyCell}
              onClick={openSelectBaseCurrencyModal}
              after={isDesktop && <Icon24ChevronCompactRight fill="var(--steel_gray_200)" />}
            >
              <CurrencyLabel id={baseCurrency.id} title={baseCurrency.title} />
            </SimpleCell>
          </Group>
          <Group
            header={
              <Header className={getPlatformClassName(mc.header)} mode="secondary">
                {L.t('title_currency_list')}
              </Header>
            }
          >
            {selectedCurrencies.length > 0 && widgetCurrenciesCount !== 0 && (
              <Div className={getPlatformClassName(mc.description)}>
                {L.t('widget_count_info', {
                  currencies: L.t('currencies', { count: widgetCurrenciesCount }),
                  context: widgetCurrenciesCount === 1 ? 'single' : '',
                })}
              </Div>
            )}
          </Group>
          {selectedCurrencies.length === 0 && (
            <Placeholder>
              <L.Jsx t="user_list_empty" />
            </Placeholder>
          )}
          {selectedCurrencies.length > 0 && (
            <div className={mc.separatedList}>
              <Separator className={mc.sepatatedList__separator} />
              <List className={getPlatformClassName(mc.separatedList__list)}>
                {selectedCurrencies.map((currency, index) => (
                  <Cell
                    key={currency.id}
                    removable
                    draggable
                    removePlaceholder={L.t('common:delete')}
                    data-index={index}
                    onRemove={() => removeCurrencyId(currency.id).catch(() => null)}
                    onDragFinish={dragFinishCurrencyHandler}
                  >
                    <CurrencyLabel id={currency.id} title={currency.title} />
                  </Cell>
                ))}
              </List>
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default SettingsContent;
