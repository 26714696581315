import React, { memo, useCallback } from 'react';
import { L } from '../../lang/L';
import { CurrencySelectModalPage } from './CurrencySelectModal';
import useBaseCurrency from '../../hooks/currency/useBaseCurrency';
import useAsync from '../../hooks/useAsync';
import {
  withModalPageOverlayWrapper,
  ModalPageHeader,
  PanelHeaderBack,
  PanelHeaderButton,
  isDesktop,
} from '@overrided-vkui';
import useSelectedCurrencies from '../../hooks/currency/useSelectedCurrencies';
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss';
import { throttle } from 'throttle-debounce';
import useAPI from '../tools/APIProvider/useAPI';
import { useActions } from '../../hooks/useActions';
import { currencyActions } from '../../redux/reducers/currency';
import { Taptic } from '../../utils/taptic';
import { useAppContext } from '../AppContext';

interface SelectBaseCurrencyModalProps {
  id: string;
  onClose(): void;
}

const SelectBaseCurrencyModal: React.FC<SelectBaseCurrencyModalProps> = memo((props) => {
  const { id: modalId, onClose } = props;

  const { baseCurrency, setBaseCurrencyId } = useBaseCurrency();
  const { setSelectedCurrencyIds, selectedCurrencies } = useSelectedCurrencies();
  const { getCurrencySeries } = useAPI();
  const { statEvents } = useAppContext();

  const setCurrencySeries = useActions(currencyActions.setCurrencySeries);

  const throttledSendStatEvents = useCallback(throttle(500, statEvents.push), []);

  const [updateBaseCurrency] = useAsync(
    (baseCurrencyId: string) => {
      const prevBaseCurrencyId = baseCurrency.id;
      if (prevBaseCurrencyId === baseCurrencyId) {
        return Promise.resolve();
      }

      return Promise.all([
        setBaseCurrencyId(baseCurrencyId),
        setSelectedCurrencyIds(
          selectedCurrencies.map((selectedCurrency) =>
            selectedCurrency.id === baseCurrencyId ? baseCurrency.id : selectedCurrency.id
          ),
          baseCurrencyId
        ),
        getCurrencySeries([baseCurrencyId]).then(
          (series) => series[0] && setCurrencySeries([{ currencyId: baseCurrencyId, series: series[0] }])
        ),
      ])
        .then(() => {
          statEvents.push('settings_base_currency_select', { name: baseCurrencyId });
        })
        .catch((e) =>
          Promise.all([
            setBaseCurrencyId(prevBaseCurrencyId),
            setSelectedCurrencyIds(
              selectedCurrencies.map((selectedCurrency) =>
                selectedCurrency.id === prevBaseCurrencyId ? baseCurrency.id : selectedCurrency.id
              ),
              prevBaseCurrencyId
            ),
          ]).catch(() => Promise.reject(e))
        );
    },
    { retry: 'snackbar' }
  );

  return (
    <CurrencySelectModalPage
      id={modalId}
      header={
        <ModalPageHeader
          noShadow={!isDesktop}
          left={isDesktop && <PanelHeaderBack onClick={onClose} />}
          right={
            !isDesktop && (
              <PanelHeaderButton onClick={onClose}>
                <Icon24Dismiss />
              </PanelHeaderButton>
            )
          }
        >
          {L.t('title_base_currency')}
        </ModalPageHeader>
      }
      selectedCurrencyId={baseCurrency.id}
      onSearchFocus={() => statEvents.push('search_click', { screen: 'base_currency_select' })}
      onSearchChange={(text) => throttledSendStatEvents('search', { screen: 'base_currency_select', text })}
      onClose={(currencyId) => {
        if (currencyId) {
          Taptic.selectionChanged();
          updateBaseCurrency(currencyId).catch(() => null);
        }
        onClose();
      }}
    />
  );
});

export default withModalPageOverlayWrapper(SelectBaseCurrencyModal);
