import React, { memo, useMemo } from 'react';
import PureSelectedCurrenciesList from '../atomic/SelectedCurrenciesList';

import useSelectedCurrencies from '../../hooks/currency/useSelectedCurrencies';
import withCurrencyObservers, { WithCurrencyObserversInjectedProps } from '../hocs/withCurrencyObservers';
import { useAppContext } from '../AppContext';

interface SelectedCurrenciesListProps extends WithCurrencyObserversInjectedProps {
  className?: string;
  onCurrencyClick(currencyId: string): void;
  openCurrencyObserversModal?(currencyId: string): void;
  onFindCurrenciesClick(): void;
}

const SelectedCurrenciesList: React.FC<SelectedCurrenciesListProps> = memo((props) => {
  const { className, onCurrencyClick, onFindCurrenciesClick, openCurrencyObserversModal, currencyObservers } = props;
  const { selectedCurrencies } = useSelectedCurrencies();

  const observedCurrencyIds = useMemo(() => currencyObservers.map((currencyObserver) => currencyObserver.currencyId), [
    currencyObservers,
  ]);

  const { statEvents } = useAppContext();

  const wrappedOpenCurrencyObserversModal = useMemo(
    () =>
      openCurrencyObserversModal &&
      ((currencyId: string) => {
        openCurrencyObserversModal(currencyId);
        statEvents.push('go_notifications', { name: currencyId, screen: 'my_list' });
      }),
    [openCurrencyObserversModal, statEvents]
  );

  return (
    <PureSelectedCurrenciesList
      currencies={selectedCurrencies}
      className={className}
      onCurrencyClick={onCurrencyClick}
      onFindCurrenciesClick={onFindCurrenciesClick}
      openCurrencyObserversModal={wrappedOpenCurrencyObserversModal}
      observedCurrencyIds={observedCurrencyIds}
    />
  );
});

export default withCurrencyObservers(SelectedCurrenciesList);
