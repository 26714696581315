import dayjs from 'dayjs';
import { TFunction } from 'i18next';

export interface Format {
  monthOf(monthNumber: number, options?: { short?: boolean }): string;
  weekDay(dayNumber: number, options?: { short?: boolean }): string;
  // eslint-disable-next-line max-len
  date(datetime: string | number, options?: { shortMonth?: boolean; alwaysDate?: boolean; alwaysShort?: boolean }): string;
  dateTime(datetime: string | number, options?: { shortMonth?: boolean; alwaysDate?: boolean }): string;
  timeFormat: '12hour' | '24hour';
  time(datetime: string | number): string;
  number(value: number): string;
}

export const initFormat = (t: TFunction): Format => ({
  /**
   * Возвращает название месяца в родительном падеже
   * @param monthNumber номер месяца (начиная с нуля)
   */
  monthOf(monthNumber: number, options?: { short?: boolean }) {
    if (options?.short) {
      return t('common:months_short_of', { returnObjects: true })[monthNumber];
    }
    return t('common:months_of', { returnObjects: true })[monthNumber];
  },
  /**
   * Возвращает название дня недели
   * @param dayNumber номер дня недели (начиная с нуля воскресенья)
   */
  weekDay(dayNumber, options) {
    if (options?.short) {
      return t('common:week_days_short', { returnObjects: true })[(dayNumber + 6) % 7];
    }
    return t('common:week_days', { returnObjects: true })[(dayNumber + 6) % 7];
  },
  /**
   * Возвращает дату
   * Вчера, сегодня, завтра или число с месяцем (и годом, если отличается от текущего)
   * @param datetime ISO8601
   */
  date(datetime, options) {
    const isShortMonth = Boolean(options?.shortMonth);
    const isAlwaysDate = Boolean(options?.alwaysDate);

    const targetDatetime = dayjs(datetime);
    const dayDiff = targetDatetime.hour(0).minute(0).second(0).diff(dayjs().format('YYYY-MM-DD'), 'day');
    const isCurrentYear = targetDatetime.year() === dayjs().year();

    const key = (isCurrentYear || options?.alwaysShort) ? 'common:short_date' : 'common:date'
    const [, date, yesterday, today, tomorrow] = t(key, {
      returnObjects: true,
      month: this.monthOf(targetDatetime.month(), { short: isShortMonth }),
      day: targetDatetime.date(),
      year: targetDatetime.year(),
    });

    if (isAlwaysDate) {
      return date;
    }

    switch (dayDiff) {
      case -1:
        return yesterday;
      case 0:
        return today;
      case 1:
        return tomorrow;
      default:
        return date;
    }
  },
  /**
   * Возвращает дату и время
   * Вчера, сегодня, завтра или число с месяцем (и годом, если отличается от текущего) + время
   * @param datetime ISO8601
   */
  dateTime(datetime, options) {
    const isShortMonth = Boolean(options?.shortMonth);
    const isAlwaysDate = Boolean(options?.alwaysDate);

    const targetDatetime = dayjs(datetime);
    const dayDiff = targetDatetime.hour(0).minute(0).second(0).diff(dayjs().format('YYYY-MM-DD'), 'day');
    const isCurrentYear = targetDatetime.year() === dayjs().year();

    const [, date, yesterday, today, tomorrow] = t(isCurrentYear ? 'common:short_date_time' : 'common:date_year_time', {
      returnObjects: true,
      day: targetDatetime.date(),
      month: this.monthOf(targetDatetime.month(), { short: isShortMonth }),
      year: targetDatetime.year(),
      hour: targetDatetime.format(this.timeFormat === '12hour' ? 'h' : 'HH'),
      minute: targetDatetime.format('mm'),
      am_pm: targetDatetime.format('a'),
    });

    if (isAlwaysDate) {
      return date;
    }

    switch (dayDiff) {
      case -1:
        return yesterday;
      case 0:
        return today;
      case 1:
        return tomorrow;
      default:
        return date;
    }
  },
  get timeFormat() {
    return t('config:timeSys', { returnObjects: true })[0] as any;
  },
  /**
   * Возвращает время
   */
  time(datetime) {
    return dayjs(datetime).format(this.timeFormat === '12hour' ? 'h:mm a' : 'HH:mm');
  },
  /**
   * Возвращает форматированное число
   */
  number(value) {
    const parts = String(value).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, t('config:numDelS'));

    return parts.join(t('config:numDec'));
  },
});
