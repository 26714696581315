import React, { memo, useMemo, useCallback, useState } from 'react';
import { DynamicDirection } from '../../types/currency';
import { Subtract } from '../../types/utility';
import { useSelector } from '../../hooks/useSelector';
import { useActions } from '../../hooks/useActions';
import { userActions } from '../../redux/reducers/user';
import useAPI from '../tools/APIProvider/useAPI';
import { useAppContext } from '../AppContext';

interface CurrencyObserver {
  id: string;
  rate: number;
  direction: DynamicDirection;
  baseCurrency: {
    id: string;
    symbol: string;
  };
}

interface CurrencyObservers {
  currencyId: string;
  observers: CurrencyObserver[];
}

export interface WithCurrencyObserversInjectedProps {
  currencyObservers: CurrencyObservers[];
  removeObserver(observerId: string, currencyId: string): void;
  removingObserverIds: string[];
}

function withCurrencyObservers<P extends WithCurrencyObserversInjectedProps>(
  Component: React.FC<P>
): React.FC<Subtract<P, WithCurrencyObserversInjectedProps>> {
  return memo((props) => {
    const observers = useSelector((state) => state.user.observers);
    const currencyById = useSelector((state) => state.currency.currencyById);

    const removeObserverAction = useActions(userActions.removeObserver);
    const { removeCurrencyObserver } = useAPI();
    const { statEvents } = useAppContext();

    const currencyObservers: CurrencyObservers[] = useMemo(() => {
      const currencyObservers: { [currencyId: string]: CurrencyObserver[] } = {};

      observers.forEach((observer) => {
        currencyObservers[observer.observedCurrencyId] = currencyObservers[observer.observedCurrencyId] || [];
        currencyObservers[observer.observedCurrencyId].push({
          id: observer.id,
          rate: observer.rate,
          direction: observer.direction,
          baseCurrency: {
            id: observer.id,
            symbol: currencyById[observer.baseCurrencyId]?.symbol || observer.baseCurrencyId,
          },
        });
      });

      return Object.entries(currencyObservers)
        .map(([currencyId, observers]) => ({ currencyId, observers }))
        .sort((a, b) => (a.currencyId > b.currencyId ? 1 : -1));
    }, [observers, currencyById]);

    const [removingObserverIds, setRemovingObserverIds] = useState<string[]>([]);

    const removeObserver = useCallback(
      (observerId: string, currencyId: string) => {
        setRemovingObserverIds((prev) => prev.concat(observerId));
        removeCurrencyObserver(observerId)
          .then(() => removeObserverAction(observerId))
          .then(() => statEvents.push('notifications_removed', { name: currencyId }))
          .catch(() => null)
          .finally(() => setRemovingObserverIds((prev) => prev.filter((id) => id !== observerId)));
      },
      [removeObserverAction, removeCurrencyObserver, statEvents]
    );

    return (
      <Component
        {...(props as P)}
        currencyObservers={currencyObservers}
        removeObserver={removeObserver}
        removingObserverIds={removingObserverIds}
      />
    );
  });
}

export default withCurrencyObservers;
