import React, { memo } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import VKSearch, { SearchProps } from '@vkontakte/vkui/dist/components/Search/Search';
import c from 'classnames';

const useStyles = makeStyles({
  search: {
    '--search_bar_field_background': 'transparent',
    '--search_default_height': '48px',
    padding: 0,
    width: '100%',
    '& .Search__after': {
      transform: 'none!important',
      '&:before': {
        display: 'none',
      },
    },
    '& .Search__icon': {
      cursor: 'pointer',
      color: 'var(--steel_gray_400)',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    '& .Search__width': {
      width: '100%',
    },
    '& .Search__icons': {
      right: 8,
    },
    '& .Search__control': {
      borderRadius: 0,
      width: '100%',
      '&:after': {
        content: '""',
        left: 0,
        right: 0,
        bottom: 0,
        height: 1,
        background: 'var(--steel_gray_80)',
        position: 'absolute',
      },
    },
    '& .Search__input': {
      borderRadius: 0,
      padding: '0 48px 0 48px',
      fontSize: 15,
    },
    '&.Search--has-icon.Search--has-value .Search__input': {
      paddingRight: 90,
    },
    '& .Search__placeholder': {
      paddingLeft: 24,
      color: 'var(--steel_gray_300)',
    },

    '& .Search__placeholder-text': {
      fontSize: 15,
      color: 'var(--steel_gray_400)',
    },
    '&.Search--focused .Search__placeholder-text': {
      color: 'var(--steel_gray_200)',
    },
    '&.Search--has-value .Search__icon:last-child': {
      color: 'transparent',
      // eslint-disable-next-line
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.20711 0.792893C1.81658 0.402369 1.18342 0.402369 0.792893 0.792893C0.402369 1.18342 0.402369 1.81658 0.792893 2.20711L5.58579 7L0.792893 11.7929C0.402369 12.1834 0.402369 12.8166 0.792893 13.2071C1.18342 13.5976 1.81658 13.5976 2.20711 13.2071L7 8.41421L11.7929 13.2071C12.1834 13.5976 12.8166 13.5976 13.2071 13.2071C13.5976 12.8166 13.5976 12.1834 13.2071 11.7929L8.41421 7L13.2071 2.20711C13.5976 1.81658 13.5976 1.18342 13.2071 0.792893C12.8166 0.402369 12.1834 0.402369 11.7929 0.792893L7 5.58579L2.20711 0.792893Z' fill='%23818c99'/%3E%3C/svg%3E%0A")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
});

const SearchDesktop: React.FC<SearchProps> = memo((props) => {
  const { className, ...rest } = props;
  return <VKSearch className={c(className, useStyles().search)} {...rest} after={null} />;
});

export default SearchDesktop;
