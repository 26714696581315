import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import ActionSheetItem from '@vkontakte/vkui/dist/components/ActionSheetItem/ActionSheetItem';
import ActionSheet from '@vkontakte/vkui/dist/components/ActionSheet/ActionSheet';
import { useStorage } from '../../../hooks/useStorage';
import { Taptic } from '../../../utils/taptic';
import { L } from '../../../lang/L';

const rootStyles: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1000000,
};

const TOUCHES_COUNT_TO_SHOW = 3;

declare global {
  interface Window {
    showServicePanel: () => void;
  }
}

interface ServicePanelProps {
  init(): void;
}

/**
 * Сервисная панель для каких-либо экстренных вещей. Например, когда у
 * пользователя возникает проблема связанная с тем, что у него какие-либо
 * невалидные данные в хранилище и его невозможно очистить кроме как на
 * клиентской стороне
 */
export const ServicePanel: React.FC<ServicePanelProps> = memo((props) => {
  const { init } = props;
  const [, dropStorage] = useStorage();
  const [show, setShow] = useState(false);
  const showTimeoutRef = useRef<number | null>(null);

  const onClose = useCallback(() => setShow(false), []);

  useEffect(() => {
    // Ожидаем одновременного нажатия сразу N пальцами в
    // течение секунды и отображаем сервисную панель
    const onTouchStart = (e: TouchEvent): void => {
      if (e.touches.length === TOUCHES_COUNT_TO_SHOW) {
        showTimeoutRef.current = window.setTimeout(() => {
          setShow(true);
        }, 1000);
      }
    };

    const onTouchEnd = (e: TouchEvent): void => {
      if (e.touches.length !== TOUCHES_COUNT_TO_SHOW) {
        // Отменяем показ как только кол-во тачей не равно N
        if (showTimeoutRef.current) {
          clearTimeout(showTimeoutRef.current);
        }
      }
    };

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchend', onTouchEnd);
    window.showServicePanel = (): void => setShow(true);

    return (): void => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchend', onTouchEnd);
      delete window.showServicePanel;
    };
  }, []);

  useEffect(() => {
    // Как только отобразили сервисную панель, посылаем тактильное уведомление
    if (show) {
      Taptic.notificationOccured('success');
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <ActionSheet onClose={onClose} style={rootStyles}>
      <ActionSheetItem onClick={init}>{L.t('restart_app')}</ActionSheetItem>
      <ActionSheetItem autoclose onClick={() => dropStorage().then(init)}>
        {L.t('clear_storage')}
      </ActionSheetItem>
    </ActionSheet>
  );
});
