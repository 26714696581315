export enum RootRoute {
  CURRENCY = 'currency',
  CURRENCY_MAIN = 'currency.main',
  CURRENCY_SETTINGS = 'currency.settings',
  CURRENCY_OBSERVERS = 'currency.observer',
  CONVERTER = 'converter',
  FEED = 'feed',
}

export const routes = [
  { name: RootRoute.CURRENCY, path: '/', forwardTo: RootRoute.CURRENCY_MAIN },
  { name: RootRoute.CURRENCY_MAIN, path: '/main' },
  { name: RootRoute.CURRENCY_SETTINGS, path: '/settings', defaultParams: { q: '' } },
  { name: RootRoute.CURRENCY_OBSERVERS, path: '/observers' },
  { name: RootRoute.CONVERTER, path: '/converter' },
  { name: RootRoute.FEED, path: '/feed' },
];
