import vkBridge, { ShowStoryBoxOptions } from '@vkontakte/vk-bridge';
import Icon24CoinsOutline from '../assets/coins_outline_24.svg';
import { AnyPlatform } from '../types';

export const isStoryShareSupports = () => {
  return vkBridge.supports('VKWebAppShowStoryBox');
};

function roundedRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
) {
  ctx.moveTo(x, y + radius);
  ctx.lineTo(x, y + height - radius);
  ctx.quadraticCurveTo(x, y + height, x + radius, y + height);
  ctx.lineTo(x + width - radius, y + height);
  ctx.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
  ctx.lineTo(x + width, y + radius);
  ctx.quadraticCurveTo(x + width, y, x + width - radius, y);
  ctx.lineTo(x + radius, y);
  ctx.quadraticCurveTo(x, y, x, y + radius);
}

function coverImage(ctx: CanvasRenderingContext2D, img: HTMLImageElement, x: number, y: number, w: number, h: number) {
  const hRatio = w / img.width;
  const vRatio = h / img.height;
  const ratio = Math.max(hRatio, vRatio);
  const centerShiftX = (w - img.width * ratio) / 2;
  const centerShiftY = (h - img.height * ratio) / 2;
  ctx.drawImage(img, -centerShiftX / ratio, -centerShiftY / ratio, w / ratio, h / ratio, x, y, w, h);
}

const loadImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.crossOrigin = 'anonymous';
    img.src = url;
  });
};

const getDynamicImage = (direction: 'decrease' | 'increase', color: string) => {
  const rotateDegree = direction === 'increase' ? 0 : -180;
  const svg = encodeURIComponent(
    // eslint-disable-next-line
    `<svg width="10" height="12" viewBox="0 0 10 12" fill="${color}" xmlns="http://www.w3.org/2000/svg"><path d="M5 11.6685C5.34424 11.6685 5.59326 11.4268 5.59326 11.0825V3.78027L5.54199 2.55713L7.36572 4.57861L8.41309 5.59668C8.51562 5.69922 8.66943 5.75781 8.82324 5.75781C9.15283 5.75781 9.39453 5.50146 9.39453 5.1792C9.39453 5.02539 9.33594 4.88623 9.21143 4.74707L5.43945 0.967773C5.31494 0.835938 5.16113 0.762695 5 0.762695C4.83887 0.762695 4.68506 0.835938 4.56055 0.967773L0.788574 4.74707C0.664062 4.88623 0.605469 5.02539 0.605469 5.1792C0.605469 5.50146 0.847168 5.75781 1.17676 5.75781C1.33057 5.75781 1.48438 5.69922 1.58691 5.59668L2.63428 4.57861L4.45801 2.55713L4.40674 3.78027V11.0825C4.40674 11.4268 4.65576 11.6685 5 11.6685Z" transform="rotate(${rotateDegree} 5 6)" /></svg>`
  );

  return loadImage('data:image/svg+xml;charset=utf-8,' + svg);
};

interface StickerProps {
  name: string;
  iconUrl: string;
  rate: string;
  difference: string;
  direction: 'decrease' | 'increase' | 'default';
}

const createSticker = async (width: number, props: StickerProps) => {
  const canvas = document.createElement('canvas');

  const initialLayout = {
    width: 0, // вычисляется по длине содержимого
    height: 0, // вычисляется далее
    padding: 16,
    borderRadius: 20,
    iconSize: 20,
    iconFallbackSize: 12,
    iconStrokeWidth: 0.5,
    iconCurrencyOffset: 9,
    currencyFontSize: 15,
    iconRateOffset: 12,
    rateFontSize: 42,
    rateDynamicOffset: 10,
    dynamicIconWidth: 10,
    dynamicIconHeight: 12,
    dynamicIconIncreaseOffset: 1,
    dynamicIconTextOffset: 6,
    dynamicTextFontSize: 14,
  };

  const pallet = {
    background: '#fff',
    iconStrokeColor: 'rgba(0, 0, 0, 0.12)',
    currencyTextColor: '#99A2AD',
    fontFamily: '-apple-system, Roboto, Helvetica Neue, Arial',
    rateTextColor: '#000',
    dynamicIncreaseTextColor: '#4BB34B',
    dynamicDefaultTextColor: '#99A2AD',
    dynamicDecreaseTextColor: '#FF3347',
  };

  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Работа с канвасом не поддерживается');
  }

  // вычисляем размеры стикера в зависимости от содержимого

  const texts = [
    {
      font: `${initialLayout.currencyFontSize}px ${pallet.fontFamily}`,
      text: props.name,
      offset: 2 * initialLayout.padding + initialLayout.iconSize + initialLayout.iconCurrencyOffset,
    },
    {
      font: `${initialLayout.rateFontSize}px ${pallet.fontFamily}`,
      text: props.rate,
      offset: 2 * initialLayout.padding,
    },
    {
      font: `${initialLayout.dynamicTextFontSize}px ${pallet.fontFamily}`,
      text: props.difference,
      offset: 2 * initialLayout.padding + initialLayout.dynamicIconWidth + initialLayout.dynamicIconTextOffset,
    },
  ];

  texts.forEach(({ font, text, offset }) => {
    ctx.font = font;
    initialLayout.width = Math.max(initialLayout.width, ctx.measureText(text).width + offset);
  });

  initialLayout.height =
    2 * initialLayout.padding +
    initialLayout.iconSize +
    initialLayout.iconRateOffset +
    initialLayout.rateFontSize +
    initialLayout.rateDynamicOffset +
    initialLayout.dynamicIconWidth;

  // размеры пропорционально ширине

  const scale = width / initialLayout.width;

  const layout = (Object.keys(initialLayout) as (keyof typeof initialLayout)[]).reduce((acc, key) => {
    acc[key] = initialLayout[key] * scale;
    return acc;
  }, {} as typeof initialLayout);

  canvas.width = layout.width;
  canvas.height = layout.height;

  // фон

  ctx.fillStyle = pallet.background;
  roundedRect(ctx, 0, 0, layout.width, layout.height, layout.borderRadius);
  ctx.fill();

  // иконка валюты

  ctx.save();
  ctx.beginPath();
  const radius = layout.iconSize / 2;
  ctx.arc(layout.padding + radius, layout.padding + radius, radius, 0, 2 * Math.PI);
  ctx.clip();

  try {
    const image = await loadImage(props.iconUrl);
    coverImage(ctx, image, layout.padding, layout.padding, layout.iconSize, layout.iconSize);
  } catch {
    const fallbackIcon = await loadImage(Icon24CoinsOutline);
    coverImage(
      ctx,
      fallbackIcon,
      layout.padding + (layout.iconSize - layout.iconFallbackSize) / 2,
      layout.padding + (layout.iconSize - layout.iconFallbackSize) / 2,
      layout.iconFallbackSize,
      layout.iconFallbackSize
    );
  }

  ctx.restore();

  ctx.beginPath();
  ctx.strokeStyle = pallet.iconStrokeColor;
  ctx.lineWidth = layout.iconStrokeWidth;
  ctx.arc(layout.padding + radius, layout.padding + radius, radius - ctx.lineWidth / 2, 0, 2 * Math.PI);
  ctx.stroke();

  // название валюты

  ctx.fillStyle = pallet.currencyTextColor;
  ctx.textBaseline = 'middle';
  ctx.font = `${layout.currencyFontSize}px ${pallet.fontFamily}`;
  ctx.fillText(
    props.name,
    layout.padding + layout.iconSize + layout.iconCurrencyOffset,
    layout.padding + layout.iconSize / 2
  );

  // текущий курс

  ctx.fillStyle = pallet.rateTextColor;
  ctx.textBaseline = 'ideographic';
  ctx.font = `${layout.rateFontSize}px ${pallet.fontFamily}`;
  const rateOffsetY = layout.padding + layout.iconSize + layout.iconRateOffset;
  ctx.fillText(props.rate, layout.padding, rateOffsetY + layout.rateFontSize);

  // иконка и текст динамики

  ctx.fillStyle =
    props.direction === 'increase'
      ? pallet.dynamicIncreaseTextColor
      : props.direction === 'decrease'
      ? pallet.dynamicDecreaseTextColor
      : pallet.dynamicDefaultTextColor;

  const dynamicOffsetY = rateOffsetY + layout.rateFontSize + layout.rateDynamicOffset;

  if (props.direction !== 'default') {
    const dynamicImage = await getDynamicImage(props.direction, ctx.fillStyle);
    ctx.drawImage(dynamicImage, layout.padding, dynamicOffsetY, layout.dynamicIconWidth, layout.dynamicIconHeight);
  }

  ctx.font = `${layout.dynamicTextFontSize}px ${pallet.fontFamily}`;
  ctx.textBaseline = 'middle';

  ctx.fillText(
    props.difference,
    layout.padding + (props.direction === 'default' ? 0 : layout.dynamicIconWidth + layout.dynamicIconTextOffset),
    dynamicOffsetY +
      layout.dynamicIconHeight / 2 +
      (props.direction === 'increase' ? layout.dynamicIconIncreaseOffset : 0)
  );

  return canvas;
};

const createStoryBackground = (vh: number, vw: number) => {
  const canvas = document.createElement('canvas');
  canvas.height = vh;
  canvas.width = vw;

  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Работа с канвасом не поддерживается');
  }

  const gradient = ctx.createLinearGradient(0, 0, vw, vh);
  gradient.addColorStop(0, '#C2DFFF');
  gradient.addColorStop(1, '#5183BC');

  ctx.beginPath();
  ctx.rect(0, 0, window.innerWidth, window.innerHeight);
  ctx.fillStyle = gradient;
  ctx.fill();

  return canvas;
};

export const shareCurrencyByStory = async (link: string, props: StickerProps, platform: AnyPlatform) => {
  const isDesktop = platform === 'desktop_web';
  const isMVK = platform === 'mobile_web';

  const vw = isDesktop ? 500 : window.innerWidth;
  const vh = isDesktop ? 875 : window.innerHeight;

  const backgroundCanvas = createStoryBackground(vh, vw);

  const stickerScale = 2; // рисуем в большом масштабе для сохранения качества при масштабировании
  const stickerCanvas = await createSticker(vw * stickerScale, props);
  const { height: stickerH, width: stickerW } = stickerCanvas;

  if (isMVK) {
    // mvk не поддерживает стикеры, поэтому рисуем их прямо на фоне
    const ctx = backgroundCanvas.getContext('2d');

    if (!ctx) {
      throw new Error('Работа с канвасом не поддерживается');
    }

    const stickerRatio = stickerH / stickerW;
    const stickerCanvasW = vw / 2;
    const stickerCanvasH = (stickerRatio * vw) / 2;

    await loadImage(stickerCanvas.toDataURL()).then((image) => {
      ctx.drawImage(
        image,
        0,
        0,
        stickerW,
        stickerH,
        (vw - stickerCanvasW) / 2,
        (vh - stickerCanvasH) / 2,
        stickerCanvasW,
        stickerCanvasH
      );
    });

    const storyBoxOptions: ShowStoryBoxOptions = {
      background_type: 'image',
      blob: backgroundCanvas.toDataURL(),
      locked: true,
      attachment: { type: 'url', text: 'learn_more', url: link },
    };

    return vkBridge.send('VKWebAppShowStoryBox', storyBoxOptions);
  }

  const storyBoxOptions: ShowStoryBoxOptions = {
    background_type: 'image',
    blob: backgroundCanvas.toDataURL(),
    locked: true,
    attachment: { type: 'url', text: 'learn_more', url: link },
    stickers: [
      {
        sticker_type: 'renderable',
        sticker: {
          blob: stickerCanvas.toDataURL(),
          content_type: 'image',
          can_delete: false,
          original_height: stickerH,
          original_width: stickerW,
          transform: { relation_width: 0.5 },
          clickable_zones: [
            {
              action_type: 'link',
              action: { link, tooltip_text_key: 'tooltip_open_default' },
              clickable_area: [
                { x: 0, y: 0 },
                { x: stickerW, y: 0 },
                { x: stickerW, y: stickerH },
                { x: 0, y: stickerH },
              ],
            },
          ],
        },
      },
    ],
  };

  return vkBridge.send('VKWebAppShowStoryBox', storyBoxOptions);
};
