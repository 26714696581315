import { useSelector } from '../useSelector';
import { useCallback } from 'react';
import { RelativeCurrency, BaseRelatedCurrency, CurrencySeries } from '../../types/currency';
import { getCurrency } from '../../redux/selectors';
import { StorageField } from '../../types';
import config from '../../config';
import { useStorageValue } from '../useStorageValue';

function calcBaseRelatedCurrencySeries(
  currencySeries: CurrencySeries | undefined,
  baseCurrencySeries: CurrencySeries | undefined,
  currentConvertedRate: number
): CurrencySeries | undefined {
  if (!currencySeries || !baseCurrencySeries) return;

  const convertedSeriesRates = currencySeries.rates.map((seriesRate) => ({
    date: seriesRate.date,
    rate: (baseCurrencySeries.rates.find((r) => r.date === seriesRate.date)?.rate || 0) / seriesRate.rate,
  }));

  convertedSeriesRates[convertedSeriesRates.length - 1].rate = currentConvertedRate;

  const convertedRatesArray = convertedSeriesRates.map(({ rate }) => rate);

  return {
    rates: convertedSeriesRates,
    timestamp: currencySeries.timestamp,
    openRate: convertedRatesArray[0],
    maxRate: Math.max.apply(null, convertedRatesArray),
    minRate: Math.min.apply(null, convertedRatesArray),
  };
}

const useBaseCurrency = () => {
  const [baseCurrencyId = config.fallbackBaseCurrencyId, setBaseCurrencyId] = useStorageValue(
    StorageField.BaseCurrencyId
  );

  const baseCurrency = useSelector(getCurrency(baseCurrencyId))!; // eslint-disable-line

  const calcBaseRelatedCurrency = useCallback(
    (currency: RelativeCurrency): BaseRelatedCurrency => {
      const convertedRate = baseCurrency.rate / currency.rate;
      const convertedPrevRate = baseCurrency.prevRate / currency.prevRate;

      return {
        ...currency,
        rate: convertedRate,
        prevRate: convertedPrevRate,
        series: calcBaseRelatedCurrencySeries(currency.series, baseCurrency.series, convertedRate),
        base: {
          id: baseCurrency.id,
          symbol: baseCurrency.symbol,
        },
      };
    },
    [baseCurrency]
  );

  return { baseCurrency, setBaseCurrencyId, calcBaseRelatedCurrency } as const;
};

export default useBaseCurrency;
