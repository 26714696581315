import React, { memo } from 'react';
import { Placeholder, Avatar } from '@vkontakte/vkui';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const logoUrl = 'https://sun9-12.userapi.com/rOIpievt9E5OIiCiAuHEeoSxUqCJP-3fD2cyIA/_3BX3NbnKjc.jpg';

const ServerWorksCrashView = memo(() => {
  return (
    <div className={useStyles().root}>
      <Placeholder icon={<Avatar src={logoUrl} size={72} mode="app" />} header="Работы на сервере">
        Мы готовим обновление, нужно немного&nbsp;подождать.
        <br />
        Приносим извинения за&nbsp;неудобства.
      </Placeholder>
    </div>
  );
});

export default ServerWorksCrashView;
