import React, { memo, useRef, useEffect, useState } from 'react';
import { ModalPage, ModalPageHeader, withModalPageOverlayWrapper } from '@overrided-vkui';
import { L } from '../../../lang/L';
import SettingsContent from './_SettingsContent';
import useForceUpdate from '../../../hooks/useForceUpdate';

interface SettingsModalProps {
  id: string;
  onClose(): void;
  openSelectBaseCurrencyModal(): void;
}

const SettingsModal: React.FC<SettingsModalProps> = memo((props) => {
  const { id: modalId, onClose, openSelectBaseCurrencyModal } = props;

  const forceUpdate = useForceUpdate();

  const modalContentRef = useRef<HTMLDivElement>(null);
  const scrollParentEl = modalContentRef.current?.parentElement?.parentElement || void 0;

  // форсим второй ререндер, чтобы пробросить scrollParentEl
  useEffect(forceUpdate, []); // eslint-disable-line

  const [searchValue, setSearchValue] = useState('');

  const initied = useRef(false);

  useEffect(() => {
    if (initied.current) {
      scrollParentEl?.scrollTo(0, 0);
    } else {
      initied.current = true;
    }
  }, [searchValue]); // eslint-disable-line

  return (
    <ModalPage
      id={modalId}
      onClose={onClose}
      fullHeight={searchValue !== ''}
      header={<ModalPageHeader>{L.t('settings')}</ModalPageHeader>}
    >
      <div ref={modalContentRef} />
      <SettingsContent
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        openSelectBaseCurrencyModal={openSelectBaseCurrencyModal}
        scrollParentEl={scrollParentEl}
      />
    </ModalPage>
  );
});

export default withModalPageOverlayWrapper(SettingsModal);
