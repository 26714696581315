import React, { useCallback, useState, useEffect, useRef, memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import NumberFormat from 'react-number-format';
import Input, { InputProps } from '@overrided-vkui/Input';
import c from 'classnames';
import { ReactComponent as IncreaseIcon } from '../../assets/increase.svg';
import { getPlatformClassName } from '@overrided-vkui';

const useStyles = makeStyles({
  dynamicInput: {
    position: 'relative',
  },
  switch: {
    height: 28,
    width: 28,
    color: 'var(--dynamic_green)',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
    margin: 8,
    '&--desktop': {
      margin: 5,
      cursor: 'pointer',
    },
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before': {
      content: '""',
      opacity: 0.12,
      position: 'absolute',
      background: 'var(--dynamic_green)',
      right: 0,
      top: 0,
      left: 0,
      bottom: 0,
      zIndex: 3,
      borderRadius: '50%',
    },
  },
  switch_decrease: {
    color: 'var(--dynamic_red)',
    transform: 'rotate(180deg)',
    '&:before': {
      opacity: 0.12,
      background: 'var(--dynamic_red)',
    },
  },
  switch_default: {
    display: 'none',
  },
  input: {
    paddingRight: 44,
  },
});

interface DynamicInputProps extends Omit<InputProps, 'onChange' | 'value' | 'defaultValue' | 'type'> {
  value: number;
  onChange(value: number): void;
  status?: 'valid' | 'error' | 'default';
  onSwap?(): void;
}

const DynamicInput: React.FC<DynamicInputProps> = memo((props) => {
  const { onChange, onSwap, value, status, onFocus, onBlur, ...rest } = props;
  const [focused, setFocused] = useState(false);

  const componentRef = useRef<HTMLDivElement>(null);

  const [inputValue, setInputValue] = useState(String(value));
  const isDecreasing = Number(inputValue) < 0;

  useEffect(() => {
    focused && onChange(Number(inputValue) || 0);
  }, [inputValue, focused, onChange]);

  useEffect(() => {
    !focused && setInputValue(String(value));
  }, [value, focused]);

  const toggleDirection = useCallback(() => {
    componentRef.current?.querySelector('input')?.focus();
    const newValue = -Number(inputValue);
    setInputValue(String(newValue < -100 ? -100 : newValue));
    onSwap && onSwap();
  }, [inputValue, onSwap]);

  const mc = useStyles();

  return (
    <div className={mc.dynamicInput} ref={componentRef}>
      <NumberFormat
        value={inputValue}
        onValueChange={({ value }) => setInputValue(value)}
        displayType="input"
        inputMode="decimal"
        thousandSeparator=" "
        customInput={Input}
        decimalSeparator=","
        decimalScale={2}
        isAllowed={(values) => {
          const { floatValue = 0 } = values;
          return floatValue >= -100 && floatValue <= 100000;
        }}
        allowEmptyFormatting={false}
        allowLeadingZeros={false}
        isNumericString
        suffix={'%'}
        className={mc.input}
        status={status}
        onFocus={(e) => {
          onFocus && onFocus(e);
          setFocused(true);
        }}
        onBlur={(e) => {
          onBlur && onBlur(e);
          setFocused(false);
        }}
        {...rest}
      />
      <span
        onClick={toggleDirection}
        className={c(getPlatformClassName(mc.switch), {
          [mc.switch_decrease]: isDecreasing,
          [mc.switch_default]: Number(value) === 0 || Number.isNaN(Number(value)),
        })}
        onFocus={(e) => e.preventDefault()}
        onMouseDown={(e) => e.preventDefault()}
      >
        <IncreaseIcon />
      </span>
    </div>
  );
});

export default DynamicInput;
