// Функция получения пути на изображение валюты
export const getCurrencyIconUrl = (abbr: string) => {
  // если мы в ODR то мы не сможем скачать картинку через фетч
  // поэтому попробуем скачать отсюда
  // https://gitlab.vkpartner.ru/happysanta/vkminiapps/mini-apps-extended-resources
  if (window.location.protocol === 'file:') {
    // eslint-disable-next-line max-len
    return `https://object2-ac.vk-apps.com/hs-mini-apps-extended-reso-65d0afda-24b8-48ed-8b45-c124cd5c804e/currencies/icons/${abbr}.svg`
  }
  // путь настраивается в config-overrides.js
  return `./static/currencies/${abbr}.svg`;
};
