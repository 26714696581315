// Application build config

// We don't check if variables are passed, due to this logic is placed in
// prebuild.js file

const config = {
  gqlHttpUrl: process.env.REACT_APP_GQL_HTTP_URL || '',
  oerProxyHttpUrl: process.env.REACT_APP_OER_PROXY_HTTP_URL || '',
  sentryDsnUrl: process.env.REACT_APP_SENTRY_DSN_URL || '',
  widgetCurrenciesCount: Number(process.env.REACT_APP_WIDGET_CURRENCIES_COUNT || ''),
  fallbackBaseCurrencyId: process.env.REACT_APP_FALLBACK_BASE_CURRENCY_ID || '',
  serverWorksView: Boolean(process.env.REACT_APP_SERVER_WORKS_VIEW),
  vkApiVersion: process.env.REACT_APP_VK_API_VERSION || '',
};

export default config;

export type Config = typeof config;
