import React, { memo } from 'react';
import RateDelta from '../../atomic/RateDelta';
import { makeStyles } from '@material-ui/styles';
import CurrencyListItem from '../CurrencyListItem';
import { BaseRelatedCurrency } from '../../../types/currency';
import { formatRate } from '../../../utils/formatter';
import MiniRateChart from '../../atomic/MiniRateChart';
import { isDesktop, getPlatformClassName } from '@overrided-vkui';

const useStyles = makeStyles({
  item: {
    '&--desktop': {
      '& $item__rate': {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 400,
      },
      '& $item__dynamic': {
        fontWeight: 400,
        fontSize: 13,
      },
    },
  },
  item__aside: {
    textAlign: 'right',
  },
  item__rate: {
    color: 'var(--text_primary)',
    fontSize: 17,
    fontWeight: 500,
    lineHeight: '22px',
  },
  item__dynamic: {
    display: 'block',
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '18px',
  },
  after: {
    '&--desktop': {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: 260,
      flexDirection: 'row-reverse',
    },
  },
  after__chart: {
    marginRight: 10,
  },
});

interface CurrencySelectedItemProps {
  onClick(): void;
  currency: BaseRelatedCurrency;
}

const CurrencySelectedItem: React.FC<CurrencySelectedItemProps> = memo((props) => {
  const { onClick, currency } = props;

  const mc = useStyles();

  return (
    <CurrencyListItem
      id={currency.id}
      title={currency.title}
      after={
        <div className={getPlatformClassName(mc.after)}>
          <div className={mc.item__aside}>
            <div className={mc.item__rate}>{formatRate(currency.rate, currency.base.symbol)}</div>
            <RateDelta
              className={mc.item__dynamic}
              prevRate={currency.prevRate}
              rate={currency.rate}
              symbol={currency.base.symbol}
            />
          </div>
          {isDesktop && (
            <MiniRateChart height={32} width={80} className={mc.after__chart} points={currency.series?.rates || []} />
          )}
        </div>
      }
      className={getPlatformClassName(mc.item)}
      onClick={onClick}
    />
  );
});

export default CurrencySelectedItem;
