import React, { useState } from 'react';
import { useSnackbar } from '@overrided-vkui';
import ErrorSnackbar from '../components/atomic/ErrorSnackbar';

type AsyncStatus = 'idle' | 'loading' | 'done' | 'error';
type RetryOption = 'snackbar';

type AsyncOptions = {
  retry?: RetryOption;
  retryText?: string;
};

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;

type ThenArgRecursive<T> = T extends PromiseLike<infer U>
  ? { 0: ThenArgRecursive<U>; 1: U }[T extends PromiseLike<any> ? 0 : 1]
  : T;

const useAsync = <T, F extends (...a: any) => Promise<T>>(action: F, options: AsyncOptions = {}) => {
  const [status, setStatus] = useState<AsyncStatus>('idle');
  const [data, setData] = useState<T>();
  const openSnackbar = useSnackbar();

  const load = (...args: ArgumentTypes<F>) => {
    setStatus('loading');
    return action(...args).then(
      (result) => {
        setStatus('done');
        setData(result);
        return result;
      },
      (e) => {
        setStatus('error');
        if (options.retry === 'snackbar') {
          openSnackbar(
            React.createElement(ErrorSnackbar, { text: options.retryText, onRetry: () => load(...args) } as any)
          );
        }
        throw e;
      }
    );
  };

  return [load, status, data] as [
    (...args: ArgumentTypes<F>) => ReturnType<F>,
    AsyncStatus,
    ThenArgRecursive<ReturnType<F>>
  ];
};

export default useAsync;
