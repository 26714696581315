import React, { memo, useMemo, useEffect } from 'react';
import {
  Group,
  SimpleCell,
  Header,
  getPlatformClassName,
  PanelHeaderButton,
  Placeholder,
  isDesktop,
  HoverTultip,
} from '@overrided-vkui';
import CurrencyBadge from '../../atomic/CurrencyBadge';
import ObserverBadgeList from '../../atomic/ObserverBadgeList';
import withCurrencyObservers, { WithCurrencyObserversInjectedProps } from '../../hocs/withCurrencyObservers';
import { makeStyles } from '@material-ui/styles';
import useSelectedCurrencies from '../../../hooks/currency/useSelectedCurrencies';
import { List } from '@vkontakte/vkui';
import CurrencyLabel from '../../atomic/CurrencyLabel';
import Icon28Notifications from '@vkontakte/icons/dist/28/notifications';
import Icon24NotificationOutline from '@vkontakte/icons/dist/24/notification_outline';
import { useSelector } from '../../../hooks/useSelector';
import { L } from '../../../lang/L';
import { useAppContext } from 'src/components/AppContext';

const useStyles = makeStyles({
  root: {
    marginBottom: 16,
    '&--desktop ': {
      '--accent': 'var(--blue_400)',
      '& $header': {
        marginBottom: -8,
        '& .Header__content': {
          textTransform: 'uppercase',
          color: 'var(--gray_400)',
          fontWeight: 500,
        },
      },
    },
  },
  badge: {
    marginBottom: 12,
  },
  header: {},
});

interface ObserversContentProps extends WithCurrencyObserversInjectedProps {
  openCurrencyObserversModal(currencyId: string): void;
  openCurrencyModal(currencyId: string): void;
}

const ObserversContent = memo((props: ObserversContentProps) => {
  const {
    openCurrencyObserversModal,
    openCurrencyModal,
    removeObserver,
    removingObserverIds,
    currencyObservers,
  } = props;

  const { statEvents } = useAppContext();

  useEffect(() => {
    statEvents.push('navigation_go', { screen: 'notification_list' });
  }, []); // eslint-disable-line

  const { selectedCurrencyIds } = useSelectedCurrencies();
  const suggestedCurrencyIds = useSelector((state) => state.currency.observerSuggestedCurrencyIds);
  const currencyById = useSelector((state) => state.currency.currencyById);

  const preparedSuggestedCurrencyIds = useMemo(() => {
    const notSelectedCurrencyIds = suggestedCurrencyIds.filter(
      (suggestedCurrencyId) => !selectedCurrencyIds.includes(suggestedCurrencyId)
    );

    return selectedCurrencyIds
      .concat(notSelectedCurrencyIds)
      .map((currencyId) => currencyById[currencyId])
      .filter(Boolean)
      .filter((currency) => !currencyObservers.find((observer) => observer.currencyId === currency.id));
  }, [suggestedCurrencyIds, selectedCurrencyIds, currencyById, currencyObservers]);

  /* Рендер */

  const NotificationIcon = isDesktop ? Icon24NotificationOutline : Icon28Notifications;

  const mc = useStyles();

  return (
    <div className={getPlatformClassName(mc.root)}>
      {currencyObservers.length > 0 ? (
        <Group
          header={
            <Header className={mc.header} mode={isDesktop ? 'secondary' : 'primary'}>
              {L.t('observers_page_section_subscribes_title')}
            </Header>
          }
        >
          {currencyObservers.map((currencyObserver) => (
            <SimpleCell key={currencyObserver.currencyId} disabled multiline>
              <CurrencyBadge id={currencyObserver.currencyId} className={mc.badge} />
              <ObserverBadgeList
                observers={currencyObserver.observers}
                removeObserver={(observerId) => removeObserver(observerId, currencyObserver.currencyId)}
                removingObserverIds={removingObserverIds}
                onAddObserver={() => openCurrencyObserversModal(currencyObserver.currencyId)}
              />
            </SimpleCell>
          ))}
        </Group>
      ) : (
        <Group>
          <Placeholder>{L.t('observers_page_section_subscribes_empty')}</Placeholder>
        </Group>
      )}
      {preparedSuggestedCurrencyIds.length > 0 && (
        <Group
          header={
            <Header className={mc.header} mode={isDesktop ? 'secondary' : 'primary'}>
              {L.t('observers_page_section_suggests_title')}
            </Header>
          }
        >
          <List>
            {preparedSuggestedCurrencyIds.map((currency) => (
              <SimpleCell
                key={currency.id}
                onClick={() => openCurrencyModal(currency.id)}
                after={
                  <HoverTultip title={L.t('observer_icon_subscribe_tooltip')} placement="top-end" margin="0 -16px 0 0">
                    <PanelHeaderButton
                      onClick={(e) => {
                        e.stopPropagation();
                        openCurrencyObserversModal(currency.id);
                      }}
                    >
                      <NotificationIcon />
                    </PanelHeaderButton>
                  </HoverTultip>
                }
              >
                <CurrencyLabel id={currency.id} title={currency.title} />
              </SimpleCell>
            ))}
          </List>
        </Group>
      )}
    </div>
  );
});

export default withCurrencyObservers(ObserversContent);
