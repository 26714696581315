import React, { memo, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Panel, PanelHeader, Tappable, Div } from '@vkontakte/vkui';
import { L } from '../../../lang/L';
import CurencyTitle from '../../atomic/CurrencyTitle';
import Icon16Dropdown from '@vkontakte/icons/dist/16/dropdown';
import Icon28SortOutline from '@vkontakte/icons/dist/28/sort_outline';
import ConverterKeyboard, { ConverterKeyboardKey } from '../../atomic/ConverterKeyboard';
import CurrencyConverterRateLabel from '../../atomic/CurrencyConverterRateLabel';
import { isRateInputValueAllowed } from './utils';
import withConverter from '../../hocs/withConverter';
import { useAppContext } from 'src/components/AppContext';

const VH_PRIMARY_FONT_SIZE = 2.28; // нужный коэффициент для адаптики

const useStyles = makeStyles({
  root: {
    '& .Panel__in': {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
  },
  container: {
    flex: 1,
    maxWidth: '65vh',
    margin: '0 auto',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    fontSize: `${VH_PRIMARY_FONT_SIZE}vh`,
  },
  screen: {
    flex: 5,
    paddingTop: '1.8em',
    paddingBottom: '1.4em',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  currency: {
    textAlign: 'center',
    width: '100%',
  },
  currency__title: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0.625em 0.75em',
    borderRadius: '0.625em',
    overflow: 'hidden',
    marginBottom: 2,
  },
  currency__titleExpandIcon: {
    marginLeft: 4,
    height: '1em!important',
    width: '1em!important',
    '& .Icon, & svg': {
      height: '1em!important',
      width: '1em!important',
    },
  },
  currency__switch: {
    margin: 'auto 0',
    flex: '0 0 auto',
    background: 'var(--input_background)',
    borderRadius: '50%',
    overflow: 'hidden',
    padding: '0.5em',
    '& .Icon, & svg': {
      height: '1.75em!important',
      width: '1.75em!important',
    },
  },
});

interface CurrencyData {
  id: string;
  value: string;
  onClick(): void;
  onChange(value: string): void;
}

type FocusedCurrency = 'first' | 'second';

interface ConverterPanelMobileProps {
  id: string;
  firstCurrency: CurrencyData;
  secondCurrency: CurrencyData;
  onSwitchClick(): void;
  onInput(fromCurrencyId: string, toCurrencyId: string): void;
  focusedCurrency: FocusedCurrency;
  onFocusChange(focused: FocusedCurrency): void;
}

const ConverterPanelMobile: React.FC<ConverterPanelMobileProps> = memo((props) => {
  const { id: panelId, firstCurrency, secondCurrency, onInput, onSwitchClick, focusedCurrency, onFocusChange } = props;

  const { statEvents } = useAppContext();

  useEffect(() => {
    statEvents.push('navigation_go', { screen: 'converter' });
  }, []); // eslint-disable-line

  /* Сфокусированное поле */

  const setFocusedCurrencyValue = useCallback(
    (cb: (prevValue: string) => string) => {
      if (focusedCurrency === 'first') {
        onInput(firstCurrency.id, secondCurrency.id);
        firstCurrency.onChange(cb(firstCurrency.value));
      } else {
        onInput(secondCurrency.id, firstCurrency.id);
        secondCurrency.onChange(cb(secondCurrency.value));
      }
    },
    [focusedCurrency, onInput, firstCurrency, secondCurrency]
  );

  /* Обработчики клавиатуры */

  const keyPressHandler = useCallback(
    (key: ConverterKeyboardKey) => {
      setFocusedCurrencyValue((value) => {
        if (key === ConverterKeyboardKey.BACKSPACE) {
          return value.slice(0, -1) || '0';
        }

        const isFloat = value.includes('.');

        if (key === ConverterKeyboardKey.DOT) {
          return value + (isFloat ? '' : '.');
        }

        const newValue = value === '0' ? String(key) : value + key;

        return isRateInputValueAllowed(newValue) ? newValue : value;
      });
    },
    [setFocusedCurrencyValue]
  );

  const resetHandler = useCallback(() => {
    setFocusedCurrencyValue(() => '0');
  }, [setFocusedCurrencyValue]);

  /* Отрисовка */

  const mc = useStyles();

  return (
    <Panel id={panelId} className={mc.root}>
      <PanelHeader>{L.t('app_name')}</PanelHeader>
      <div className={mc.container}>
        <Div className={mc.screen}>
          <div className={mc.currency}>
            <Tappable className={mc.currency__title} onClick={firstCurrency.onClick}>
              <CurencyTitle abbr={firstCurrency.id} />
              <Icon16Dropdown className={mc.currency__titleExpandIcon} />
            </Tappable>
            <CurrencyConverterRateLabel
              value={firstCurrency.value}
              onFocus={(): void => onFocusChange('first')}
              focused={focusedCurrency === 'first'}
            />
          </div>
          <Tappable className={mc.currency__switch} onClick={onSwitchClick}>
            <Icon28SortOutline fill="var(--accent)" />
          </Tappable>
          <div className={mc.currency}>
            <Tappable className={mc.currency__title} onClick={secondCurrency.onClick}>
              <CurencyTitle abbr={secondCurrency.id} />
              <Icon16Dropdown className={mc.currency__titleExpandIcon} />
            </Tappable>
            <CurrencyConverterRateLabel
              value={secondCurrency.value}
              onFocus={(): void => onFocusChange('second')}
              focused={focusedCurrency === 'second'}
            />
          </div>
        </Div>
        <ConverterKeyboard onKeyPress={keyPressHandler} onReset={resetHandler} />
      </div>
    </Panel>
  );
});

export default withConverter(ConverterPanelMobile);
