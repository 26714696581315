import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Spinner } from '@vkontakte/vkui';
import Icon36Replay from '@vkontakte/icons/dist/36/replay';
import { PanelHeaderButton } from '@overrided-vkui';
import { L } from '../../../lang/L';

const useStyles = makeStyles({
  chartSkeletonWrap: {
    width: '100%',
  },
  chartSkeleton: {
    width: '100%',
    height: 160,
    display: 'flex',
  },
  chartSkeleton__chart: {
    borderRadius: 10,
    border: `.5px solid var(--field_border)`,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: 'var(--text_secondary)',
    fontSize: 14,
    lineHeight: '18px',
    flex: '1 0 auto',
  },
  chartSkeleton__axis_x: {
    height: 26,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    marginRight: 32,
    '& > $chartSkeleton__label': {
      width: 40,
      marginRight: 'auto',
    },
  },
  chartSkeleton__axis_y: {
    width: 32,
    display: 'flex',
    transform: 'translateY(3px)',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    '& > $chartSkeleton__label': {
      width: 16,
      marginBottom: 'auto',
    },
  },
  chartSkeleton__label: {
    height: 12,
    background: 'var(--control_background)',
    borderRadius: 4,
  },
  chartSkeleton__spinner: {
    color: 'var(--icon_secondary)',
  },
  chartSkeleton__reloadIcon: {
    color: 'var(--icon_secondary)',
  },
  chartSkeleton__reloadText: {
    marginTop: 8,
  },
});

interface RateChartSkeletonProps {
  loading: boolean;
  failed: boolean;
  onRetry(): void;
}

const RateChartSkeleton: React.FC<RateChartSkeletonProps> = memo((props) => {
  const { loading, failed, onRetry } = props;

  const mc = useStyles();

  return (
    <div className={mc.chartSkeletonWrap}>
      <div className={mc.chartSkeleton}>
        <div className={mc.chartSkeleton__chart}>
          {loading && <Spinner size="regular" className={mc.chartSkeleton__spinner} />}
          {failed && (
            <>
              <PanelHeaderButton onClick={onRetry}>
                <Icon36Replay className={mc.chartSkeleton__reloadIcon} />
              </PanelHeaderButton>
              <div className={mc.chartSkeleton__reloadText}>{L.t('failed_to_load')}</div>
            </>
          )}
        </div>
        <div className={mc.chartSkeleton__axis_y}>
          <div className={mc.chartSkeleton__label} />
          <div className={mc.chartSkeleton__label} />
          <div className={mc.chartSkeleton__label} />
          <div className={mc.chartSkeleton__label} />
        </div>
      </div>
      <div className={mc.chartSkeleton__axis_x}>
        <div className={mc.chartSkeleton__label} />
        <div className={mc.chartSkeleton__label} />
        <div className={mc.chartSkeleton__label} />
        <div className={mc.chartSkeleton__label} />
      </div>
    </div>
  );
});

export default RateChartSkeleton;
