import {VkClientInstance} from "./lib/vkClient/types";
import {AppToggles} from "../redux/reducers/app-config";

export async function getDefaultToggles(): Promise<AppToggles> {
  return {
    toggleShowFeed: false,
  }
}

export const loadAppToggles = (vkClient: VkClientInstance): Promise<AppToggles> => {
  return vkClient
    .call('execute.toggles')
    .then( res => {
      return {
        toggleShowFeed: !!(res.toggleShowFeed || false),
      }
    } )
    .catch(e => {
      console.warn("Fail load execute.toggles use default toggles", e)
      return getDefaultToggles();
    })
};
