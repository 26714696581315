import React, { useRef, useEffect, useCallback, memo } from 'react';
import c from 'classnames';
import * as d3 from 'd3';
import useWindowSizes from '../../hooks/useWindowSizes';
import { formatRateAxisLabel, formatDateAxisLabel } from '../../utils/formatter';
import { makeStyles } from '@material-ui/styles';
import { isDesktop } from '@overrided-vkui';

const STROKE_WIDTH = isDesktop ? 1 : 0.5;
const BORDER_RADIUS = isDesktop ? 6 : 10;
const X_AXIS_LINE_MAX_OFFSET = isDesktop ? 55 : 70;
const Y_AXIS_LINE_MIN_OFFSET = 38;

const useStyles = makeStyles({
  chartRoot: {
    width: '100%',
    userSelect: 'none',
  },
  chart: {
    position: 'relative',
    width: '100%',
    display: 'flex',
  },
  chart__image: {
    height: 160,
    zIndex: 1,
    borderRadius: BORDER_RADIUS,
    border: `${STROKE_WIDTH}px solid var(--field_border)`,
    boxSizing: 'border-box',
    background: 'var(--background_content)',
    width: 'inherit',
    overflow: isDesktop ? 'visible' : 'hidden',
  },
  chart__axis: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    color: 'var(--text_tertiary)',
    fontSize: 13,
    lineHeight: '16px',
    overflow: 'inherit',
    '& > li': {
      position: 'relative',
      height: 0,
    },
    whiteSpace: 'nowrap',
  },
  chart__axis_y: {
    textAlign: 'left',
    marginLeft: 16,
    transform: 'translateY(3px)',
    flex: '0 1 auto',
  },
  chart__axis_x: {
    height: 26,
    paddingTop: 10,
    boxSizing: 'border-box',
  },
  chartLabel__date: {
    fontSize: 14,
    color: 'var(--text_tertiary)',
  },
  chartLabel__rate: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 4,
  },
  chartNativeLabel: {
    visibility: 'hidden',
    position: 'absolute',
    bottom: 'calc(100% + 9px)',
    background: 'var(--gray_800_alpha88)',
    padding: '8px 12px',
    borderRadius: 8,
    textAlign: 'center',
    transform: 'translate(-50%,0)',
    whiteSpace: 'pre',
    '& $chartLabel__rate': {
      fontSize: 15,
      fontWeight: 400,
      color: '#fff',
      lineHeight: '20px',
      marginBottom: 1,
    },
    '& $chartLabel__date': {
      fontSize: 13,
      lineHeight: '16px',
      color: 'var(--white_alpha64)',
    },
  },
});

interface RateChartPoint {
  date: number;
  rate: number;
}

interface RateChartProps {
  points: RateChartPoint[];
  onPointFocus(point: RateChartPoint, translateX: number): void;
  onPointBlur(): void;
}

const RateChart: React.FC<RateChartProps> = memo((props) => {
  const { points, onPointFocus, onPointBlur } = props;

  const getClosestPoint = useCallback(
    (date: number) => {
      const index = points.findIndex((point) => point.date >= date);
      const itemMore = points[index];
      const itemLess = points[index - 1];

      if (!itemLess) {
        return itemMore;
      } else if (!itemMore) {
        return itemLess;
      } else if (!itemLess && !itemMore) {
        return null;
      }

      return itemMore.date - date <= date - itemLess.date ? itemMore : itemLess;
    },
    [points]
  );

  const chartRef = useRef<HTMLDivElement>(null);

  const { innerWidth } = useWindowSizes();

  useEffect(() => {
    const el = chartRef.current;

    if (!el) return;

    const svgEl = el.querySelector('[data-el="chart"]');
    const yAxisEl = el.querySelector('[data-el="axis-y"]');
    const xAxisEl = el.querySelector('[data-el="axis-x"]');

    if (!svgEl || !yAxisEl || !xAxisEl) return;

    // y axis label

    const yLabelsCount = Math.floor(svgEl.clientHeight / Y_AXIS_LINE_MIN_OFFSET);

    const minValue = Math.min(...points.map((point) => point.rate));
    const maxValue = Math.max(...points.map((point) => point.rate));
    const valueDelta = Math.max(maxValue - minValue, 0.0001);

    const maxYValue = maxValue + valueDelta * 0.25;
    const minYValue = minValue - valueDelta * 0.25;

    const yLabelValueStep = (maxYValue - minYValue) / yLabelsCount;

    const yLabels = Array(yLabelsCount)
      .fill(0)
      .map((_, index) => maxYValue - yLabelValueStep * index);

    const yAxisScale = d3.scaleLinear().range([0, svgEl.clientHeight]).domain([maxYValue, minYValue]);

    d3.select(yAxisEl)
      .text('')
      .selectAll('ul')
      .data(yLabels)
      .enter()
      .append('li')
      .text(formatRateAxisLabel(minYValue, maxYValue))
      .style('top', (d) => yAxisScale(d) + 'px');

    // x axis labels

    const xLabelsCount = Math.floor(svgEl.clientWidth / X_AXIS_LINE_MAX_OFFSET);

    const minXValue = Math.min(...points.map((point) => point.date));
    const maxXValue = Math.max(...points.map((point) => point.date));
    const xLabelValueStep = (maxXValue - minXValue) / xLabelsCount;

    const xLabels = Array(xLabelsCount)
      .fill(0)
      .map((_, index) => minXValue + xLabelValueStep * index);

    const xAxisScale = d3.scaleLinear().range([0, svgEl.clientWidth]).domain([minXValue, maxXValue]);

    d3.select(xAxisEl)
      .text('')
      .selectAll('ul')
      .data(xLabels)
      .enter()
      .append('li')
      .text(formatDateAxisLabel(minXValue, maxXValue))
      .style('left', (d) => xAxisScale(d) + 'px');

    // svg

    const graph = d3.select(svgEl).text('');

    const graphWidth = svgEl.clientWidth;
    const graphHeight = svgEl.clientHeight;

    // graph background

    graph
      .append('clipPath')
      .attr('id', 'chart-area')
      .append('rect')
      .attr('width', graphWidth)
      .attr('height', graphHeight)
      .attr('x', 0)
      .attr('y', 0)
      .attr('rx', 10)
      .attr('ry', 10);

    graph
      .append('linearGradient')
      .attr('id', 'line-area')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', 0)
      .attr('y1', yAxisScale(maxYValue) || 0)
      .attr('x2', 0)
      .attr('y2', yAxisScale(minYValue) || 0)
      .selectAll('stop')
      .data([
        { offset: '0%', color: 'rgba(63, 138, 224, 0.7)' },
        { offset: '100%', color: 'rgba(63, 138, 224, 0)' },
      ])
      .enter()
      .append('stop')
      .attr('offset', (d) => d.offset)
      .attr('stop-color', (d) => d.color);

    const xStepWidth = graphWidth / xLabels.length;
    const yStepHeigth = graphHeight / yLabels.length;

    graph
      .selectAll('g')
      .data(xLabels.slice(0, -1))
      .enter()
      .append('line')
      .style('stroke', 'var(--background_highlighted)')
      .style('pointer-events', `none`)
      .attr('stroke-width', STROKE_WIDTH)
      .attr('x1', (_, index) => (index + 1) * xStepWidth)
      .attr('y1', 0)
      .attr('x2', (_, index) => (index + 1) * xStepWidth)
      .attr('y2', graphHeight);

    graph
      .selectAll('g')
      .data(yLabels.slice(0, -1))
      .enter()
      .append('line')
      .style('stroke', 'var(--field_border)')
      .attr('stroke-width', STROKE_WIDTH)
      .attr('stroke-dasharray', 8)
      .attr('stroke-dashoffset', 4)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .style('pointer-events', `none`)
      .attr('x1', 0)
      .attr('y1', (_, index) => (index + 1) * yStepHeigth)
      .attr('x2', graphWidth)
      .attr('y2', (_, index) => (index + 1) * yStepHeigth);

    // active point line

    const pointLine = graph
      .append('line')
      .attr('x1', 0)
      .attr('y1', isDesktop ? -10 : 0)
      .attr('x2', 0)
      .attr('y2', '100%')
      .attr('stroke', 'var(--header_text)')
      .style('visibility', `hidden`)
      .style('pointer-events', `none`)
      .attr('stroke-width', 1);

    // graph line

    const line = d3
      .line<RateChartPoint>()
      .x((d) => xAxisScale(d.date) || 0)
      .y((d) => yAxisScale(d.rate) || 0);

    graph
      .append('path')
      .attr('clip-path', 'url(#chart-area)')
      .datum([
        { date: points[0].date - 2000000000, rate: minYValue },
        { date: points[0].date - 1000000000, rate: points[0].rate },
        ...points,
        { date: points[points.length - 1].date + 1000000000, rate: points[0].rate },
        { date: points[points.length - 1].date + 2000000000, rate: minYValue },
      ])
      .attr('stroke', 'var(--accent)')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 2)
      .style('pointer-events', `none`)
      .attr('d', line)
      .attr('fill', 'url(#line-area)');

    // active point dot

    const pointDot = graph
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 5)
      .attr('stroke', 'var(--accent)')
      .attr('stroke-width', 2)
      .style('visibility', `hidden`)
      .style('pointer-events', `none`)
      .style('fill', 'var(--background_content)');

    let prevPoint: RateChartPoint | null = null;

    const clearLabelPosition = function () {
      onPointBlur();
      pointDot.style('visibility', `hidden`);
      pointLine.style('visibility', `hidden`);

      prevPoint = null;
    };

    const drawLabelPosition: d3.ValueFn<d3.BaseType, unknown, void> = function () {
      d3.event.preventDefault();

      const [x] = d3.mouse(this as d3.ContainerElement);
      const date = xAxisScale.invert(x);
      const point = getClosestPoint(date);

      if (!point) {
        clearLabelPosition();
        return;
      }

      if (prevPoint?.date === point.date && prevPoint.rate === point.rate) {
        return;
      }

      prevPoint = point;

      const translateX = xAxisScale(point.date) || 0;
      const translateY = yAxisScale(point.rate) || 0;

      onPointFocus(point, translateX);
      pointDot.style('visibility', `visible`);
      pointLine.style('visibility', `visible`);

      pointDot.style('transform', `translate(${translateX}px, ${translateY}px)`);
      pointLine.style('transform', `translate(${translateX}px, 0)`);
    };

    graph.on('mousemove', drawLabelPosition);
    graph.on('mouseout', clearLabelPosition);

    graph.on('touchstart', drawLabelPosition);
    graph.on('touchmove', drawLabelPosition);
    graph.on('touchend', clearLabelPosition);
    graph.on('touchcancel', clearLabelPosition);

    return () => {
      graph.on('mousemove', null);
      graph.on('mouseout', null);

      graph.on('touchstart', null);
      graph.on('touchmove', null);
      graph.on('touchend', null);
      graph.on('touchcancel', null);
    };
  }, [points, innerWidth, onPointFocus, onPointBlur, getClosestPoint]);

  const mc = useStyles();

  return (
    <div className={mc.chartRoot} ref={chartRef}>
      <div className={mc.chart}>
        <svg className={mc.chart__image} data-el="chart" />
        <ul className={c(mc.chart__axis, mc.chart__axis_y)} data-el="axis-y" />
      </div>
      <ul className={c(mc.chart__axis, mc.chart__axis_x)} data-el="axis-x" />
    </div>
  );
});

export default RateChart;
