import React, { memo, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Input, PanelHeaderButton, HoverTultip } from '@overrided-vkui';
import Icon16Dropdown from '@vkontakte/icons/dist/16/dropdown';
import Icon28SortOutline from '@vkontakte/icons/dist/28/sort_outline';
import CurrencyConverterRateInput from '../../atomic/CurrencyConverterRateInput';
import CurrencyBadge from '../../atomic/CurrencyBadge';
import { isRateInputValueAllowed } from './utils';
import { L } from '../../../lang/L';
import withConverter from '../../hocs/withConverter';

const useStyles = makeStyles({
  root: {
    flex: 1,
    boxSizing: 'border-box',
  },
  root__header: {
    fontWeight: 600,
    fontSize: 13,
    textTransform: 'uppercase',
    color: 'var(--gray_400)',
    lineHeight: '16px',
    padding: 16,
  },
  root__content: {
    padding: '4px 16px',
  },
  currency: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  currency__title: {
    cursor: 'pointer',
    fontSize: 15,
    padding: '2px 0 0',
  },
  currency__titleExpandIcon: {
    marginLeft: 4,
    '& .Icon, & svg': {
      color: 'var(--steel_gray_300)',
    },
  },
  currency__input: {
    margin: '12px 0',
    width: '100%',
  },
  currency__switch: {
    borderRadius: '50%',
    overflow: 'hidden',
    margin: '-4px auto 6px',
    cursor: 'pointer',
    opacity: 0.7,
    '& .Icon, & svg': {
      height: '24px!important',
      width: '24px!important',
    },
    '&:hover': {
      opacity: 1,
    },
  },
});

interface CurrencyData {
  id: string;
  value: string;
  onClick(): void;
  onChange(value: string): void;
}

type FocusedCurrency = 'first' | 'second';

interface ConverterPanelDesktopProps {
  firstCurrency: CurrencyData;
  secondCurrency: CurrencyData;
  onSwitchClick(): void;
  onInput(fromCurrencyId: string, toCurrencyId: string): void;
  focusedCurrency: FocusedCurrency;
  onFocusChange(focused: FocusedCurrency): void;
}

const ConverterPanelDesktop: React.FC<ConverterPanelDesktopProps> = memo((props) => {
  const { firstCurrency, secondCurrency, onInput, onSwitchClick, onFocusChange } = props;

  const mc = useStyles();

  const preventedMouseDownHandler = useCallback((e: React.MouseEvent) => e.preventDefault(), []);

  return (
    <div className={mc.root}>
      <div className={mc.root__header}>{L.t('tab', { context: 'converter' })}</div>
      <div className={mc.root__content}>
        <div className={mc.currency}>
          <HoverTultip title={L.t('converter_select_currency_hint')} placement="top-start">
            <PanelHeaderButton className={mc.currency__title} onClick={firstCurrency.onClick}>
              <CurrencyBadge id={firstCurrency.id} />
              <Icon16Dropdown className={mc.currency__titleExpandIcon} />
            </PanelHeaderButton>
          </HoverTultip>
          <CurrencyConverterRateInput
            value={firstCurrency.value}
            onChange={firstCurrency.onChange}
            onKeyDown={() => onInput(firstCurrency.id, secondCurrency.id)}
            customInput={Input}
            isAllowed={isRateInputValueAllowed}
            className={mc.currency__input}
            onFocus={(): void => onFocusChange('first')}
          />
        </div>
        <PanelHeaderButton
          className={mc.currency__switch}
          onMouseDown={preventedMouseDownHandler}
          onClick={onSwitchClick}
        >
          <Icon28SortOutline fill="var(--steel_gray_300)" />
        </PanelHeaderButton>
        <div className={mc.currency}>
          <HoverTultip title={L.t('converter_select_currency_hint')} placement="top-start">
            <PanelHeaderButton className={mc.currency__title} onClick={secondCurrency.onClick}>
              <CurrencyBadge id={secondCurrency.id} />
              <Icon16Dropdown className={mc.currency__titleExpandIcon} />
            </PanelHeaderButton>
          </HoverTultip>
          <CurrencyConverterRateInput
            value={secondCurrency.value}
            onChange={secondCurrency.onChange}
            customInput={Input}
            isAllowed={isRateInputValueAllowed}
            className={mc.currency__input}
            onFocus={(): void => onFocusChange('second')}
            onKeyDown={() => onInput(secondCurrency.id, firstCurrency.id)}
          />
        </div>
      </div>
    </div>
  );
});

export default withConverter(ConverterPanelDesktop);
