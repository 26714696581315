import React, { memo, useEffect, useCallback } from 'react';
import { RootRoute } from '../../../router/routes';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { Search } from '@overrided-vkui';
import { L } from '../../../lang/L';
import { makeStyles } from '@material-ui/styles';
import SelectedCurrenciesList from '../../partial/SelectedCurrenciesList';
import CurrenciesSearchSelectList from '../../partial/CurrenciesSearchSelectList';
import useCurrencies from '../../../hooks/currency/useCurrencies';
import { throttle } from 'throttle-debounce';
import { useAppContext } from 'src/components/AppContext';

const useStyles = makeStyles({
  search: {
    position: 'sticky',
    top: 55,
    zIndex: 29,
    overflow: 'visible',
    '& .Search': {
      borderRadius: '6px 6px 0 0',
      background: 'var(--search_bar_background)',
      border: '1px solid #DCE1E6',
      borderTop: 0,
      borderBottom: 0,
      margin: -1,
      marginBottom: 0,
    },
  },
  list: {
    margin: '10px 0',
    '& .Placeholder': {
      padding: '150px 0',
    },
  },
  searchIcons: {
    position: 'absolute',
    top: 0,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: 16,
    '& .Icon': {
      color: 'var(--steel_gray_300)',
      opacity: 0.7,
      cursor: 'pointer',

      '&:hover': {
        opacity: 1,
      },
    },
    '& > * + *': {
      marginLeft: 16,
    },
  },
});

interface CurrenciesSectionDesktopProps {
  route: RootRoute;
  onCurrencyClick(currencyId: string): void;
  onCurrencyObserversClick(currencyId: string): void;
}

const CurrenciesSectionDesktop = memo((props: CurrenciesSectionDesktopProps) => {
  const { route, onCurrencyClick, onCurrencyObserversClick } = props;

  const { statEvents, isOKClient } = useAppContext();

  const throttledSendStatEvents = useCallback(throttle(500, statEvents.push), []);

  useEffect(() => {
    statEvents.push('navigation_go', { screen: 'my_list' });
  }, []); // eslint-disable-line

  /* Обновление данных */

  const { refreshCurrencies } = useCurrencies();

  useEffect(() => {
    refreshCurrencies().catch(() => null);
  }, []); // eslint-disable-line

  /* Роутинг поиска */

  const [searchValue, setSearchValue] = useSearchQuery(route);
  const isSearchMode = searchValue !== '';

  /* Рендер */

  const mc = useStyles();

  return (
    <div>
      <Search
        className={mc.search}
        onChange={(e): void => {
          window.scrollTo(0, 0);
          setSearchValue(e.target.value);
          throttledSendStatEvents('search', { screen: 'my_list', text: e.target.value });
        }}
        value={searchValue}
        onFocus={() => statEvents.push('search_click', { screen: 'my_list' })}
        placeholder={L.t('common:search_placeholder')}
        after={L.t('common:cancel')}
      />
      {isSearchMode ? (
        <CurrenciesSearchSelectList
          className={mc.list}
          onCurrencyClick={onCurrencyClick}
          search={searchValue}
          skipBaseCurrency
        />
      ) : (
        <SelectedCurrenciesList
          className={mc.list}
          onCurrencyClick={onCurrencyClick}
          onFindCurrenciesClick={() => setSearchValue(' ')}
          openCurrencyObserversModal={isOKClient ? void 0 : onCurrencyObserversClick}
        />
      )}
    </div>
  );
});

export default CurrenciesSectionDesktop;
