import React, { memo } from 'react';
import { MiniPostAttachmentTypeArticle } from '../../../types/miniPost';
import { makeStyles } from '@material-ui/styles';
import { Title, Subhead, Caption, Button } from '@vkontakte/vkui';
import Icon24Flash from '@vkontakte/icons/dist/24/flash';
import RatioBlock from '../../atomic/RatioBlock';
import { L } from '../../../lang/L';

const useStyles = makeStyles({
  attachment__preview: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'block',
  },
  attachment__layer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.4)',
    color: '#fff',
    textAlign: 'center',
    overflow: 'hidden',
    padding: 16,
    boxSizing: 'border-box',
  },
  title: {
    marginBottom: 8,
  },
  targetButton: {
    marginTop: 24,
    pointerEvents: 'none',
  },
});

const MiniPostAttachmentArticle = memo((props: MiniPostAttachmentTypeArticle) => {
  const { sourceName, image, title } = props;

  const mc = useStyles();

  return (
    <RatioBlock h={340} w={600}>
      <div className={mc.attachment__preview} style={{ backgroundImage: `url(${image.url})` }} />
      <div className={mc.attachment__layer}>
        <Title level="1" weight="heavy" className={mc.title}>
          {title}
        </Title>
        <Subhead weight="regular">{sourceName}</Subhead>
        <Button
          mode="overlay_primary"
          size="l"
          before={<Icon24Flash height={16} width={16} />}
          className={mc.targetButton}
        >
          <Caption level="2" weight="semibold" caps>
            {L.t('post_article_read_button')}
          </Caption>
        </Button>
      </div>
    </RatioBlock>
  );
});

export default MiniPostAttachmentArticle;
