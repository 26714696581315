import React, { memo, useCallback, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import { copyToClipboard } from '../../utils/copying';
import Icon56AddCircleOutline from '@vkontakte/icons/dist/56/add_circle_outline';
import { Root, View, Panel } from '@vkontakte/vkui';
import { ModalPageHeader, ModalPage, ModalRoot, isDesktop, Button, Div } from '@overrided-vkui';
import { L } from '../../lang/L';

interface IProps {
  onRestartClick(): void;
  error: string;
}

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  crashIcon: {
    transform: 'rotate(45deg)',
    color: 'var(--activity_indicator_tint)',
  },
  button: {
    '& + &': {
      marginTop: 10,
    },
  },
  error: {
    whiteSpace: 'pre-wrap',
  },
  header: {
    fontSize: 21,
    lineHeight: '26px',
  },
});

enum AppCrashModal {
  BUTTONS = 'buttons',
  INFO = 'info',
}

/**
 * Вью которая отображается в случае, когда в приложении произошла ошибка.
 * @type {React.NamedExoticComponent<IProps>}
 */
const AppCrashedView = memo((props: IProps) => {
  const { onRestartClick, error } = props;
  const mc = useStyles(props);

  const [activeModal, setActiveModal] = useState<AppCrashModal>(AppCrashModal.BUTTONS);
  const [copying, setCopying] = useState(false);

  const onCopyClick = useCallback(async () => {
    setCopying(true);
    try {
      await copyToClipboard(error);
    } finally {
      setCopying(false);
    }
  }, [error]);

  const modal = (
    <ModalRoot activeModal={activeModal} onClose={() => null}>
      <ModalPage id={AppCrashModal.BUTTONS} header={<ModalPageHeader>{L.t('crash_view_title')}</ModalPageHeader>}>
        <Div>
          <Button size="xl" onClick={onRestartClick} className={mc.button}>
            {L.t('restart_app')}
          </Button>
          <Button size="xl" mode="secondary" onClick={() => setActiveModal(AppCrashModal.INFO)} className={mc.button}>
            {L.t('crash_view_more_info')}
          </Button>
        </Div>
      </ModalPage>
      <ModalPage
        id={AppCrashModal.INFO}
        onClose={() => setActiveModal(AppCrashModal.BUTTONS)}
        header={<ModalPageHeader>{L.t('common:error')}</ModalPageHeader>}
      >
        <Div className={mc.error}>{error}</Div>
        <Div>
          <Button size="xl" onClick={onCopyClick} disabled={copying}>
            {L.t('copy_to_clipboard')}
          </Button>
        </Div>
      </ModalPage>
    </ModalRoot>
  );

  return (
    <Root activeView="main" modal={modal}>
      <View id="main" activePanel="main-panel">
        <Panel id="main-panel">
          {!isDesktop && (
            <div className={mc.root}>
              <Icon56AddCircleOutline className={mc.crashIcon} />
            </div>
          )}
        </Panel>
      </View>
    </Root>
  );
});

export default AppCrashedView;
