import React, { memo, useMemo, useCallback, useEffect, useState } from 'react';
import useBaseCurrency from '../../../hooks/currency/useBaseCurrency';
import useSelectedCurrencies from '../../../hooks/currency/useSelectedCurrencies';
import { useActions } from '../../../hooks/useActions';
import { useSelector } from '../../../hooks/useSelector';
import useAPI from '../../tools/APIProvider/useAPI';
import useAsync from '../../../hooks/useAsync';
import { currencyActions } from '../../../redux/reducers/currency';
import { isStoryShareSupports, shareCurrencyByStory } from '../../../utils/share';
import { formatPercent, parseDelta, formatRate } from '../../../utils/formatter';
import { delay } from '../../../utils/misc';
import { getCurrencyIconUrl } from '../../../utils/currency-icon-url';
import { getCurrency } from '../../../redux/selectors';
import { BaseRelatedCurrency } from '../../../types/currency';
import { OverlayPopoutWrapper } from '@overrided-vkui';
import { ScreenSpinner } from '@vkontakte/vkui';
import { Taptic } from '../../../utils/taptic';
import { useAppContext } from 'src/components/AppContext';

const SHARE_SPINNER_FALLBACK_CLOSE_DELAY = 4000;

export interface WithCurrencyInfoModalInjectedProps {
  areNotificationsEnabled: boolean;
  isSelected: boolean;
  toggleSeleted(): void;
  currency: BaseRelatedCurrency | null;
  isStoryShareSupports: boolean;
  openStoryShare(): void;
  isObserversSupports: boolean;
  openObservers(): void;
  openTerms(): void;
  currencySeriesFetchStatus: 'idle' | 'loading' | 'error' | 'done';
  refetchCurrencySeries(): void;
}

function withCurrencyInfoModalData<
  T extends { currencyId: string; openObservers(currencyId: string): void; openTerms(): void }
>(Component: React.FC<T & WithCurrencyInfoModalInjectedProps>): React.FC<T> {
  return memo((props) => {
    const { currencyId, openObservers, openTerms } = props;

    const { calcBaseRelatedCurrency } = useBaseCurrency();
    const { getCurrencySeries } = useAPI();
    const { selectCurrencyId, unselectCurrencyId, selectedCurrencyIds } = useSelectedCurrencies();

    const setCurrencySeries = useActions(currencyActions.setCurrencySeries);

    const {
      statEvents,
      launchParams: { appId, platform },
      isOKClient,
    } = useAppContext();

    useEffect(() => {
      statEvents.push('navigation_go', { screen: 'currency' });
    }, []); // eslint-disable-line

    /* Данные об окружении */

    const areNotificationsEnabled = useSelector((state) => state.user.areNotificationsEnabled);

    /* Данные о валюте */

    const relativeCurrency = useSelector(getCurrency(currencyId));

    const currency = relativeCurrency && calcBaseRelatedCurrency(relativeCurrency);
    const isSelected = useMemo(() => (currency ? selectedCurrencyIds.includes(currency.id) : false), [
      currency,
      selectedCurrencyIds,
    ]);

    /* Данные о графике валюты */

    const [loadCurrencySeries, currencySeriesStatus] = useAsync(() => {
      return getCurrencySeries([currencyId]).then(
        (series) => series[0] && setCurrencySeries([{ currencyId, series: series[0] }])
      );
    });

    /* В фоне обновляем график валюты и список обсерверов */

    useEffect(() => {
      loadCurrencySeries().catch(() => null);
    }, []); // eslint-disable-line

    /* Скрин-спиннер перед появлением стори бокса */

    const [screenSpinnerShown, setScreenSpinnerShown] = useState(false);

    /* Подготавливаем шару в стори */

    const openStoryShare = useCallback(() => {
      if (!currency) return;

      const [absoluteValue, direction] = parseDelta(currency.rate - currency.prevRate);
      const difference = `${formatRate(absoluteValue, currency.base.symbol)} (${formatPercent(
        absoluteValue / currency.rate
      )})`;

      const appUrl = `https://vk.com/app${appId}#/?currency=${currencyId}`;

      setScreenSpinnerShown(true);
      statEvents.push('sharing_click', { name: currency.id });
      Promise.race([
        shareCurrencyByStory(
          appUrl,
          {
            name: currency.id,
            iconUrl: getCurrencyIconUrl(currency.id),
            rate: formatRate(currency.rate, currency.base.symbol),
            difference,
            direction,
          },
          platform
        )
          .then(() => {
            statEvents.push('sharing_success', { name: currency.id });
          })
          .catch(() => null),
        delay(SHARE_SPINNER_FALLBACK_CLOSE_DELAY),
      ]).finally(() => setScreenSpinnerShown(false));
    }, [appId, currency, currencyId, platform, statEvents]);

    /* Обработчики действий */

    const wrappedOpenObservers = useCallback(() => {
      openObservers(currencyId);
      statEvents.push('go_notifications', { name: currencyId, screen: 'currency' });
    }, [openObservers, currencyId, statEvents]);

    const wrappedOpenTerms = useCallback(() => {
      openTerms();
      statEvents.push('go_limitation', { name: currencyId });
    }, [openTerms, currencyId, statEvents]);

    const [toggleSelected] = useAsync(
      () => {
        const action = isSelected ? unselectCurrencyId : selectCurrencyId;
        return action(currencyId).then(() => {
          statEvents.push(isSelected ? 'item_remove' : 'item_add', {
            screen: 'settings',
            name: currencyId,
          });
        });
      },
      { retry: 'snackbar' }
    );

    /* Рендер */

    return (
      <>
        <Component
          {...(props as T)}
          isSelected={isSelected}
          areNotificationsEnabled={areNotificationsEnabled}
          currency={currency}
          isStoryShareSupports={!isOKClient && isStoryShareSupports()}
          openStoryShare={openStoryShare}
          isObserversSupports={!isOKClient}
          openTerms={wrappedOpenTerms}
          openObservers={wrappedOpenObservers}
          toggleSeleted={() => {
            Taptic.selectionChanged();
            toggleSelected().catch(() => null);
          }}
          refetchCurrencySeries={() => loadCurrencySeries().catch(() => null)}
          currencySeriesFetchStatus={currencySeriesStatus}
        />
        <OverlayPopoutWrapper show={screenSpinnerShown}>
          <ScreenSpinner />
        </OverlayPopoutWrapper>
      </>
    );
  });
}

export default withCurrencyInfoModalData;
