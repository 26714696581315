import React, { memo, useCallback } from 'react';
import { Currency } from '../../types/currency';
import CurrenciesSearchList from './CurrenciesSearchList';
import { PanelHeaderButton, getPlatformClassName, isDesktop, HoverTultip } from '@overrided-vkui';
import useSelectedCurrencies from '../../hooks/currency/useSelectedCurrencies';
import Icon28AddOutline from '@vkontakte/icons/dist/28/add_outline';
import Icon28DoneOutline from '@vkontakte/icons/dist/28/done_outline';
import { makeStyles } from '@material-ui/styles';
import useAsync from '../../hooks/useAsync';
import Icon24AddOutline from '@vkontakte/icons/dist/24/add_outline';
import Icon24DoneOutline from '@vkontakte/icons/dist/24/done_outline';
import { L } from '../../lang/L';
import { Taptic } from '../../utils/taptic';
import { useAppContext } from '../AppContext';

const useStyles = makeStyles({
  subscribeButton: {
    marginRight: -8,
    '&--desktop': {
      marginRight: -12,
      '--accent': 'var(--blue_400)',
      '--toolbar_attach_background_from': 'var(--steel_gray_200)',
    },
    '& > .Icon': {
      padding: '10px!important',
    },
  },
});

interface CurrenciesSearchSelectListProps {
  search: string;
  skipBaseCurrency?: boolean;
  sorted?: boolean;
  onCurrencyClick?(currencyId: string): void;
  className?: string;
  scrollParentEl?: HTMLElement;
}

const CurrenciesSearchSelectList: React.FC<CurrenciesSearchSelectListProps> = memo((props) => {
  const { selectedCurrencyIds, selectCurrencyId, unselectCurrencyId } = useSelectedCurrencies();

  const mc = useStyles();

  const { statEvents } = useAppContext();

  const [setCurrencySelect] = useAsync(
    (currencyId: string, selected: boolean) => {
      const action = selected ? selectCurrencyId : unselectCurrencyId;
      return action(currencyId).then(() => {
        statEvents.push(selected ? 'item_add' : 'item_remove', { screen: 'search', name: currencyId });
      });
    },
    { retry: 'snackbar' }
  );

  const IconAdd = isDesktop ? Icon24AddOutline : Icon28AddOutline;
  const IconDone = isDesktop ? Icon24DoneOutline : Icon28DoneOutline;

  const renderAfter = useCallback(
    (currency: Currency) => {
      const isSelected = selectedCurrencyIds.includes(currency.id);

      const toggleSelection = (e: React.MouseEvent): void => {
        e.stopPropagation();
        Taptic.selectionChanged();
        setCurrencySelect(currency.id, !isSelected).catch(() => null);
      };

      return (
        <HoverTultip
          title={L.t(isSelected ? 'button_add_currency_done_short' : 'button_add_currency_short')}
          placement="top-end"
          margin="0 -20px -2px 0"
        >
          <PanelHeaderButton onClick={toggleSelection} className={getPlatformClassName(mc.subscribeButton)}>
            {isSelected ? <IconDone fill="var(--toolbar_attach_background_from)" /> : <IconAdd fill="var(--accent)" />}
          </PanelHeaderButton>
        </HoverTultip>
      );
    },
    [selectedCurrencyIds, setCurrencySelect, mc]
  );

  return <CurrenciesSearchList {...props} renderAfter={renderAfter} />;
});

export default CurrenciesSearchSelectList;
