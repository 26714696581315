import React, { memo, useEffect, useState } from 'react';
import { useOverlayContext } from './context';
import { ModalPageProps } from '@vkontakte/vkui/dist/components/ModalPage/ModalPage';
import { createIterator } from './utils';
import usePrevious from './usePrevious';

export interface ModalPageContextWrapperProps {
  show: boolean;
  children: React.ReactElement<ModalPageProps>;
}

const nextModalId = createIterator('overlay-modal');

const ModalPageContextWrapper: React.FC<ModalPageContextWrapperProps> = memo(({ show, children }) => {
  const { showModal, hideModal } = useOverlayContext();

  const [id] = useState(nextModalId);
  const prevShow = usePrevious(show);

  useEffect(() => {
    if (!show && prevShow) {
      hideModal(id);
    }
  }, [show, prevShow, id, hideModal]);

  useEffect(() => {
    if (show) {
      showModal(id, children);
    }
  }, [showModal, show, id, children]);

  useEffect(() => (): void => hideModal(id), [id, hideModal]);

  return null;
});

export default ModalPageContextWrapper;
