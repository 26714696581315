import React, { memo } from 'react';
import VKCell, { CellProps } from '@vkontakte/vkui/dist/components/Cell/Cell';
import c from 'classnames';
import { makeStyles } from '@material-ui/styles';
import PanelHeaderButton from '../PanelHeaderButton';
import HoverTultip from '../HoverTultip';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
const useStyles = makeStyles({
  cell: {
    display: 'flex',
    overflow: 'initial',
    '& .Cell__remove-marker': {
      display: 'none',
    },
    '& .Cell__dragger': {
      cursor: 'pointer',
      opacity: 0.76,
      color: 'transparent',
      '& > .Icon': {
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17H20ZM20 13C20.5523 13 21 13.4477 21 14C21 14.5523 20.5523 15 20 15H4C3.44772 15 3 14.5523 3 14C3 13.4477 3.44772 13 4 13H20ZM20 9C20.5523 9 21 9.44772 21 10C21 10.5523 20.5523 11 20 11H4C3.44772 11 3 10.5523 3 10C3 9.44772 3.44772 9 4 9H20ZM20 5C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5H20Z' fill='%2399A2AD'/%3E%3C/svg%3E%0A")`, // eslint-disable-line
      },
    },
    '&.Cell--draggable': {
      '& .Cell__aside': {
        order: -1,
        paddingLeft: 0,
        paddingRight: 16,
      },
    },
    '& .Cell__in': {
      paddingLeft: 24,
      paddingRight: 24,
      flex: '1 0 auto',
      boxSizing: 'border-box',
      overflow: 'initial',
    },
    '--text_secondary': 'var(--steel_gray_400)',
    '& .Cell__children, & .SimpleCell__indicator': {
      fontSize: 15,
      lineHeight: '20px',
    },
    '& .Cell__main': {
      padding: '10px 0',
      overflow: 'initial',
      minWidth: 0,
    },
    '& .Cell__children': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      overflow: 'initial',
    },
  },
  simpleCell_tappable: {
    '&.Tappable': {
      cursor: 'pointer',
      transition: 'none',
      '&:hover': {
        backgroundColor: 'var(--steel_gray_40)',
      },
    },
  },
  children: {
    overflow: 'hidden',
  },
  remover: {
    marginRight: -12,
  },
  remover__in: {
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
});

const SimpleCellDesktop: React.FC<CellProps> = memo((props) => {
  const { className, disabled, onClick, removable, onRemove, children, removePlaceholder, ...rest } = props;
  const mc = useStyles();

  return (
    <VKCell
      className={c(className, mc.cell, !disabled && onClick && mc.simpleCell_tappable)}
      {...rest}
      onClick={onClick}
      disabled
      expandable={false}
    >
      <div className={mc.children}>{children}</div>
      {removable && (
        <div className={mc.remover}>
          <HoverTultip title={removePlaceholder || 'Удалить'} placement="top-end" margin="0 -8px 6px 0">
            <PanelHeaderButton onClick={onRemove && ((e) => onRemove(e, e.currentTarget))} className={mc.remover__in}>
              <Icon24Cancel fill="var(--steel_gray_300)" />
            </PanelHeaderButton>
          </HoverTultip>
        </div>
      )}
    </VKCell>
  );
});

export default SimpleCellDesktop;
