import { useStorageValue } from '../useStorageValue';
import { StorageField } from '../../types';
import { useState, useCallback } from 'react';
import useAPI from '../../components/tools/APIProvider/useAPI';
import { useActions } from '../useActions';
import { currencyActions } from '../../redux/reducers/currency';
import config from '../../config';
import { useSelector } from 'react-redux';
import { getSelectedCurrencies } from '../../redux/selectors';
import useBaseCurrency from './useBaseCurrency';

const useSelectedCurrencies = () => {
  const [selectedCurrencyIds = [], setSelectedCurrencyIds] = useStorageValue(StorageField.SelectedCurrencyIds);
  const [baseCurrencyId = config.fallbackBaseCurrencyId] = useStorageValue(StorageField.BaseCurrencyId);
  const [syncedCurrencyIds, setSyncedCurrencyIds] = useState(selectedCurrencyIds);
  const setHeadCurrencies = useActions(currencyActions.setHeadCurrencies);
  const { calcBaseRelatedCurrency } = useBaseCurrency();
  const selectedCurrencies = useSelector(getSelectedCurrencies()).map(calcBaseRelatedCurrency);
  const setCurrencySeries = useActions(currencyActions.setCurrencySeries);
  const { getCurrencySeries } = useAPI();

  const { setWidgetCurrencies } = useAPI();

  const setCurrencyIds = useCallback(
    (newCurrencyIds: string[], newBaseCurrencyId: string = baseCurrencyId) => {
      const widgetCurrencyIds = selectedCurrencyIds.slice(0, config.widgetCurrenciesCount);
      const newWidgetCurrencyIds = newCurrencyIds.slice(0, config.widgetCurrenciesCount);

      return setSelectedCurrencyIds(newCurrencyIds)
        .then(() => {
          if (
            newWidgetCurrencyIds.length === 0 ||
            (widgetCurrencyIds.join() === newWidgetCurrencyIds.join() && baseCurrencyId === newBaseCurrencyId)
          ) {
            return true;
          }

          return setWidgetCurrencies(newWidgetCurrencyIds, newBaseCurrencyId);
        })
        .then(() => {
          setSyncedCurrencyIds(newCurrencyIds);
          setHeadCurrencies([newBaseCurrencyId, ...newCurrencyIds]);
        })
        .catch(() => {
          return setSelectedCurrencyIds(syncedCurrencyIds).then((e) => Promise.reject(e));
        });
    },
    [
      baseCurrencyId,
      selectedCurrencyIds,
      setHeadCurrencies,
      setSelectedCurrencyIds,
      setWidgetCurrencies,
      syncedCurrencyIds,
    ]
  );

  return {
    selectedCurrencyIds,
    selectedCurrencies,
    selectCurrencyId: (currencyId: string): Promise<void> => {
      if (selectedCurrencyIds.find((id) => id === currencyId) !== void 0) {
        return Promise.resolve();
      }

      getCurrencySeries([currencyId])
        .then((series) => series[0] && setCurrencySeries([{ currencyId, series: series[0] }]))
        .catch(() => null);

      return setCurrencyIds(selectedCurrencyIds.concat(currencyId));
    },
    unselectCurrencyId: (currencyId: string): Promise<void> => {
      return setCurrencyIds(selectedCurrencyIds.filter((id) => id !== currencyId));
    },
    setSelectedCurrencyIds: setCurrencyIds,
  };
};

export default useSelectedCurrencies;
