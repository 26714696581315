import { AnyRestriction, LaunchParams } from '../types';

type Parser<K extends keyof LaunchParams> = [K, (value: string) => LaunchParams[K]];
type TAnyParser = { [K in keyof LaunchParams]: Parser<K> }[keyof LaunchParams];

/**
 * Returns value as it is
 * @param value
 * @returns {any}
 */
function asIs<T>(value: T): T {
  return value;
}

/**
 * Parses string representation of boolean
 * @param {string} value
 * @returns {boolean}
 */
function parseBoolean(value: string): boolean {
  return value === '1' || value === 'true';
}

/**
 * Парсит значение, как ограничения от ВКонтакте.
 * @param value
 */
function parseRestrictions(value: string): AnyRestriction[] {
  return value.split(',');
}

const paramParsers: Record<string, TAnyParser> = {
  vk_user_id: ['userId', parseInt],
  vk_app_id: ['appId', parseInt],
  vk_is_app_user: ['isAppUser', parseBoolean],
  vk_are_notifications_enabled: ['areNotificationsEnabled', parseBoolean],
  vk_language: ['language', asIs],
  vk_ref: ['ref', asIs],
  vk_access_token_settings: ['accessTokenSettings', (value) => (value.length === 0 ? [] : value.split(','))],
  vk_group_id: ['groupId', parseInt],
  vk_viewer_group_role: ['viewerGroupRole', asIs],
  vk_platform: ['platform', asIs],
  vk_is_favorite: ['isFavorite', parseBoolean],
  vk_ts: ['ts', parseInt],
  vk_client: ['client', asIs],
  vk_restrictions: ['restrictions', parseRestrictions],
  sign: ['sign', asIs]
};

/**
 * Возвращает параметры запуска приложения.
 * @param query
 */
export function getLaunchParams(query: string): LaunchParams {
  return query.split('&').reduce<any>(
    (acc, pair) => {
      const [key, value = ''] = pair.split('=');

      if (key in paramParsers) {
        const [field, parse] = paramParsers[key];
        acc[field] = parse(value);
      }
      return acc;
    }, {
      accessTokenSettings: [],
      appId: 0,
      areNotificationsEnabled: false,
      isAppUser: false,
      isFavorite: false,
      language: 'ru',
      platform: 'desktop_web',
      ref: 'other',
      restrictions: [],
      userId: 0,
      groupId: null,
      viewerGroupRole: null,
      sign: '',
      client: null,
      ts: null
    } as LaunchParams
  );
}
