import React, { memo } from 'react';
import c from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { prettifyRateValue } from '../../../utils/formatter';
import AdaptiveFontSize from '../../atomic/AdaptiveFontSize';

const useStyles = makeStyles({
  currency__rate: {
    height: 56,
    outline: 'none',
    cursor: 'text',
    width: '100%',
    display: 'block',
    opacity: 0,
    animation: `$caretTick 0.01s step-end forwards`,
  },
  currency__rate_focused: {
    '& $currency__rateCaret': {
      display: 'inline-block',
    },
  },
  '@keyframes caretTick': {
    '50%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  currency__rateCaret: {
    height: '1em',
    background: 'var(--accent)',
    width: 2,
    marginRight: -2,
    borderRadius: 2,
    left: '0.07em',
    position: 'relative',
    animation: `$caretTick 1.2s steps(1, end) infinite`,
    display: 'none',
    verticalAlign: 'middle',
    marginTop: '-0.26em',
  },
});

interface CurrencyConverterRateLabelProps {
  value: string;
  focused: boolean;
  onFocus(): void;
}

const CurrencyConverterRateLabel: React.FC<CurrencyConverterRateLabelProps> = memo((props) => {
  const { value, focused, onFocus } = props;

  const mc = useStyles();

  return (
    <AdaptiveFontSize
      className={c(mc.currency__rate, { [mc.currency__rate_focused]: focused })}
      tabIndex={0}
      onFocus={onFocus}
      minSize={16}
      maxSize={56}
      lineHeight="56px"
    >
      {prettifyRateValue(value)}
      <span className={mc.currency__rateCaret} />
    </AdaptiveFontSize>
  );
});

export default CurrencyConverterRateLabel;
