import { useCallback } from 'react';
import vkBridge from '@vkontakte/vk-bridge';
import { useAppContext } from '../components/AppContext';
import { useSelector } from './useSelector';

type TRequestNotificationPermission = () => Promise<boolean>;
type TUseNotificationsTuple = [TRequestNotificationPermission];

/**
 * Возвращает функционал, связанный с уведомлениями.
 */
export function useNotifications(): TUseNotificationsTuple {
  const areNotificationsEnabled = useSelector(store => store.user.areNotificationsEnabled);
  const { statEvents, isOKClient } = useAppContext();

  /**
   * Запрашивает у пользователя разрешение на отправку уведомлений.
   */
  const requestNotificationPermission = useCallback<TRequestNotificationPermission>(
    async () => {
      if (isOKClient) {
        // Если уведомления запрашиваются в одноклассниках, считаем действие неуспешным
        return false;
      }
      if (areNotificationsEnabled) {
        // Если уведомления уже были запрошены, считаем действие успешным
        return true;
      }
      statEvents.push('notifications_permission_request', {});

      try {
        await vkBridge.send('VKWebAppAllowNotifications', {});
        statEvents.push('notifications_permission_accept', {});
        return true;
      } catch (e) {
        statEvents.push('notifications_permission_declined', {});
        return false;
      }
    }, [areNotificationsEnabled, isOKClient, statEvents]
  );

  return [requestNotificationPermission];
}