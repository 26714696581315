import React, { memo, useState, useCallback, useEffect } from 'react';
import { Subtract } from '../../types/utility';
import { useStorageValue } from '../../hooks/useStorageValue';
import { StorageField } from '../../types';
import { useAppContext } from '../AppContext';
import {useSelector} from "../../hooks/useSelector";

export interface WithFeedHintInjectedProps {
  showFeed: boolean;
  hintOpened: boolean;
  closeHint(): void;
}

export function withFeedHint<P extends WithFeedHintInjectedProps>(
  Component: React.FC<P>
): React.FC<Subtract<P, WithFeedHintInjectedProps>> {
  return memo((props) => {

    const [hintOpened, setHintOpened] = useState(false);
    const [hintShown, setHintShown] = useStorageValue(StorageField.FeedHintShown);
    const { isOKClient } = useAppContext()
    // в однокасниках ленту не показываем потому что апишки нет
    const toggleShowFeed = useSelector(state => state.appConfig.toggleShowFeed) && !isOKClient;

    const { statEvents } = useAppContext();

    useEffect(() => {
      if (!toggleShowFeed) return;
      if (!hintShown) {
        statEvents.send('feed_tooltip_shown', {});
        setHintOpened(true);
        setHintShown(true);
      }
    }, []); // eslint-disable-line

    const closeHint = useCallback(() => {
      setHintOpened(false);
    }, []);

    if (toggleShowFeed) {
      return <Component {...(props as P)} showFeed={toggleShowFeed} hintOpened={hintOpened} closeHint={closeHint} />;
    } else {
      return <Component {...(props as P)} showFeed={toggleShowFeed} hintOpened={false} closeHint={() => null} />;
    }

  });
}
