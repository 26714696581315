import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import CurrencyIcon from '../../atomic/CurrencyIcon';
import c from 'classnames';

const useStyle = makeStyles({
  badge: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    width: '100%',
    justifyContent: 'flex-end',
  },
  badge__name: {
    marginLeft: '0.625em',
    overflow: 'hidden',
  },
});

interface CurrencyBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
}

const CurrencyBadge: React.FC<CurrencyBadgeProps> = memo((props) => {
  const { id, className, ...restProps } = props;
  const mc = useStyle();

  return (
    <div className={c(mc.badge, className)} {...restProps}>
      <div className={mc.badge__name}>{id}</div>
      <CurrencyIcon id={id} size={'1.25em'} />
    </div>
  );
});

export default CurrencyBadge;
