import React, { memo } from 'react';
import {
  ModalPage,
  Div,
  ModalPageHeader,
  PanelHeaderBack,
  PanelHeaderButton,
  isDesktop,
  getPlatformClassName,
  withModalPageOverlayWrapper,
} from '@overrided-vkui';
import { L } from '../../lang/L';
import { makeStyles } from '@material-ui/styles';
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss';

const useStyles = makeStyles({
  root: {
    '&--desktop': {
      lineHeight: '18px',
      fontSize: 14,
    },
    fontSize: 15,
    '& p': {
      margin: 0,
    },
    '& p + p': {
      marginTop: '12px!important',
    },
  },
});

interface TermsModalProps {
  id: string;
  withBackButton?: boolean;
  onClose(): void;
}

const TermsModal: React.FC<TermsModalProps> = memo((props) => {
  const { id: modalId, withBackButton, onClose } = props;

  const mc = useStyles();

  return (
    <ModalPage
      id={modalId}
      onClose={onClose}
      header={
        <ModalPageHeader
          left={isDesktop && withBackButton && <PanelHeaderBack onClick={onClose} />}
          right={
            !isDesktop && (
              <PanelHeaderButton onClick={onClose}>
                <Icon24Dismiss />
              </PanelHeaderButton>
            )
          }
        >
          {L.t('terms')}
        </ModalPageHeader>
      }
    >
      <Div className={getPlatformClassName(mc.root)}>
        {L.t('terms_content')
          .split('<br>')
          .map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
      </Div>
    </ModalPage>
  );
});

export default withModalPageOverlayWrapper(TermsModal);
