import HoverTultipDesktop from './HoverTultipDesktop';
import HoverTultipMobile from './HoverTultipMobile';
import { getPlatformComponent } from '../utils/getPlatformComponent';

export interface HoverTultipProps {
  placement?: 'top-start' | 'top' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end';
  title: React.ReactNode;
  children: React.ReactElement;
  className?: string;
  margin?: number | string;
}

export default getPlatformComponent<HoverTultipProps>({ mobile: HoverTultipMobile, desktop: HoverTultipDesktop });
