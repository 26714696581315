import { StatEventType } from '../utils/lib/statEvents';
import { ofType } from 'unionize';

/**
 * Список поддерживаемых событий
 */
export const currencyEvents = {
  /* Запуск приложения */
  launch: {
    type: StatEventType.Navgo,
    payload: ofType<{ ref: string; notification_type?: 'observer_resolved'; items_count: number }>(),
  },

  /* Переход на экран */
  navigation_go: {
    type: StatEventType.Navgo,
    payload: ofType<{
      screen: 'my_list' | 'converter' | 'settings' | 'currency' | 'feed' | 'notification_list';
    }>(),
  },

  /* Клик на валюту */
  item_click: { type: StatEventType.Click, payload: ofType<{ name: string }>() },

  /* Перемена валют местами */
  converter_swap: { type: StatEventType.Click, payload: {} },

  /* Ввод данных в конвертер */
  converter_input: { type: StatEventType.Click, payload: ofType<{ from: string; to: string }>() },

  /* Выбор какой-то валюты в конвертере */
  convert_select: { type: StatEventType.Click, payload: ofType<{ name: string; type: 'from' | 'to' }>() },

  /* Выбор базовой валюты */
  settings_base_currency_select: { type: StatEventType.Click, payload: ofType<{ name: string }>() },

  /* Cмена позиции валюты */
  settings_item_swap: { type: StatEventType.Click, payload: ofType<{ name: string; from: number; to: number }>() },

  /* Клик на поиск */
  search_click: {
    type: StatEventType.Click,
    payload: ofType<{ screen: 'my_list' | 'converter_select' | 'settings' | 'base_currency_select' }>(),
  },

  /* Поиск */
  search: {
    type: StatEventType.View,
    payload: ofType<{ text: string; screen: 'my_list' | 'converter_select' | 'settings' | 'base_currency_select' }>(),
  },

  /* Удаление валюты из списка */
  item_remove: {
    type: StatEventType.Click,
    payload: ofType<{ name: string; screen: 'search' | 'currency' | 'settings' }>(),
  },

  /* Добавление валюты в мои список */
  item_add: { type: StatEventType.Click, payload: ofType<{ name: string; screen: 'search' | 'currency' }>() },

  /* Открытие шаринга в истории */
  sharing_click: { type: StatEventType.Click, payload: ofType<{ name: string }>() },

  /* Успешная публикация истории */
  sharing_success: { type: StatEventType.Click, payload: ofType<{ name: string }>() },

  /* Тап по графу (первое касание) */
  graph_tap: { type: StatEventType.Click, payload: ofType<{ name: string }>() },

  /* Все последующие пермещения пальцем по графику */
  graph_drag: { type: StatEventType.Click, payload: ofType<{ name: string }>() },

  /* переход в ограничение от ответственности */
  go_limitation: { type: StatEventType.Navgo, payload: ofType<{ name?: string }>() },

  /* переход в настройки уведомлений */
  go_notifications: {
    type: StatEventType.View,
    payload: ofType<{ name: string; screen: 'currency' | 'notification_list' | 'my_list' }>(),
  },

  /* запрос настроек на уведомления */
  notifications_permission_request: { type: StatEventType.Click, payload: {} },

  /* принятие */
  notifications_permission_accept: { type: StatEventType.Click, payload: {} },

  /* отказ */
  notifications_permission_declined: { type: StatEventType.Click, payload: {} },

  /* добавление нотификации */
  notifications_added: { type: StatEventType.Click, payload: ofType<{ name: string }>() },

  /* удаление подписки нотификации */
  notifications_removed: { type: StatEventType.Click, payload: ofType<{ name: string }>() },

  /* отправка нотификации (бэк) */
  notifications_send: { type: StatEventType.View, payload: ofType<{ name: string }>() },

  /* отправка нотификации (бэк) */
  notifications_swap: { type: StatEventType.Click, payload: ofType<{ name: string }>() },

  /* показ диалога добавить апп на главный экран */
  add_to_home_screen_shown: { type: StatEventType.View, payload: {} },

  /* согласие на добавлене апп на главный экран */
  add_to_home_screen_accepted: { type: StatEventType.Click, payload: {} },

  /* событие показа тултипа с новой лентой */
  feed_tooltip_shown: { type: StatEventType.View, payload: {} },

  /* переход к ленте через тултип */
  feed_tooltip_go: { type: StatEventType.Click, payload: {} },

  /* скрытие тултипа с новой лентой */
  feed_tooltip_dismiss: { type: StatEventType.Click, payload: {} },

  /* открытия поста */
  feed_post_open: {
    type: StatEventType.Click,
    payload: ofType<{ owner_id: number; post_id: number; index: number }>(),
  },
};

export type CurrencyEvents = typeof currencyEvents;
