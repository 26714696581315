import useAPI from '../../components/tools/APIProvider/useAPI';
import { useActions } from '../../hooks/useActions';
import { currencyActions } from '../../redux/reducers/currency';
import useAsync from '../../hooks/useAsync';

const useCurrencies = () => {
  const api = useAPI();
  const updateCurrencies = useActions(currencyActions.updateCurrencies);

  const [refreshCurrencies, refreshCurrenciesStatus] = useAsync(() => api.getCurrencies().then(updateCurrencies), {
    retry: 'snackbar',
  });

  return { refreshCurrencies, refreshCurrenciesLoading: refreshCurrenciesStatus === 'loading' };
};

export default useCurrencies;
