import React, { memo } from 'react';
import NumberFormat from 'react-number-format';
import { L } from '../../../lang/L';

interface CurrencyConverterRateInputProps {
  value: string;
  onChange(value: string): void;
  onKeyDown?(): void;
  onFocus?(): void;
  isAllowed(value: string): boolean;
  customInput?: React.ComponentType<any>;
  className?: string;
}

const CurrencyConverterRateInput: React.FC<CurrencyConverterRateInputProps> = memo((props) => {
  const { value, className, onChange, onKeyDown, onFocus, isAllowed, customInput } = props;

  return (
    <NumberFormat
      isNumericString
      value={value}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onValueChange={(values): void => {
        values.value !== value && values.value !== '.' && onChange(values.value);
      }}
      inputMode="decimal"
      className={className}
      thousandSeparator={L.t('config:numDelS').toString()}
      decimalSeparator={L.t('config:numDec').toString()}
      customInput={customInput}
      allowNegative={false}
      isAllowed={({ value }): boolean => isAllowed(value)}
      allowLeadingZeros={false}
    />
  );
});

export default CurrencyConverterRateInput;
