import React, { memo, useEffect, useCallback } from 'react';
import { RootRoute } from '../../../router/routes';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { Panel, PullToRefresh, FixedLayout, Div, Separator, Caption } from '@vkontakte/vkui';
import { PanelHeaderButton, Search, PanelHeader } from '@overrided-vkui';
import { L } from '../../../lang/L';
import SelectedCurrenciesList from '../../partial/SelectedCurrenciesList';
import CurrenciesSearchSelectList from '../../partial/CurrenciesSearchSelectList';
import { makeStyles } from '@material-ui/styles';
import useCurrencies from '../../../hooks/currency/useCurrencies';
import { throttle } from 'throttle-debounce';
import { useAppContext } from 'src/components/AppContext';
import { Icon28SettingsOutline, Icon28Notifications, Icon20InfoCircleOutline } from '@vkontakte/icons';

const useStyles = makeStyles({
  list: {
    margin: '4px 0',
  },
  caption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
  },
  captionText: {
    color: 'var(--text_secondary)',
    marginRight: 12,
  },
});

interface CurrenciesPanelMobileProps {
  id: string;
  route: RootRoute;
  onSettingsClick(): void;
  onObserversClick(): void;
  onCurrencyClick(currencyId: string): void;
  onCurrencyObserversClick(currencyId: string): void;
  onLegalClick(): void;
}

const CurrenciesPanelMobile: React.FC<CurrenciesPanelMobileProps> = memo((props) => {
  const {
    id: panelId,
    route,
    onSettingsClick,
    onLegalClick,
    onCurrencyClick,
    onObserversClick,
    onCurrencyObserversClick,
  } = props;

  const { statEvents, launchParams } = useAppContext();

  const throttledSendStatEvents = useCallback(throttle(500, statEvents.push), []);
  const isOKClient = launchParams.client === 'ok';

  useEffect(() => {
    statEvents.push('navigation_go', { screen: 'my_list' });
  }, []); // eslint-disable-line

  /* Обновление данных */

  const { refreshCurrencies, refreshCurrenciesLoading } = useCurrencies();

  useEffect(() => {
    refreshCurrencies().catch(() => null);
  }, []); // eslint-disable-line

  /* Роутинг поиска */

  const [searchValue, setSearchValue] = useSearchQuery(route);
  const isSearchMode = searchValue !== '';

  /* Рендер */

  const mc = useStyles();

  return (
    <Panel id={panelId}>
      <PanelHeader
        separator={false}
        left={
          <>
            <PanelHeaderButton onClick={onSettingsClick}>
              <Icon28SettingsOutline />
            </PanelHeaderButton>
            {!isOKClient && (
              <PanelHeaderButton onClick={onObserversClick}>
                <Icon28Notifications />
              </PanelHeaderButton>
            )}
          </>
        }
      >
        {L.t('app_name')}
      </PanelHeader>
      <FixedLayout vertical="top">
        <Search
          onChange={(e): void => {
            window.scrollTo(0, 0);
            setSearchValue(e.target.value);
            throttledSendStatEvents('search', { screen: 'my_list', text: e.target.value });
          }}
          value={searchValue}
          onFocus={() => statEvents.push('search_click', { screen: 'my_list' })}
          placeholder={L.t('common:search_placeholder')}
          after={L.t('common:cancel')}
        />
      </FixedLayout>
      <div style={{ height: 52 }} />
      <PullToRefresh onRefresh={() => refreshCurrencies().catch(() => null)} isFetching={refreshCurrenciesLoading}>
        {isSearchMode ? (
          <CurrenciesSearchSelectList
            className={mc.list}
            onCurrencyClick={onCurrencyClick}
            search={searchValue}
            skipBaseCurrency
          />
        ) : (
          <SelectedCurrenciesList
            className={mc.list}
            onCurrencyClick={onCurrencyClick}
            onFindCurrenciesClick={() => setSearchValue(' ')}
            openCurrencyObserversModal={isOKClient ? void 0 : onCurrencyObserversClick}
          />
        )}
      </PullToRefresh>
      <div style={{ height: 44 }} />
      <FixedLayout filled vertical="bottom">
        <Separator wide />
        <Div className={mc.caption}>
          <Caption className={mc.captionText} weight="regular" level="2">
            {L.t('main_legal_caption')}
          </Caption>
          <PanelHeaderButton onClick={onLegalClick}>
            <Icon20InfoCircleOutline fill="var(--icon_secondary)" />
          </PanelHeaderButton>
        </Div>
      </FixedLayout>
    </Panel>
  );
});

export default CurrenciesPanelMobile;
