/**
 * Список криптовалют.
 */
const cryptoCurrencies = [
  'BTC', 'BTS', 'DASH', 'DOGE', 'EAC', 'EMC', 'ETH', 'FCT', 'FTC', 'LD',
  'LTC', 'NMC', 'NVC', 'NXT', 'PPC', 'STR', 'VTC', 'XMR', 'XPM',
  'XRP', 'VEF_BLKMKT', 'VEF_DICOM', 'VEF_DIPRO',
] as const;

type TCryptoCurrency = (typeof cryptoCurrencies) extends readonly (infer C)[]
  ? C : never;

/**
 * Возвращает true в случае, если переданное значение является аббревиатурой
 * криптовалюты.
 * @param value
 */
export function isCryptoCurrency(value: string): value is TCryptoCurrency {
  return cryptoCurrencies.includes(value.toUpperCase() as TCryptoCurrency);
}
