import React, { memo, useRef, useEffect } from 'react';
import fitty from 'fitty';

interface AdaptiveFontSizeProps extends React.HTMLAttributes<HTMLDivElement> {
  maxSize: number;
  minSize: number;
  lineHeight?: string | number;
  className?: string;
}

const AdaptiveFontSize: React.FC<AdaptiveFontSizeProps> = memo((props) => {
  const { maxSize, minSize, children, lineHeight, ...restProps } = props;

  const wrapperEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!wrapperEl.current) return;

    const fittyInstance = fitty(wrapperEl.current, { maxSize, minSize, multiLine: false });

    return (): void => fittyInstance.unsubscribe();
  }, [maxSize, minSize]);

  return (
    <div {...restProps}>
      <div ref={wrapperEl} style={{ lineHeight }}>
        {children}
      </div>
    </div>
  );
});

export default AdaptiveFontSize;
