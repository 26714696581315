import ContainerDesktop from './ContainerDesktop';
import ContainerMobile from './ContainerMobile';
import { getPlatformComponent } from '@overrided-vkui';

export interface ContainerProps {
  openCurrencyInfoModal(currencyId: string): void;
  openCurrencyObserversModal(currencyId: string): void;
  openSelectBaseCurrencyModal(): void;
  openLegalModal(): void;
  openObserversModal(): void;
  openSettingsModal(): void;
}

export default getPlatformComponent<ContainerProps>({ mobile: ContainerMobile, desktop: ContainerDesktop });
