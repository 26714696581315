import React, { memo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';
import { Placeholder, PanelHeaderButton } from '@vkontakte/vkui';
import { Button } from '@overrided-vkui';
import { ReactComponent as ArticlePromoLogo } from '../../../assets/article_promo_logo.svg';
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss';
import { L } from '../../../lang/L';

const APPEARANCE_DELAY = 400;
const LAYER_OPACITY_FADE_DURATION = 320;

const useStyles = makeStyles({
  target: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 0,
    height: 0,
  },
  '@keyframes layerOpacityFadeIn': {
    from: { opacity: 0 },
    to: { opacity: 0.4 },
  },
  '@keyframes layerOpacityFadeOut': {
    from: { opacity: 0.4 },
    to: { opacity: 0 },
  },
  '@keyframes layerMaskFadeIn': {
    from: { transform: 'translate(50%,50%) scale(0)' },
    to: { transform: 'translate(50%,50%) scale(1)' },
  },
  '@keyframes cardFadeOut': {
    from: { transform: 'translateY(0)', opacity: 1 },
    to: { transform: 'translateY(50px)', opacity: 0 },
  },
  '@keyframes cardFadeIn': {
    from: { transform: 'translateY(50px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
  },
  target_closing: {
    '& $layer': {
      animation: `$layerOpacityFadeOut ${LAYER_OPACITY_FADE_DURATION}ms ease forwards`,
    },
    '& $card': {
      animation: `$cardFadeOut ${LAYER_OPACITY_FADE_DURATION}ms var(--ios-easing) forwards`,
    },
  },
  layer: {
    animation: `$layerOpacityFadeIn ${LAYER_OPACITY_FADE_DURATION}ms ease ${APPEARANCE_DELAY}ms forwards`,
    transform: 'translate(-50%,-50%)',
    opacity: 0,
    willChange: 'transform,opacity',
    position: 'fixed',
    zIndex: 9999,
    height: '200vh',
    width: '200vw',
  },
  layer__mask: {
    transform: 'translate(50%,50%) scale(0)',
    animation: `$layerMaskFadeIn ${LAYER_OPACITY_FADE_DURATION}ms ease ${
      LAYER_OPACITY_FADE_DURATION + APPEARANCE_DELAY
    }ms  forwards`,
  },
  layerTarget: {
    borderRadius: '50%',
    position: 'fixed',
    zIndex: 9999,
    overflow: 'hidden',
    opacity: 0,
  },
  overlay: {
    position: 'fixed',
    height: '100vh',
    marginTop: '-100vh',
    padding: 8,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    left: 0,
    right: 0,
    zIndex: 9999,
    pointerEvents: 'none',
  },
  card: {
    display: 'block',
    width: '100%',
    maxWidth: 480,
    background: 'var(--modal_card_background)',
    opacity: 0,
    animation: `$cardFadeIn ${LAYER_OPACITY_FADE_DURATION}ms var(--ios-easing) ${
      APPEARANCE_DELAY + LAYER_OPACITY_FADE_DURATION / 2
    }ms forwards`,
    borderRadius: 18,
    padding: 32,
    boxSizing: 'border-box',
    position: 'relative',
    pointerEvents: 'all',
  },
  card__placeholder: {
    background: 'none',
    '& .Placeholder__in': {
      padding: 0,
    },
  },
  card__logo: {
    height: 56,
    width: 56,
    display: 'block',
  },
  card__dismiss: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 8,
  },
});

interface FeedHintMobileProps {
  onOpen(): void;
  onDismiss(): void;
  size: number;
  offsetX?: number;
  offsetY?: number;
}

const FeedHintMobile = memo((props: FeedHintMobileProps) => {
  const { onOpen, onDismiss, size, offsetY = 0, offsetX = 0 } = props;

  const [closing, setClosing] = useState(false);

  const dismissHint = () => {
    setClosing(true);
    setTimeout(onDismiss, LAYER_OPACITY_FADE_DURATION);
  };

  const openHint = () => {
    setClosing(true);
    setTimeout(onOpen, LAYER_OPACITY_FADE_DURATION);
  };

  useEffect(() => {
    const initialOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = initialOverflow;
    };
  }, []);

  const mc = useStyles();

  return (
    <div className={c(mc.target, { [mc.target_closing]: closing })} onClick={(e) => e.stopPropagation()}>
      <svg className={mc.layer} style={{ marginTop: offsetY, marginLeft: offsetX }} onClick={dismissHint}>
        <defs>
          <mask id="target-rect">
            <rect
              width={size + 2}
              height={size + 2}
              x={-size / 2 - 1}
              y={-size / 2 - 1}
              style={{ transform: 'translate(50%,50%)' }}
              fill="#fff"
            />
            <circle className={mc.layer__mask} r={size / 2} fill="#000" />
          </mask>
          <mask id="target-hole">
            <rect width="100%" height="100%" fill="#fff" />
            <rect
              width={size}
              height={size}
              x={-size / 2}
              y={-size / 2}
              style={{ transform: 'translate(50%,50%)' }}
              fill="#000"
            />
          </mask>
        </defs>
        <rect width="100%" height="100%" fill="#000" mask="url(#target-rect)" />
        <rect width="100%" height="100%" fill="#000" mask="url(#target-hole)" />
      </svg>
      <div
        className={mc.layerTarget}
        onClick={openHint}
        style={{ height: size, width: size, transform: `translate(${-size / 2 + offsetX}px,${-size / 2 + offsetY}px)` }}
      />
      <div className={mc.overlay} style={{ transform: `translateY(${-size / 2 + offsetY}px)` }}>
        <div className={mc.card}>
          <PanelHeaderButton className={mc.card__dismiss} onClick={dismissHint}>
            <Icon24Dismiss fill="var(--placeholder_icon_foreground_primary)" />
          </PanelHeaderButton>
          <Placeholder
            icon={<ArticlePromoLogo className={mc.card__logo} />}
            header={L.t('feed_tooltip_header')}
            className={mc.card__placeholder}
            action={
              <Button onClick={openHint} size="l">
                {L.t('feed_tooltip_nav_open')}
              </Button>
            }
          >
            {L.t('feed_tooltip_text')}
          </Placeholder>
        </div>
      </div>
    </div>
  );
});

export default FeedHintMobile;
