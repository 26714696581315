import styled from '@material-ui/styles/styled';
import Input, { InputProps } from '@vkontakte/vkui/dist/components/Input/Input';

export default styled(Input)({
  '& .FormField__border': {
    borderRadius: '6px',
    '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)': {
      borderRadius: '12px',
    },
    '@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx)': {
      borderRadius: '18px',
    },
  },
  '& .Input__el:focus ~ .FormField__border': {
    borderRadius: '6px!important',
  },
  '& .Input__el': {
    height: 38,
    lineHeight: '20px',
    fontSize: 15,
    padding: '10px 12px',
    borderRadius: '6px',
  },
}) as React.FC<InputProps>;
