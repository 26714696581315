import { useEffect, memo, useCallback } from 'react';

import { useRouter } from 'react-router5';
import { RootRoute } from '../../../router/routes';
import { Modal } from '../../App';
import { useSelector } from '../../../hooks/useSelector';
import { useAppContext } from 'src/components/AppContext';
import qs from 'querystring';
import vkBridge, { VKBridgeSubscribeHandler } from '@vkontakte/vk-bridge';

interface AppExternalRoutesProps {
  initialHash: string;
}

/**
 * Делает перенаправление к внутренним роутингам приложения по начальным URL-параметрам iframe-приложения.
 * Позволяет формировать желаемые внешние ссылки независимо от внутреннего роутинга.
 */
const AppExternalRoutes = memo<AppExternalRoutesProps>((props) => {
  const { initialHash } = props;
  const router = useRouter();
  const { statEvents, launchParams: { ref }, } = useAppContext();
  const itemsCount = useSelector((state) => state.storage['selected-currency-ids']?.length || 0);

  /**
   * Исходя из хэша определяет, необходимо ли совершить переход на другой
   * маршрут.
   */
  const checkRedirects = useCallback((
    hash: string,
    redirectOnEmpty = false
  ) => {
    const routeQueries = qs.parse(hash);

    // modal=terms - ссылка на модалку с ограничением ответственности.
    if (routeQueries.modal === Modal.Terms) {
      statEvents.push('go_limitation', {});
      return router.navigate(RootRoute.CURRENCY_MAIN, { modal: Modal.Terms });
    }
    // currency=USD - ссылка на модалку к конкретной валюте
    const currencyId = routeQueries.currency;

    if (typeof currencyId === 'string') {
      router.navigate(RootRoute.CURRENCY_MAIN, {
        modal: Modal.CurrencyInfo,
        currency: currencyId
      });
    }
    if (Object.keys(routeQueries).length === 0 && redirectOnEmpty) {
      router.navigate(RootRoute.CURRENCY_MAIN);
    }
  }, [router, statEvents]);

  /**
   * Отслеживает события bridge и ожидает смены фрагмента для того, чтобы
   * обновить текущее состояние роутинга.
   */
  const onBridgeEvent = useCallback<VKBridgeSubscribeHandler>(event => {
    if (event.detail.type === 'VKWebAppChangeFragment') {
      checkRedirects(event.detail.data.location, true);
    }
  }, [checkRedirects]);

  useEffect(() => {
    checkRedirects(initialHash);

    const listener = (e: HashChangeEvent) => {
      checkRedirects(e.newURL.slice(e.newURL.indexOf('#') + 1));
    };

    window.addEventListener('hashchange', listener);
    return () => window.removeEventListener('hashchange', listener);
    // eslint-disable-next-line
  }, [checkRedirects]);

  useEffect(() => {
    const routeQueries = qs.parse(initialHash);
    const isObserverResolved = routeQueries.notification_type === 'observer_resolved';

    statEvents.send('launch', {
      ref,
      items_count: itemsCount,
      notification_type: isObserverResolved ? 'observer_resolved' : void 0
    });
    // eslint-disable-next-line
  }, []);

  // Подписываемся на событие изменения фрагмента.
  useEffect(() => {
    vkBridge.subscribe(onBridgeEvent);

    return () => vkBridge.unsubscribe(onBridgeEvent);
  }, [onBridgeEvent]);

  return null;
});

export default AppExternalRoutes;
