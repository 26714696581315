import React, { memo } from 'react';
import RateDelta from '../../atomic/RateDelta';
import CurrencyLabel from '../../atomic/CurrencyLabel';
import CurrencyBadge from '../../atomic/CurrencyBadge';
import { makeStyles } from '@material-ui/styles';
import { SimpleCell, Group, isDesktop, PanelHeaderButton, HoverTultip, getPlatformClassName } from '@overrided-vkui';
import { BaseRelatedCurrency } from '../../../types/currency';
import { formatRate } from '../../../utils/formatter';
import MiniRateChart from '../../atomic/MiniRateChart';
import AdaptiveFontSize from '../../atomic/AdaptiveFontSize';
import Icon24NotificationOutline from '@vkontakte/icons/dist/24/notification_outline';
import Icon28Notifications from '@vkontakte/icons/dist/28/notifications';
import Icon24NotificationCheckOutline from '@vkontakte/icons/dist/24/notification_check_outline';
import { ReactComponent as Icon28NotificationCheck } from '../../../assets/notification_check_outline_28.svg';
import c from 'classnames';
import { L } from '../../../lang/L';

const useStyles = makeStyles({
  item: {
    '& .Group__separator, &.Group>:not(.Header):first-child': {
      marginTop: 10,
    },
    '& .SimpleCell__main': {
      padding: '28px 0',
    },
    '&--desktop': {
      '& .SimpleCell__main': {
        padding: '20px 0',
      },
      '& $item__dynamic': {
        fontSize: 14,
        lineHeight: '18px',
      },
      '& $item__rate': {
        fontWeight: 300,
        marginTop: 5,
        marginRight: 20,
        lineHeight: 'initial',
        fontSize: 'initial',
      },
      '& $item__title': {
        marginLeft: 0,
      },
      '& $observersIcon': {
        '--accent': 'var(--blue_400)',
        '--icon_secondary': 'var(--steel_gray_200)',
      },
    },
    '&--mobile': {
      '& .SimpleCell__after': {
        alignSelf: 'flex-start',
        paddingTop: 16,
      },
    },
  },
  item__title: {
    '& > *:last-child': {
      fontSize: 32,
    },
  },
  item__rate: {
    fontSize: 'min(10vw, 56px)',
    lineHeight: 1.5,
    display: 'block!important',
  },
  item__dynamic: {},
  observersIcon: {
    display: 'block',
  },
  observersIcon_checked: {
    color: 'var(--icon_secondary)!important',
  },
  item__after: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
});

interface CurrencySelectedItemLargeProps {
  onClick(): void;
  currency: BaseRelatedCurrency;
  hasObservers: boolean;
  openCurrencyObserversModal?(): void;
}

const CurrencySelectedItemLarge: React.FC<CurrencySelectedItemLargeProps> = memo((props) => {
  const { onClick, currency, hasObservers, openCurrencyObserversModal } = props;

  const mc = useStyles();

  const Currency = isDesktop ? CurrencyLabel : CurrencyBadge;

  const IconHasNotObservers = isDesktop ? Icon24NotificationOutline : Icon28Notifications;
  const IconHasObserversIcon = isDesktop ? Icon24NotificationCheckOutline : Icon28NotificationCheck;

  return (
    <Group className={getPlatformClassName(mc.item)}>
      <SimpleCell
        onClick={onClick}
        after={
          <div className={mc.item__after}>
            {openCurrencyObserversModal && (
              <HoverTultip
                title={L.t(hasObservers ? 'observer_icon_subscribe_done_tooltip' : 'observer_icon_subscribe_tooltip')}
              >
                <PanelHeaderButton
                  onClick={(e) => {
                    e.stopPropagation();
                    openCurrencyObserversModal();
                  }}
                >
                  {hasObservers ? (
                    <IconHasObserversIcon
                      className={c('Icon', 'Icon--28', mc.observersIcon, mc.observersIcon_checked)}
                    />
                  ) : (
                    <IconHasNotObservers className={c('Icon', 'Icon--28', mc.observersIcon)} />
                  )}
                </PanelHeaderButton>
              </HoverTultip>
            )}
            {isDesktop && <MiniRateChart height={80} width={200} points={currency.series?.rates || []} />}
          </div>
        }
      >
        <Currency id={currency.id} title={currency.title} className={mc.item__title} />
        {isDesktop ? (
          <AdaptiveFontSize className={mc.item__rate} minSize={16} maxSize={42} lineHeight={1.5}>
            {formatRate(currency.rate, currency.base.symbol)}
          </AdaptiveFontSize>
        ) : (
          <div className={mc.item__rate}>{formatRate(currency.rate, currency.base.symbol)}</div>
        )}
        <RateDelta
          className={mc.item__dynamic}
          prevRate={currency.prevRate}
          rate={currency.rate}
          symbol={currency.base.symbol}
        />
      </SimpleCell>
    </Group>
  );
});

export default CurrencySelectedItemLarge;
