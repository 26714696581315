import PanelHeader, { PanelHeaderProps } from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import styled from '@material-ui/styles/styled';

export default styled(PanelHeader)({
  'body:not([scheme=space_gray]) &': {
    '--header_tint': 'var(--steel_gray_400)',
  },
  '& .PanelHeader__left, & .PanelHeader__right': {
    height: '100%',
    padding: 0,
  },
  '& .PanelHeader__left': {
    paddingLeft: 12,
  },
  '& .PanelHeader__right': {
    paddingRight: 12,
  },
  '& .PanelHeaderButton': {
    fontSize: 15,
    cursor: 'pointer',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
    
  },
  '& .PanelHeader__content': {
    fontFamily: 'var(--font-common)',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 500,
  },
  '& .PanelHeader__content>*': {
    padding: '0 8px',
  },
  '&.PanelHeader--no-left .PanelHeader__content': {
    paddingLeft: 12,
  },
  '&.PanelHeader--no-right .PanelHeader__content': {
    paddingRight: 12,
  },
  '& .Separator': {
    padding: 0,
    zIndex: 10,
    position: 'fixed',
    top: 48,
    left: 0,
    right: 0,
  },
  '& .Separator__in': {
    marginLeft: '0!important',
    marginRight: '0!important',
    transform: 'none!important',
    backgroundColor: 'var(--steel_gray_80)',
    height: 1,
  },
}) as React.FC<PanelHeaderProps>;
