import React, { memo } from 'react';
import { Card, Caption, Avatar, Tappable } from '@vkontakte/vkui';
import { Subhead, Text, Separator, isDesktop, getPlatformClassName } from '@overrided-vkui';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';
import MiniPostAttachment from '../MiniPostAttachment';
import MiniPostText from '../MiniPostText';
import { MiniPost as MiniPostType } from '../../../types/miniPost';
import { fomatPostTime } from '../../../utils/formatter';

const useStyles = makeStyles({
  post: {
    '&--mobile': {
      borderRadius: '14px!important',
    },
    '&--desktop': {
      boxShadow: 'none',
      border: '1px solid var(--steel_gray_80)',
    },
    '& .Card__in': {
      overflow: 'hidden',
    },
    cursor: 'pointer',
  },
  post__container: {
    borderRadius: 14,
    overflow: 'hidden',
  },
  post__item: {
    margin: '12px 16px',
    '&:first-child': {
      marginTop: 16,
    },
    '&:last-child': {
      marginBottom: 16,
    },
  },
  postHeader: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: 8,
    },
  },
  postHeader__avatar: {},
  postHeader__title: {
    color: 'var(--text_primary)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  postHeader__datetime: {
    color: 'var(--text_secondary)',
    flexShrink: 0,
  },

  postLink: {},
  postLink__title: {
    color: 'var(--text_primary)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  postLink__url: {
    color: 'var(--text_secondary)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  postAttachment: {
    marginBottom: -1,
    '&--mobile': {
      marginLeft: 0,
      marginRight: 0,
    },
    '&--desktop': {
      borderRadius: 6,
      overflow: 'hidden',
    },
    '& + $linkSeparator': {
      display: 'none',
    },
  },
  linkSeparator: {
    margin: '0 16px',
    marginBottom: -2,
  },
});

interface MiniPostProps {
  post: MiniPostType;
  onClick(): void;
}

const MiniPost = memo((props: MiniPostProps) => {
  const { post, onClick } = props;
  const { text, date, source, link, attachment } = post;
  const mc = useStyles();

  if (!post.attachment && !post.link && !post.text) {
    return null;
  }

  const Container = isDesktop ? 'div' : Tappable;

  return (
    <Card mode="shadow" className={getPlatformClassName(mc.post)}>
      <Container className={mc.post__container} onClick={onClick}>
        <div className={c(mc.post__item, mc.postHeader)}>
          <Avatar size={24} shadow={false} src={source.photoUrl} className={mc.postHeader__avatar} />
          <Subhead weight="semibold" className={mc.postHeader__title}>
            {source.name}
          </Subhead>
          <Caption weight="regular" level="1" className={mc.postHeader__datetime}>
            {fomatPostTime(date * 1000)}
          </Caption>
        </div>
        {Boolean(text) && (
          <div className={mc.post__item}>
            <MiniPostText text={text} />
          </div>
        )}
        {attachment && (
          <div className={c(getPlatformClassName(mc.postAttachment), { [mc.post__item]: isDesktop })}>
            <MiniPostAttachment {...attachment} />
          </div>
        )}
        {!attachment && link && link.image && (
          <div className={c(getPlatformClassName(mc.postAttachment), { [mc.post__item]: isDesktop })}>
            <MiniPostAttachment type="photo" image={link.image} />
          </div>
        )}
        {link && (
          <>
            <Separator wide className={mc.linkSeparator} />
            <div className={c(mc.post__item, mc.postLink)}>
              <Text weight="semibold" className={mc.postLink__title}>
                {link.title}
              </Text>
              <Caption weight="regular" level="1" className={mc.postLink__url}>
                {link.url}
              </Caption>
            </div>
          </>
        )}
      </Container>
    </Card>
  );
});

export default MiniPost;
