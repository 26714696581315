import {
  BaseLink,
  NewsfeedGetResponse,
  NewsfeedItemWallpost,
  NewsfeedNewsfeedItem,
  NewsfeedNewsfeedItemType,
  PhotosPhotoSizesType,
  WallWallpostAttachment,
  WallWallpostAttachmentType,
} from "@vkontakte-internal/api-schema-typescript";

const DEFAULT_PHOTO = 'https://vk.com/images/camera_200.png'

export interface MiniPostAttachmentTypePhoto {
  type: 'photo';
  image: {
    height: number;
    width: number;
    url: string;
  };
}

function isMiniPostAttachmentTypePhoto(data: any): data is MiniPostAttachmentTypePhoto {
  return Boolean(
    data &&
      typeof data === 'object' &&
      data.type === 'photo' &&
      data.image &&
      typeof data.image === 'object' &&
      typeof data.image.height === 'number' &&
      typeof data.image.width === 'number' &&
      typeof data.image.url === 'string'
  );
}

export interface MiniPostAttachmentTypeVideo {
  type: 'video';
  duration: number;
  image: {
    height: number;
    width: number;
    url: string;
  };
}

function isMiniPostAttachmentTypeVideo(data: any): data is MiniPostAttachmentTypeVideo {
  return Boolean(
    data &&
      typeof data === 'object' &&
      data.type === 'video' &&
      typeof data.duration === 'number' &&
      data.image &&
      typeof data.image === 'object' &&
      typeof data.image.height === 'number' &&
      typeof data.image.width === 'number' &&
      typeof data.image.url === 'string'
  );
}

export interface MiniPostAttachmentTypeArticle {
  type: 'article';
  title: string;
  sourceName: string;
  image: {
    height: number;
    width: number;
    url: string;
  };
}

function isMiniPostAttachmentTypeArticle(data: any): data is MiniPostAttachmentTypeArticle {
  return Boolean(
    data &&
      typeof data === 'object' &&
      data.type === 'article' &&
      typeof data.title === 'string' &&
      typeof data.sourceName === 'string' &&
      data.image &&
      typeof data.image === 'object' &&
      typeof data.image.height === 'number' &&
      typeof data.image.width === 'number' &&
      typeof data.image.url === 'string'
  );
}

export type MiniPostAttachment =
  | MiniPostAttachmentTypePhoto
  | MiniPostAttachmentTypeVideo
  | MiniPostAttachmentTypeArticle
  | { type: string };

function isMiniPostAttachment(data: any): data is MiniPostAttachment {
  return (
    isMiniPostAttachmentTypePhoto(data) ||
    isMiniPostAttachmentTypeVideo(data) ||
    isMiniPostAttachmentTypeArticle(data) ||
    (data && typeof data === 'object' && typeof data.type === 'string')
  );
}

export interface MiniPostLink {
  title: string;
  url: string;
  image?: {
    height: number;
    width: number;
    url: string;
  };
}

function isMiniPostLink(data: any): data is MiniPostLink {
  return Boolean(
    data &&
      typeof data === 'object' &&
      typeof data.title === 'string' &&
      typeof data.url === 'string' &&
      (typeof data.image === 'undefined' ||
        (data.image &&
          typeof data.image === 'object' &&
          typeof data.image.height === 'number' &&
          typeof data.image.width === 'number' &&
          typeof data.image.url === 'string'))
  );
}

export interface MiniPostSource {
  id: number;
  name: string;
  photoUrl: string;
}

export interface MiniPost {
  id: number;
  ownerId: number;
  text: string;
  date: number;
  source: MiniPostSource;
  attachment?: MiniPostAttachment;
  link?: MiniPostLink;
}

export function isMiniPost(data: any): data is MiniPost {
  return Boolean(
    data &&
      typeof data === 'object' &&
      typeof data.id === 'number' &&
      typeof data.ownerId === 'number' &&
      typeof data.text === 'string' &&
      typeof data.date === 'number' &&
      (typeof data.link === 'undefined' || isMiniPostLink(data.link)) &&
      (typeof data.attachment === 'undefined' || isMiniPostAttachment(data.attachment))
  );
}

function buildIndex<T,X extends {id: number}>(array: T[]|undefined, mapItem: (x: T) => X): {[key: string]: X} {
  if (!array) {
    return {}
  }
  const index: {[key: string]: X} = {}
  array.forEach( item => {
    const entity = mapItem(item)
    index[entity.id.toString()] = entity
  } )
  return index
}

function isFeedItemWallPost(item: NewsfeedNewsfeedItem): item is NewsfeedItemWallpost {
  return item.type === NewsfeedNewsfeedItemType.POST
}

function isLinkLookLikeArticle(link: BaseLink | undefined): boolean {
  return Boolean(
    link
    && link.photo
    && link.button
    && link.button.action?.url
    && link.button.action?.url.indexOf('vk.com/@') !== -1
  )
}

function extractLinkFromAttachments(attachments: WallWallpostAttachment[] | undefined): BaseLink | undefined {
  return attachments?.find(attach => !!attach.link)?.link;
}

function convertBaseLinkToMiniPostLink(link: BaseLink | undefined): MiniPostLink|undefined {
  if (!link) return undefined;
  const imageLSize = link.photo?.sizes?.find(size => size.type === PhotosPhotoSizesType.L);
  return {
    image: imageLSize ? {
      url: imageLSize.url,
      width: imageLSize.width,
      height: imageLSize.height,
    } : undefined,
    title: link.title || link.url,
    url: link.url,
  }
}

function convertWallAttachToPostAttach(attachments: WallWallpostAttachment[] | undefined): MiniPostAttachment[] {
  return attachments?.reduce( (acc, attachment) => {
    const photoSizeL = attachment.photo?.sizes?.find(size => size.type === PhotosPhotoSizesType.L)
    const videoImage800 = attachment.video?.image?.find( img => img.width === 800 && img.with_padding === 1 )
    if (attachment.type === WallWallpostAttachmentType.PHOTO && photoSizeL) {
      acc.push({
        type: 'photo',
        image: {
          width: photoSizeL.width,
          height: photoSizeL.height,
          url: photoSizeL.url,
        }
      })
    }

    if (attachment.video && videoImage800) {
      acc.push({
        type: 'video',
        duration:attachment.video.duration,
        image: {
          width: videoImage800.width,
          height: videoImage800.height,
          url: videoImage800.url,
        }
      })
    }

    // Почему-то не существует типа attach ¯\_(ツ)_/¯
    // attachment.attach

    return acc
  }, [] as MiniPostAttachment[] ) || []
}

export function convertNewsFeedToMiniPost(response: NewsfeedGetResponse): MiniPost[] {
  const res: MiniPost[] = [];
  if (!response.items) return res;

  const groupIndex: { [key: string]: MiniPostSource } = buildIndex(response.groups, group => ({
    id: -group.id,
    name: group.name,
    photoUrl: (group.photo_200 || group.photo_100 || group.photo_50) || DEFAULT_PHOTO,
  }))

  const profileIndex: { [key: string]: MiniPostSource } = buildIndex(response.profiles, profile => ({
    id: profile.id,
    name: `${profile.first_name} ${profile.last_name}`,
    photoUrl: (profile.photo_200 || profile.photo_100 || profile.photo_50) || DEFAULT_PHOTO,
  }))

  response.items.forEach(feedItem => {
    if (!isFeedItemWallPost(feedItem)) return null
    const source = groupIndex[feedItem.source_id] || profileIndex[feedItem.source_id]
    if (!source) return null
    const post: MiniPost = {
      id: feedItem.post_id || 0,
      ownerId: source.id,
      text: feedItem.text || "",
      date: feedItem.date,
      source: source,
    }

    const baseLink = extractLinkFromAttachments(feedItem.attachments)
    const attaches = convertWallAttachToPostAttach(feedItem.attachments)

    const photo = attaches.find(attach => attach.type === 'photo')
    if (baseLink && isLinkLookLikeArticle(baseLink) && isMiniPostAttachmentTypePhoto(photo)) {
      post.attachment = {
        type: 'article',
        title: baseLink.url,
        sourceName: source.name,
        image: photo.image,
      }
    } else {
      post.link = convertBaseLinkToMiniPostLink(baseLink)
      if (attaches[0]) {
        post.attachment = attaches[0]
      }
    }

    // Может быть такое что пост оказался пустым
    // Пустые посты нельзя пихать в ленту
    if (post.id && (post.text || post.attachment || post.link)) {
      res.push(post);
    }
  })

  return res;
}
