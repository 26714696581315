import React, { PureComponent, ReactNode } from 'react';

import AppLoadingView from '../views/AppLoadingView';
import AppCrashView from '../views/AppCrashView';
import { RouterProvider } from 'react-router5';
import ServerWorksCrashView from '../views/ServerWorksCrashView';

import { App } from '../App';
import { GlobalStyleSheet } from '../tools/GlobalStyleSheet';
import { Provider as StoreProvider } from 'react-redux';
import { ServicePanel } from '../tools/ServicePanel';

import createReduxStore from '../../redux';
import vkBridge, { VKBridgeSubscribeHandler } from '@vkontakte/vk-bridge';
import { getStorage, setStorageValue } from '../../utils/storage';
import { createApolloClient } from './utils';
import { appConfigActions } from '../../redux/reducers/app-config';

import { Store } from 'redux';
import { Config } from '../../config';
import { ReduxState } from '../../redux/types';
import { StorageField, LaunchParams, StorageValueType } from '../../types';
import { ApolloClient } from '@apollo/client';
import AddToHomeScreenSuggest from '../tools/AddToHomeScreenSuggest';
import { currencyActions } from '../../redux/reducers/currency';
import { storageActions } from '../../redux/reducers/storage';
import {
  RegisterDocument,
  RegisterMutation,
  RegisterMutationVariables,
  GetObserversQuery,
  GetObserversDocument
} from '../../gql/generated/types';
import { getLaunchParams } from '../../utils/launch-params';
import {
  setUserWidgetCurrencies,
  getCurrencyDictionaries
} from '../../utils/exchangeRatesAPI';
import {
  getDefaultSuggested,
  getObserverSuggestedCurrencies
} from '../../utils/getObserverSuggestedCurrencies';
import * as Sentry from '@sentry/browser';
import baseCurrencyIdDetector from '../../utils/baseCurrencyIdDetector';
import { initL } from '../../lang/L';
import { userActions } from '../../redux/reducers/user';
import ConfigProvider from '../tools/ConfigProvider';
import { OverlayProvider } from '@overrided-vkui';
import { createRouter } from '../../router';
import { Router } from 'router5';
import AppExternalRoutes from '../tools/AppExternalRoutes/AppExternalRoutes';
import { createStatEventsInstance } from '../../utils/lib/statEvents';
import { StatEventsInstance } from '../../utils/lib/statEvents/types';
import { currencyEvents, CurrencyEvents } from '../../types/statEvents';
import { CurrencySeries } from '../../types/currency';
import AppContextProvider from '../AppContext/AppContextProvider';
import { createVkClientInstance } from '../../utils/lib/vkClient';
import { VkClientInstance } from '../../utils/lib/vkClient/types';
import { getDefaultToggles, loadAppToggles } from '../../utils/loadAppToggles';
import { Taptic } from 'src/utils/taptic';
import { isIOSTapticSupported } from '../../utils/supports';
import { logTrace } from '../../utils/logging';
import { APIProvider } from '../tools/APIProvider';
import { API } from '../../utils/lib/API';

interface State {
  loading: boolean;
  error: string | null;
}

interface Props {
  /**
   * Environments-based config
   */
  config: Config;
  /**
   * Ссылка инициализации приложения
   */
  url: string;
  /**
   * Application raw launch parameters
   */
  launchParams: string;
  /**
   * Initial route hash
   */
  initialHash: string;
}

/**
 * Root application component. Everything application requires for showing
 * first screen is being loaded here.
 */
export class AppRoot extends PureComponent<Props, State> {
  private readonly apolloClient: ApolloClient<any>;
  private readonly api: API;
  private readonly vkClient: VkClientInstance;
  private readonly statEventsInstance: StatEventsInstance<CurrencyEvents> = {
    destroy: () => null,
    send: () => null,
    push: () => null
  };
  private readonly launchParams: LaunchParams;
  private readonly router: Router<Record<string, any>>;
  public state: Readonly<State> = { loading: true, error: null };
  private store: Store<ReduxState>;

  public constructor(props: Readonly<Props>) {
    super(props);
    const { launchParams, config } = props;

    this.launchParams = getLaunchParams(launchParams);
    this.store = createReduxStore();
    this.apolloClient = createApolloClient({
      httpURI: config.gqlHttpUrl,
      launchParams,
      useOldAuthHeader: this.launchParams.appId === 7368392
    });

    // Инициализируем сущность отправки запросов VK API
    this.vkClient = createVkClientInstance({
      appId: this.launchParams.appId,
      v: this.props.config.vkApiVersion,
      scope: ['wall', 'friends']
    });

    // Создает инстанс API.
    this.api = new API({
      apolloClient: this.apolloClient,
      vkClient: this.vkClient,
      isOkClient: this.launchParams.client === 'ok',
      isCryptoCurrencyIncluded: !this.launchParams.restrictions.includes('btc')
    });

    // Инициализируем сущность отправки статистики
    if (process.env.NODE_ENV === 'production') {
      this.statEventsInstance = createStatEventsInstance(currencyEvents, {
        appId: this.launchParams.appId,
        url: this.props.url,
        userId: this.launchParams.userId,
        platform: this.launchParams.platform,
        callVkMethod: this.vkClient.call
      });
    }

    // Роутер
    this.router = createRouter();
    this.router.start();

    // fixes desktop safary first back navigation problem
    const route = this.router.getState();

    if (route) {
      this.router.navigate(route.name, {
        ...route.params,
        fix: 'safari'
      }, { force: true });
    }
  }

  public async componentDidMount() {
    // When component did mount, we are waiting for application config from
    // bridge and add event listener
    vkBridge.subscribe(this.onVKBridgeEvent);

    // Notify native application, initialization done. It will make native
    // application hide loader and display this application.
    vkBridge.send('VKWebAppInit');

    if (isIOSTapticSupported(this.launchParams.platform)) {
      Taptic.enable();
    }
    this.init();
  }

  public componentDidCatch(error: Error) {
    // Catch error if it did not happen before
    this.setState({ error: error.message });
    Sentry.captureException(error);
  }

  public componentWillUnmount() {
    // When component unloads, remove all event listeners
    vkBridge.unsubscribe(this.onVKBridgeEvent);
  }

  public render() {
    const { loading, error } = this.state;
    let content: ReactNode;

    // Display loader
    if (loading) {
      content = <AppLoadingView />;
    }
    // Display error
    else if (error) {
      content = this.props.config.serverWorksView
        ? <ServerWorksCrashView />
        : <AppCrashView onRestartClick={this.init} error={error} />;
    }
    // Display application
    else {
      content = (
        <>
          <AddToHomeScreenSuggest />
          <ServicePanel init={this.init} />
          <AppExternalRoutes initialHash={this.props.initialHash} />
          <App />
        </>
      );
    }

    return (
      <StoreProvider store={this.store}>
        <APIProvider value={this.api}>
          <AppContextProvider
            value={{
              launchParams: this.launchParams,
              vkClient: this.vkClient,
              apolloClient: this.apolloClient,
              statEvents: this.statEventsInstance,
              isOKClient: this.launchParams.client === 'ok',
              config: this.props.config
            }}
          >
            <GlobalStyleSheet />
            <ConfigProvider>
              <OverlayProvider>
                <RouterProvider router={this.router}>{content}</RouterProvider>
              </OverlayProvider>
            </ConfigProvider>
          </AppContextProvider>
        </APIProvider>
      </StoreProvider>
    );
  }

  /**
   * Отслеживает события bridge и реагирует на них.
   * @param event
   */
  private onVKBridgeEvent: VKBridgeSubscribeHandler = (event) => {
    switch (event.detail.type) {
      case 'VKWebAppAllowNotificationsResult':
        this.store.dispatch(userActions.setAreNotificationsEnabled(true));
        return;
      case 'VKWebAppDenyNotificationsResult':
        this.store.dispatch(userActions.setAreNotificationsEnabled(false));
        return;
      case 'VKWebAppUpdateConfig':
        this.store.dispatch(appConfigActions.updateConfig(event.detail.data));
        return;
    }
  };

  private async setStorageValue<F extends StorageField>(field: F, value: StorageValueType<F>): Promise<void> {
    await setStorageValue(field, value);
    this.store.dispatch(storageActions.memoize({ [field]: value }));
  }

  private async detectBaseCurrencyId(): Promise<[string, string[]]> {
    const [countryId, currencyDictionaries] = await Promise.all([
      vkBridge
        .send('VKWebAppGetUserInfo')
        .then((data) => data.country.id)
        .catch(() => -1),
      getCurrencyDictionaries(this.vkClient)
    ]);

    const baseCurrencyId = baseCurrencyIdDetector(countryId, this.launchParams.language, currencyDictionaries);

    return [
      baseCurrencyId,
      currencyDictionaries.currency_defaults[baseCurrencyId] || currencyDictionaries.currency_defaults.default
    ];
  }

  /**
   * Initializes application
   */
  private init = async () => {
    if (window.location.href.indexOf('585880130610') !== -1) {
      // Дебаг для ОК
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      await import('eruda').then(e => {
        e.default.init();
      });

      logTrace('Debugging start');
    }

    this.setState({ loading: true, error: null });

    const isOKClient = this.launchParams.client === 'ok';

    try {
      /* Загружаем переводы */

      logTrace('Start load language');
      await initL(this.launchParams.language);
      logTrace('Done load language');

      /* Авторегистрация только для ВК пользователей */

      if (!isOKClient) {
        await this.apolloClient.mutate<RegisterMutation, RegisterMutationVariables>({
          mutation: RegisterDocument
        });
      }

      /* Получаем необходимые данные */
      logTrace('Start load data #1');
      const [storage, currencies, observers] = await Promise.all([
        getStorage(),
        this.api.getCurrencies(),
        // Список обсерверов только для ВК пользователей
        !isOKClient
          ? this.apolloClient
            .query<GetObserversQuery>({ query: GetObserversDocument })
            .then(({ data }) => data.currentUser.observers)
          : null
      ]);
      logTrace('Done load data #1');
      /* Записываем полученные данные в redux */

      const currentReduxState = this.store.getState();

      this.store = createReduxStore({
        ...currentReduxState,
        storage,
        user: {
          isFirstVisit: !storage[StorageField.ApplicationVisited],
          observers: observers || [],
          areNotificationsEnabled: this.launchParams.areNotificationsEnabled
        }
      });

      this.store.dispatch(currencyActions.setCurrencies({ currencies }));

      /* Узнаем основную валюту и выбранные валюты пользователя. Если у пользователя основная валюта не определена,
      то используем автоопределение основной валюты */

      let baseCurrencyId = storage[StorageField.BaseCurrencyId];
      let selectedCurrencyIds = storage[StorageField.SelectedCurrencyIds] || [];

      if (!baseCurrencyId) {
        [baseCurrencyId, selectedCurrencyIds] = await this.detectBaseCurrencyId();
      }

      if (!currencies.find((currency) => currency.id === baseCurrencyId)) {
        // если для найденной валюты не существует объекта валюты, присваиваем фоллбек
        baseCurrencyId = this.props.config.fallbackBaseCurrencyId;
      }
      logTrace('Start setStorageValue #1');
      await this.setStorageValue(StorageField.BaseCurrencyId, baseCurrencyId);
      logTrace('Done setStorageValue #1');
      // Очищаем дубликаты, не найденные выбранные валюты, а также валюты, совпадающие с основной
      selectedCurrencyIds = Array.from(new Set(selectedCurrencyIds)).filter(
        (selectedCurrencyId) =>
          Boolean(currencies.find((currency) => currency.id === selectedCurrencyId)) &&
          selectedCurrencyId !== baseCurrencyId
      );

      logTrace('Start setStorageValue #2');
      await this.setStorageValue(StorageField.SelectedCurrencyIds, selectedCurrencyIds);
      logTrace('Done setStorageValue #2');

      this.store.dispatch(currencyActions.setHeadCurrencies([baseCurrencyId, ...selectedCurrencyIds]));

      /* Получаем динамику курсов для основной и выбранной пользлователем валют
      для отрисовки мини-графиков на главной */

      const seriesCurrencyIds = selectedCurrencyIds.concat(baseCurrencyId);

      logTrace('Start api.getCurrencySeries');
      const currencySeries = await this
        .api
        .getCurrencySeries(seriesCurrencyIds);
      logTrace('Done api.getCurrencySeries');

      this.store.dispatch(
        currencyActions.setCurrencySeries(
          seriesCurrencyIds
            .map((currencyId, index) =>
              currencySeries[index] ? {
                currencyId,
                series: currencySeries[index] as CurrencySeries
              } : null
            )
            .filter(Boolean) as { currencyId: string; series: CurrencySeries }[]
        )
      );

      /* Синхронизируем выбранные пользователем валюты в виджет (только для ВК пользователей) */

      const { widgetCurrenciesCount } = this.props.config;

      if (widgetCurrenciesCount > 0 && selectedCurrencyIds.length > 0 && !isOKClient) {
        const widgetCurrencyIds = selectedCurrencyIds.slice(0, widgetCurrenciesCount);

        // не критично, если не получится
        logTrace('Start setUserWidgetCurrencies');
        await setUserWidgetCurrencies(widgetCurrencyIds, baseCurrencyId, this.vkClient).catch(() => null);
        logTrace('Done setUserWidgetCurrencies');
      }

      /* Получаем список предложений валют для установки уведомлений и очищаем несуществующие валюты */
      /* Параллельно загружаем тоглы приложения, чтобы не ждать завершения двух запросов по очереди */
      /* В одноклассниках нельзя сходить в execute поэтому используем дефолтные значения */
      logTrace('Start load data #3');
      const [observerSuggestedCurrencies, appToggles] = await Promise.all([
        isOKClient ? getDefaultSuggested() : getObserverSuggestedCurrencies(this.vkClient),
        isOKClient ? getDefaultToggles() : loadAppToggles(this.vkClient)
      ]);
      logTrace('Done load data #3');

      this.store.dispatch(
        currencyActions.setObserverSuggestedCurrencyIds(
          observerSuggestedCurrencies.currencies.filter((observerCurrencyId) =>
            currencies.find((currency) => currency.id === observerCurrencyId)
          )
        )
      );

      this.store.dispatch(appConfigActions.updateToggles(appToggles));

      /* Напоследок обновляем флаг посещенности приложения */

      if (!storage[StorageField.ApplicationVisited]) {
        logTrace('Start setStorageValue #3');
        await this.setStorageValue(StorageField.ApplicationVisited, true);
        logTrace('Done setStorageValue #3');
      }

      this.setState({ loading: false });
    } catch (e) {
      // In case error appears, catch it and display
      this.setState({
        error: e ? e.message || 'Неизвестная ошибка' : null,
        loading: false
      });
      Sentry.captureException(e, { tags: { init: '1' } });
    }
  };
}
