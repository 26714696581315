import React, { memo } from 'react';
import { List } from '@vkontakte/vkui';
import { Placeholder, Button } from '@overrided-vkui';
import CurrencySelectedItem from '../../atomic/CurrencySelectedItem';
import CurrencySelectedItemLarge from '../../atomic/CurrencySelectedItemLarge';
import { L } from '../../../lang/L';
import { BaseRelatedCurrency } from '../../../types/currency';

const SELECTED_CURRENCIES_BIG_LIMIT = 3;

interface SelectedCurrenciesListProps {
  currencies: BaseRelatedCurrency[];
  observedCurrencyIds: string[];
  onCurrencyClick(currencyId: string): void;
  openCurrencyObserversModal?(currencyId: string): void;
  onFindCurrenciesClick(): void;
  className?: string;
}

const SelectedCurrenciesList: React.FC<SelectedCurrenciesListProps> = memo((props) => {
  const {
    currencies,
    className,
    onCurrencyClick,
    onFindCurrenciesClick,
    observedCurrencyIds,
    openCurrencyObserversModal,
  } = props;

  const CurrencyItem =
    currencies.length > SELECTED_CURRENCIES_BIG_LIMIT ? CurrencySelectedItem : CurrencySelectedItemLarge;

  return (
    <List className={className}>
      {currencies.length === 0 && (
        <Placeholder
          action={
            <Button size="l" onClick={onFindCurrenciesClick}>
              {L.t('empty_list_action_find')}
            </Button>
          }
        >
          <L.Jsx t="user_list_empty" />
        </Placeholder>
      )}
      {currencies.map((currency) => (
        <CurrencyItem
          key={currency.id}
          onClick={(): void => onCurrencyClick(currency.id)}
          currency={currency}
          openCurrencyObserversModal={openCurrencyObserversModal && openCurrencyObserversModal.bind(null, currency.id)}
          hasObservers={observedCurrencyIds.includes(currency.id)}
        />
      ))}
    </List>
  );
});

export default SelectedCurrenciesList;
