import React, { memo } from 'react';
import { MiniPostAttachmentTypeVideo } from '../../../types/miniPost';
import { makeStyles } from '@material-ui/styles';
import Icon28Play from '@vkontakte/icons/dist/28/play';
import { formatMediaDuration } from '../../../utils/formatter';
import RatioBlock from '../RatioBlock';

const useStyles = makeStyles({
  attachment__preview: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'block',
    backgroundColor: 'var(--content_tint_background)',
  },
  attachment__layer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  playButton: {
    width: 48,
    height: 48,
    borderRadius: '50%',
    background: 'rgba(0, 0, 0, 0.6)',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  attachment__duration: {
    position: 'absolute',
    right: 8,
    bottom: 8,
    background: 'rgba(0, 0, 0, 0.35)',
    color: '#fff',
    borderRadius: 4,
    padding: '3px 8px',
    fontSize: 12,
    lineHeight: '14px',
  },
});

const MiniPostAttachmentVideo = memo((props: MiniPostAttachmentTypeVideo) => {
  const { image, duration } = props;

  const mc = useStyles();

  return (
    <RatioBlock h={image.height} w={image.width}>
      <div className={mc.attachment__preview} style={{ backgroundImage: `url(${image.url})` }} />
      <div className={mc.attachment__layer}>
        <div className={mc.playButton}>
          <Icon28Play />
        </div>
      </div>
      {duration > 0 && <div className={mc.attachment__duration}>{formatMediaDuration(duration)}</div>}
    </RatioBlock>
  );
});

export default MiniPostAttachmentVideo;
