import React, { memo } from 'react';
import CurrencyLabel from '../../atomic/CurrencyLabel';
import { SimpleCell } from '@overrided-vkui';

interface CurrencyListItemProps {
  onClick?(): void;
  id: string;
  title: string;
  after?: React.ReactNode;
  className?: string;
}

const CurrencyListItem: React.FC<CurrencyListItemProps> = memo((props) => {
  const { onClick, id, title, after, className } = props;

  return (
    <SimpleCell className={className} after={after} onClick={onClick}>
      <CurrencyLabel id={id} title={title} />
    </SimpleCell>
  );
});

export default CurrencyListItem;
