import vkBridge, { TapticNotificationType, TapticVibrationPowerType } from '@vkontakte/vk-bridge';

export class Taptic {
  private static _enabled = false;

  /**
   * Инициализирует таптик синглтон
   */
  static enable() {
    Taptic._enabled = vkBridge.supports('VKWebAppTapticNotificationOccurred');
  }

  /**
   * Отключает таптик синглтон
   */
  static disable() {
    Taptic._enabled = false;
  }

  /**
   * Посылает тактильное уведомление
   */
  static notificationOccured(type: TapticNotificationType): void {
    if (Taptic._enabled) {
      vkBridge.send('VKWebAppTapticNotificationOccurred', { type }).catch(() => null);
    }
  }

  /**
   * Посылает тактильное уведомление об изменении
   */
  static selectionChanged(): void {
    if (Taptic._enabled) {
      vkBridge.send('VKWebAppTapticSelectionChanged', {}).catch(() => null);
    }
  }

  /**
   * Посылает тактильное уведомление о столкновении
   */
  static impactOccured(style: TapticVibrationPowerType): void {
    if (Taptic._enabled) {
      vkBridge.send('VKWebAppTapticImpactOccurred', { style }).catch(() => null);
    }
  }
}
