import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import ObserverBadge, { BadgeObserver } from './ObserverBadge';
import { Tappable } from '@vkontakte/vkui';
import Icon16Add from '@vkontakte/icons/dist/16/add';
import { getPlatformClassName, isDesktop } from '@overrided-vkui';

const useStyles = makeStyles({
  list: {
    '& > *': {
      marginBottom: 8,
      marginRight: 8,
      verticalAlign: 'top',
    },
    marginBottom: -8,
  },
  badgeAdd: {
    background: 'var(--panel_tab_active_background)',
    color: 'var(--icon_secondary)',
    width: 40,
    height: 30,
    display: 'inline-flex',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&--desktop': {
      background: 'var(--light_blue_40)',
      color: 'var(--steel_gray_200)',
      height: 26,
      width: 30,
      '&:hover': {
        color: 'var(--steel_gray_300)',
      },
      borderRadius: 4,
    },
  },
});

interface ObserverBadgeListProps {
  observers: BadgeObserver[];
  removeObserver(observerId: string): void;
  removingObserverIds: string[];
  onAddObserver?(): void;
}

const ObserverBadgeList = memo((props: ObserverBadgeListProps) => {
  const { observers, removeObserver, removingObserverIds, onAddObserver } = props;

  const mc = useStyles();

  const Wrapper = isDesktop ? 'div' : Tappable;

  return (
    <div className={mc.list}>
      {observers.map((observer) => (
        <ObserverBadge
          observer={observer}
          onRemove={() => removeObserver(observer.id)}
          removing={removingObserverIds.includes(observer.id)}
          key={observer.id}
        />
      ))}
      {onAddObserver && (
        <Wrapper className={getPlatformClassName(mc.badgeAdd)} onClick={onAddObserver}>
          <Icon16Add />
        </Wrapper>
      )}
    </div>
  );
});

export default ObserverBadgeList;
