import { useContext } from 'react';
import { apiContext } from './context';

/**
 * Возвращает ранее созданный инстанс API.
 */
function useAPI() {
  const api = useContext(apiContext);

  if (api === null) {
    throw new Error('API не был создан');
  }
  return api;
}

export default useAPI;
