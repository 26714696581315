import React, { memo, useCallback, useState } from 'react';
import { CurrencyInfoModalProps } from './index';
import withCurrencyInfoModalData, { WithCurrencyInfoModalInjectedProps } from './withCurrencyInfoModalData';
import { FormLayoutGroup } from '@vkontakte/vkui';
import { Button, Div, ModalPageHeader, PanelHeaderButton, ModalPage, Separator } from '@overrided-vkui';
import { L } from '../../../lang/L';
import { makeStyles } from '@material-ui/styles';
import { formatRate, formatToLabelTime } from '../../../utils/formatter';
import RateChartSkeleton from '../../atomic/RateChartSkeleton';
import RateChart from '../../atomic/RateChart';
import RateDelta from '../../atomic/RateDelta';
import Icon28ShareOutline from '@vkontakte/icons/dist/28/share_outline';
import Icon24AddOutline from '@vkontakte/icons/dist/24/add_outline';
import Icon24DoneOutline from '@vkontakte/icons/dist/24/done_outline';
import Icon24NotificationOutline from '@vkontakte/icons/dist/24/notification_outline';
import { ReactComponent as Icon24InfoCircleOutline } from '../../../assets/info_circle_outline_24.svg';
import c from 'classnames';
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss';
import AdaptiveFontSize from '../../atomic/AdaptiveFontSize';
import { CurrencySeriesRate } from '../../../types/currency';
import { Taptic } from '../../../utils/taptic';
import { throttle } from 'throttle-debounce';
import { useAppContext } from 'src/components/AppContext';

const useStyles = makeStyles({
  titleRate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  updateInfo: {
    color: 'var(--text_tertiary)',
    fontSize: 13,
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  updateInfo__at: {
    fontSize: 13,
    height: 12,
    margin: '4px 0',
  },
  updateInfo__at_skeleton: {
    fontSize: 0,
    maxWidth: 150,
    background: 'var(--control_background)',
    borderRadius: 4,
  },
  separator: {
    margin: '12px 0 7px',
  },
  indicators: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: -12,
  },
  indicatorItem: {
    padding: '12px 0',
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingRight: 8,
    marginRight: 4,
  },
  indicatorItem__title: {
    color: 'var(--text_secondary)',
    fontSize: 13,
    height: 12,
  },
  indicatorItem__value: {
    height: 16,
    margin: '5px 0',
  },
  indicatorItem_skeleton: {
    '& $indicatorItem__title': {
      fontSize: 0,
      maxWidth: 75,
      background: 'var(--control_background)',
      borderRadius: 4,
    },
    '& $indicatorItem__value': {
      fontSize: 0,
      maxWidth: 60,
      background: 'var(--control_background)',
      borderRadius: 4,
    },
  },
  chart: {
    minHeight: 186,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  graphRate: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: '0',
    top: '0',
    background: 'var(--background_content)',
    visibility: 'hidden',
    textAlign: 'center',
    '&[data-visible="true"]': {
      visibility: 'visible',
    },
  },
  graphRate__date: {
    fontSize: 14,
    color: 'var(--text_tertiary)',
  },
  graphRate__rate: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 4,
  },
  modalHeader: {
    '& .ModalPageHeader__left,& .ModalPageHeader__right': {
      alignSelf: 'flex-start',
      flexGrow: 1,
      flexBasis: 0,
    },
    '& .ModalPageHeader__content': {
      width: 'auto',
    },
    '& .ModalPageHeader__content-in': {
      display: 'block',
      textAlign: 'center',
    },
  },
});

const CurrencyInfoModalMobile: React.FC<CurrencyInfoModalProps & WithCurrencyInfoModalInjectedProps> = memo((props) => {
  const {
    id: modalId,
    onClose,
    isObserversSupports,
    openObservers,
    isSelected,
    currencyId,
    areNotificationsEnabled,
    openTerms,
    currency,
    openStoryShare,
    toggleSeleted,
    isStoryShareSupports,
    currencySeriesFetchStatus,
    refetchCurrencySeries,
  } = props;

  const mc = useStyles();

  const { statEvents } = useAppContext();

  const throttledSendStatEvents = useCallback(throttle(500, statEvents.push), [statEvents.push]);

  const [focusPoint, setFocusPoint] = useState<CurrencySeriesRate | null>(null);

  const onChartFocusHandler = useCallback(
    (point: CurrencySeriesRate) => {
      setFocusPoint((prevPoint) => {
        Taptic.selectionChanged();
        throttledSendStatEvents(prevPoint ? 'graph_drag' : 'graph_tap', { name: currencyId });
        return point;
      });
    },
    [currencyId, throttledSendStatEvents]
  );

  const onChartBlurHandler = useCallback(() => {
    setFocusPoint(null);
  }, []);

  return (
    <ModalPage
      id={modalId}
      onClose={onClose}
      header={
        <ModalPageHeader
          className={mc.modalHeader}
          left={
            isStoryShareSupports && (
              <PanelHeaderButton onClick={openStoryShare}>
                <Icon28ShareOutline />
              </PanelHeaderButton>
            )
          }
          right={
            <PanelHeaderButton onClick={onClose}>
              <Icon24Dismiss />
            </PanelHeaderButton>
          }
          noShadow
        >
          {currency ? currency.id : L.t('not_found')}
        </ModalPageHeader>
      }
    >
      {currency && (
        <>
          <div className={mc.titleRate}>
            <b style={{ marginBottom: 4 }}>{formatRate(currency.rate, currency.base.symbol)}</b>
            <RateDelta
              prevRate={currency.prevRate}
              rate={currency.rate}
              symbol={currency.base.symbol}
              style={{ fontSize: 14 }}
            />
            <div className={mc.graphRate} data-visible={Boolean(focusPoint)}>
              <div className={mc.graphRate__rate}>
                {focusPoint ? formatRate(focusPoint.rate, currency.base.symbol) : null}
              </div>
              <div className={mc.graphRate__date}>{focusPoint ? formatToLabelTime(focusPoint.date) : null}</div>
            </div>
          </div>
          <Div>
            <div className={mc.chart}>
              {currency.series ? (
                <RateChart
                  points={currency.series.rates}
                  onPointFocus={onChartFocusHandler}
                  onPointBlur={onChartBlurHandler}
                />
              ) : (
                <RateChartSkeleton
                  onRetry={refetchCurrencySeries}
                  loading={currencySeriesFetchStatus === 'loading'}
                  failed={currencySeriesFetchStatus === 'error'}
                />
              )}
            </div>
          </Div>
          <Div className={mc.indicators}>
            <div className={c(mc.indicatorItem, { [mc.indicatorItem_skeleton]: !currency.series })}>
              <div className={mc.indicatorItem__title}>{L.t('indicator_rate_open')}</div>
              <AdaptiveFontSize
                minSize={12}
                maxSize={17}
                lineHeight={1.33}
                key={Number(!currency.series)}
                className={mc.indicatorItem__value}
              >
                {currency.series && formatRate(currency.series.openRate, currency.base.symbol)}
              </AdaptiveFontSize>
            </div>
            <div className={c(mc.indicatorItem, { [mc.indicatorItem_skeleton]: !currency.series })}>
              <div className={mc.indicatorItem__title}>{L.t('indicator_rate_minimum')}</div>
              <AdaptiveFontSize
                minSize={12}
                maxSize={17}
                lineHeight={1.33}
                key={Number(!currency.series)}
                className={mc.indicatorItem__value}
              >
                {currency.series && formatRate(currency.series.minRate, currency.base.symbol)}
              </AdaptiveFontSize>
            </div>
            <div className={c(mc.indicatorItem, { [mc.indicatorItem_skeleton]: !currency.series })}>
              <div className={mc.indicatorItem__title}>{L.t('indicator_rate_maximum')}</div>
              <AdaptiveFontSize
                minSize={12}
                maxSize={17}
                lineHeight={1.33}
                key={Number(!currency.series)}
                className={mc.indicatorItem__value}
              >
                {currency.series && formatRate(currency.series.maxRate, currency.base.symbol)}
              </AdaptiveFontSize>
            </div>
          </Div>
          <Separator className={mc.separator} />
          <Div className={mc.updateInfo}>
            <div>
              <div className={c(mc.updateInfo__at, { [mc.updateInfo__at_skeleton]: !currency.series })}>
                {currency.series &&
                  `${L.t('indicator_updated_at', { time: L.format.time(currency.series.timestamp) })}.`}
              </div>
              <div
                className={c(mc.updateInfo__at, { [mc.updateInfo__at_skeleton]: !currency.series })}
                style={{ minWidth: 200 }}
              >
                {L.t('terms_source')}.
              </div>
            </div>
            <PanelHeaderButton>
              <Icon24InfoCircleOutline style={{ display: 'block' }} onClick={openTerms} />
            </PanelHeaderButton>
          </Div>
          <Div>
            <FormLayoutGroup>
              {isSelected ? (
                <Button size="xl" mode="secondary" before={<Icon24DoneOutline />} onClick={toggleSeleted}>
                  {L.t('button_add_currency_done')}
                </Button>
              ) : (
                <Button size="xl" before={<Icon24AddOutline />} onClick={toggleSeleted}>
                  {L.t('button_add_currency')}
                </Button>
              )}
              {isObserversSupports && (
                <Button
                  size="xl"
                  mode="secondary"
                  before={<Icon24NotificationOutline style={{ marginRight: 6 }} />}
                  onClick={openObservers}
                >
                  {areNotificationsEnabled ? L.t('button_add_observers_done') : L.t('button_add_observers')}
                </Button>
              )}
            </FormLayoutGroup>
          </Div>
        </>
      )}
    </ModalPage>
  );
});

export default withCurrencyInfoModalData(CurrencyInfoModalMobile);
