import React, { memo, useState, useCallback } from 'react';
import { CurrencyInfoModalProps } from './index';
import withCurrencyInfoModalData, { WithCurrencyInfoModalInjectedProps } from './withCurrencyInfoModalData';
import {
  Button,
  Div,
  PanelHeaderButton,
  ModalPage,
  Separator,
  SimpleCell,
  Title,
  Subhead,
  Link,
  Text,
  HoverTultip,
} from '@overrided-vkui';
import { L } from '../../../lang/L';
import { makeStyles } from '@material-ui/styles';
import { formatRate, formatToLabelTime } from '../../../utils/formatter';
import RateChartSkeleton from '../../atomic/RateChartSkeleton';
import RateChart from '../../atomic/RateChart';
import RateDelta from '../../atomic/RateDelta';
import Icon24ShareOutline from '@vkontakte/icons/dist/24/share_outline';
import { ReactComponent as Icon24InfoCircleOutline } from '../../../assets/info_circle_outline_24.svg';
import c from 'classnames';
import CurrencyIcon from '../../atomic/CurrencyIcon';
import AdaptiveFontSize from '../../atomic/AdaptiveFontSize';
import { CurrencySeriesRate } from '../../../types/currency';
import { throttle } from 'throttle-debounce';
import { useAppContext } from 'src/components/AppContext';

const useStyles = makeStyles({
  modal: {
    '& .ModalPage__in': {
      paddingTop: 96,
    },
  },
  header: {
    height: 96,
    '& .SimpleCell__after .PanelHeaderButton': {
      '--accent': 'var(--blue_400)',
    },
    '& .SimpleCell__main': {
      marginLeft: 12,
    },
    '& .Separator': {
      zIndex: 3,
    },
  },
  header__label: {
    fontSize: 48,
  },
  titleRate: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginTop: 1,
  },
  chart: {
    minHeight: 186,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  updateInfo: {
    color: 'var(--steel_gray_300)',
    fontSize: 13,
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 4,
    paddingBottom: 24,
  },
  updateInfo__at: {
    fontSize: 13,
    height: 12,
    margin: '4px 0',
  },
  updateInfo__at_skeleton: {
    fontSize: 0,
    maxWidth: 150,
    background: 'var(--control_background)',
    borderRadius: 4,
  },
  indicators: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: -12,
  },
  indicatorItem: {
    padding: '12px 12px 12px 0px',
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingRight: 8,
    marginRight: 4,
  },
  indicatorItem__title: {
    color: 'var(--steel_gray_400)',
    fontSize: 13,
    height: 12,
    textTransform: 'lowercase',
  },
  indicatorItem__value: {
    height: 16,
    margin: '6px 0',
  },
  indicatorItem_skeleton: {
    '& $indicatorItem__title': {
      fontSize: 0,
      maxWidth: 75,
      background: 'var(--control_background)',
      borderRadius: 4,
    },
    '& $indicatorItem__value': {
      fontSize: 0,
      maxWidth: 60,
      background: 'var(--control_background)',
      borderRadius: 4,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    borderTop: '1px solid var(--steel_gray_80)',
    alignItems: 'center',
  },
  graphRate: {
    position: 'absolute',
    bottom: '100%',
    marginBottom: 9,
    left: 0,
    textAlign: 'center',
    padding: '8px 12px',
    borderRadius: 8,
    transform: 'translate(-50%, 0)',
    background: 'var(--gray_800_alpha88)',
    whiteSpace: 'pre',
  },
  graphRate__date: {
    color: 'var(--white_alpha64)',
    fontSize: 13,
    lineHeight: '16px',
  },
  graphRate__rate: {
    color: '#fff',
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: 1,
  },
});

const CurrencyInfoModalDesktop: React.FC<CurrencyInfoModalProps & WithCurrencyInfoModalInjectedProps> = memo(
  (props) => {
    const {
      id: modalId,
      currencyId,
      onClose,
      isObserversSupports,
      openObservers,
      isSelected,
      areNotificationsEnabled,
      openTerms,
      currency,
      openStoryShare,
      toggleSeleted,
      isStoryShareSupports,
      currencySeriesFetchStatus,
      refetchCurrencySeries,
    } = props;

    const mc = useStyles();

    const { statEvents } = useAppContext();

    const throttledSendStatEvents = useCallback(throttle(500, statEvents.push), [statEvents.push]);

    const [focusPoint, setFocusPoint] = useState<(CurrencySeriesRate & { translateX: number }) | null>(null);

    const onChartFocusHandler = useCallback(
      (point: CurrencySeriesRate, translateX: number) => {
        setFocusPoint((prevPoint) => {
          throttledSendStatEvents(prevPoint ? 'graph_drag' : 'graph_tap', { name: currencyId });
          return { ...point, translateX };
        });
      },
      [currencyId, throttledSendStatEvents]
    );

    const onChartBlurHandler = useCallback(() => {
      setFocusPoint(null);
    }, []);

    return (
      <ModalPage
        id={modalId}
        onClose={onClose}
        className={mc.modal}
        header={
          <>
            <SimpleCell
              className={mc.header}
              multiline
              before={<CurrencyIcon id={currencyId} size={48} />}
              description={<Subhead weight="regular">{currency?.title || L.t('not_found')}</Subhead>}
              after={
                isStoryShareSupports && (
                  <HoverTultip title={L.t('currency_story_share_hint')} placement="top" margin="0 -8px -2px 0">
                    <PanelHeaderButton onClick={openStoryShare}>
                      <Icon24ShareOutline />
                    </PanelHeaderButton>
                  </HoverTultip>
                )
              }
            >
              <Title level="2" weight="medium">
                {currencyId}
              </Title>
            </SimpleCell>
            <Separator />
          </>
        }
      >
        {currency && (
          <>
            <Div className={mc.titleRate}>
              <div style={{ marginBottom: 4 }}>{formatRate(currency.rate, currency.base.symbol)}</div>
              <RateDelta
                prevRate={currency.prevRate}
                rate={currency.rate}
                symbol={currency.base.symbol}
                style={{ fontSize: 14 }}
              />
            </Div>
            <Div style={{ paddingTop: 0, paddingBottom: 24 }}>
              <div className={mc.chart}>
                <div
                  className={mc.graphRate}
                  style={{
                    visibility: focusPoint ? 'visible' : 'hidden',
                    left: `max(30px, ${focusPoint?.translateX}px)`,
                  }}
                >
                  <div className={mc.graphRate__rate}>
                    {focusPoint ? formatRate(focusPoint.rate, currency.base.symbol) : null}
                  </div>
                  <div className={mc.graphRate__date}>{focusPoint ? formatToLabelTime(focusPoint.date) : null}</div>
                </div>
                {currency.series ? (
                  <RateChart
                    points={currency.series.rates}
                    onPointFocus={onChartFocusHandler}
                    onPointBlur={onChartBlurHandler}
                  />
                ) : (
                  <RateChartSkeleton
                    onRetry={refetchCurrencySeries}
                    loading={currencySeriesFetchStatus === 'loading'}
                    failed={currencySeriesFetchStatus === 'error'}
                  />
                )}
              </div>
            </Div>
            <Separator />
            <Div className={mc.indicators}>
              <div className={c(mc.indicatorItem, { [mc.indicatorItem_skeleton]: !currency.series })}>
                <div className={mc.indicatorItem__title}>{L.t('indicator_rate_open')}</div>
                <AdaptiveFontSize
                  minSize={12}
                  maxSize={15}
                  lineHeight={1.33}
                  key={Number(!currency.series)}
                  className={mc.indicatorItem__value}
                >
                  {currency.series && formatRate(currency.series.openRate, currency.base.symbol)}
                </AdaptiveFontSize>
              </div>
              <div className={c(mc.indicatorItem, { [mc.indicatorItem_skeleton]: !currency.series })}>
                <div className={mc.indicatorItem__title}>{L.t('indicator_rate_minimum')}</div>
                <AdaptiveFontSize
                  minSize={12}
                  maxSize={15}
                  lineHeight={1.33}
                  key={Number(!currency.series)}
                  className={mc.indicatorItem__value}
                >
                  {currency.series && formatRate(currency.series.minRate, currency.base.symbol)}
                </AdaptiveFontSize>
              </div>
              <div className={c(mc.indicatorItem, { [mc.indicatorItem_skeleton]: !currency.series })}>
                <div className={mc.indicatorItem__title}>{L.t('indicator_rate_maximum')}</div>
                <AdaptiveFontSize
                  minSize={12}
                  maxSize={15}
                  lineHeight={1.33}
                  key={Number(!currency.series)}
                  className={mc.indicatorItem__value}
                >
                  {currency.series && formatRate(currency.series.maxRate, currency.base.symbol)}
                </AdaptiveFontSize>
              </div>
            </Div>
            <Div className={mc.updateInfo}>
              <div>
                <div className={c(mc.updateInfo__at, { [mc.updateInfo__at_skeleton]: !currency.series })}>
                  {currency.series &&
                    `${L.t('indicator_updated_at', { time: L.format.time(currency.series.timestamp) })}.`}
                </div>
                <div
                  className={c(mc.updateInfo__at, { [mc.updateInfo__at_skeleton]: !currency.series })}
                  style={{ minWidth: 200 }}
                >
                  {L.t('terms_source')}.
                </div>
              </div>
              <PanelHeaderButton>
                <Icon24InfoCircleOutline style={{ display: 'block' }} onClick={openTerms} />
              </PanelHeaderButton>
            </Div>
            <Div className={mc.footer}>
              {isSelected ? (
                <Button size="l" mode="secondary" onClick={toggleSeleted}>
                  {L.t('button_add_currency_done')}
                </Button>
              ) : (
                <Button size="l" onClick={toggleSeleted}>
                  {L.t('button_add_currency')}
                </Button>
              )}
              {isObserversSupports && (
                <Text weight="regular">
                  <Link onClick={openObservers}>
                    {areNotificationsEnabled ? L.t('button_add_observers_done') : L.t('button_add_observers')}
                  </Link>
                </Text>
              )}
            </Div>
          </>
        )}
      </ModalPage>
    );
  }
);

export default withCurrencyInfoModalData(CurrencyInfoModalDesktop);
