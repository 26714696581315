import i18next, { TFunction } from 'i18next';
import LJsx, { LJsxProps } from './LJsx';
import { BackendModule, initFormat, Format, BackendFallback } from '../utils/lib/vkui-lang-tools';

interface L {
  t: TFunction;
  format: Format;
  Jsx: React.FC<LJsxProps>;
}

export const L: L = {
  t: (arg: string) => arg,
  format: initFormat((arg: string) => arg),
  Jsx: LJsx,
};

const importLocale: BackendFallback = (lng) => import(`./locales/${lng}.json`);

export async function initL(lng: string): Promise<L> {
  return i18next
    .use(BackendModule)
    .init({
      lng,
      backend: {
        name: 'currency',
        defaultNsPrefix: 'vkui_hs_currency',
        fallback: () => importLocale(lng).catch(() => importLocale('ru')),
      },
      debug: process.env.NODE_ENV === 'development',
    })
    .then((t) => {
      L.t = t;
      L.format = initFormat(t);
      return L;
    });
}
