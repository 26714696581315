import React from 'react';
import styled from '@material-ui/styles/styled';

import { PlaceholderProps } from '@vkontakte/vkui/dist/components/Placeholder/Placeholder';
import { Placeholder } from '@vkontakte/vkui';

export default styled(Placeholder)({
  maxWidth: 490,
  margin: '0 auto',
  background: 'transparent',
  '--text_placeholder': 'var(--steel_gray_400)',
  '--placeholder_icon_foreground_secondary': 'var(--steel_gray_300)',
  '& .Placeholder__header': {
    fontSize: 18,
    lineHeight: '22px',
  },
  '& .Placeholder__text': {
    fontSize: 14.5,
    lineHeight: '20px',
  },
  '& .Placeholder__action': {
    top: -4,
    bottom: -4,
    position: 'relative',
    '& > *': {
      margin: 4,
    },
    '&:not(:first-child)': {
      marginTop: 20,
    },
  },
}) as React.FC<PlaceholderProps>;
