import React, { useEffect, memo } from 'react';
import { Snackbar, getPlatformClassName, isDesktop } from '@overrided-vkui';
import Icon28ErrorOutline from '@vkontakte/icons/dist/28/error_outline';
import { makeStyles } from '@material-ui/styles';
import { L } from '../../lang/L';
import { Taptic } from 'src/utils/taptic';

const useStyles = makeStyles({
  snackbar: {
    '&--desktop': {
      '& .Snackbar__body': {
        background: 'var(--gray_800_alpha88)',
      },
      '& .Snackbar__action': {
        '--accent': 'var(--sky_100)',
      },
      '& .Snackbar__content-text': {
        color: '#fff',
        fontSize: 14,
        lineHeight: '18px',
      },
      '& .Icon > svg > use': {
        color: '#fff!important',
      },
    },
  },
});

interface ErrorSnackbarProps {
  text?: string;
  onRetry?(): Promise<void>;
  onClose(): void;
}

const ErrorSnackbar: React.FC<ErrorSnackbarProps> = memo((props) => {
  const { text, onRetry, onClose } = props;
  const mc = useStyles();

  useEffect(() => {
    Taptic.notificationOccured('error');
  }, []);

  return (
    <Snackbar
      layout={isDesktop ? 'vertical' : 'horizontal'}
      action={onRetry && L.t('error_retry')}
      onActionClick={onRetry && (() => onRetry().catch(() => null))}
      before={<Icon28ErrorOutline fill="var(--destructive)" />}
      className={getPlatformClassName(mc.snackbar)}
      onClose={onClose}
    >
      {text || L.t('error_retry_default_message')}
    </Snackbar>
  );
});

export default ErrorSnackbar;
