import CurrencyInfoModalMobile from './CurrencyInfoModalMobile';
import CurrencyInfoModalDesktop from './CurrencyInfoModalDesktop';
import { getPlatformComponent, withModalPageOverlayWrapper } from '../../overrided-vkui';

export interface CurrencyInfoModalProps {
  id: string;
  currencyId: string;
  onClose(): void;
  openTerms(): void;
  openObservers(currencyId: string): void;
}

export default withModalPageOverlayWrapper(
  getPlatformComponent<CurrencyInfoModalProps>({
    mobile: CurrencyInfoModalMobile,
    desktop: CurrencyInfoModalDesktop,
  })
);
