import { VkClientInstance } from './lib/vkClient/types';

/**
 * Устанавливает валюты в виджет пользователя
 * @param currencyIds устанавливаемые валюты
 * @param baseCurrencyId основная валюта перевода
 */
export const setUserWidgetCurrencies = (
  currencyIds: string[],
  baseCurrencyId: string,
  vkClient: VkClientInstance
): Promise<1> => {
  return vkClient.call('exchangeRates.setUserCurrencyList', {
    currencies: currencyIds.join(),
    base_currency: baseCurrencyId,
  });
};

export interface CurrencyDictionaries {
  currency_countries: Record<string, number[]>;
  currency_languages: Record<string, string[]>;
  currency_defaults: Record<string, string[]> & { default: string[] };
}

/**
 * Получает словари для определения пользовательских валют
 */
export const getCurrencyDictionaries = (vkClient: VkClientInstance): Promise<CurrencyDictionaries> => {
  return vkClient
    .call('exchangeRates.getCurrenciesData')
    .catch(() => import('../assets/dictionaries/currenciesData.json').then((data) => data.default));
};
