import React from 'react';
import { DynamicDirection } from '../../types/currency';
import Tappable from '@vkontakte/vkui/dist/components/Tappable/Tappable';
import { makeStyles } from '@material-ui/styles';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';
import { Spinner } from '@vkontakte/vkui';
import { getPlatformClassName, isDesktop } from '@overrided-vkui';
import { formatRate } from '../../utils/formatter';

const useStyles = makeStyles({
  badge: {
    background: 'var(--panel_tab_active_background)',
    color: 'var(--text_subhead)',
    fontSize: 14,
    padding: '0 8px 0 12px',
    height: '30px',
    borderRadius: 10,
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&--desktop': {
      color: 'var(--steel_gray_540)',
      fontSize: 13,
      fontWeight: 400,
      height: 26,
      padding: '0 6px 0 10px',
      borderRadius: 4,
      background: 'var(--light_blue_40)',
      '& $badge__cross': {
        color: 'var(--steel_gray_200)',
        cursor: 'pointer',
        '&:hover': {
          color: 'var(--steel_gray_300)',
        },
      },
    },
  },
  badge__cross: {
    color: 'var(--icon_secondary)',
    padding: 7,
    width: 16,
    height: 16,
    margin: '-7px -8px',
    marginLeft: 5,
  },
});

export interface BadgeObserver {
  id: string;
  rate: number;
  direction: DynamicDirection;
  baseCurrency: {
    id: string;
    symbol: string;
  };
}

interface ObserverBadgeProps {
  observer: BadgeObserver;
  onRemove(): void;
  removing: boolean;
}

const ObserverBadge: React.FC<ObserverBadgeProps> = (props: ObserverBadgeProps) => {
  const { observer, onRemove, removing } = props;

  const mc = useStyles();

  const Wrapper = isDesktop ? 'div' : Tappable;

  return (
    <div className={getPlatformClassName(mc.badge)}>
      {observer.direction === DynamicDirection.Negative ? '<' : '>'}{' '}
      {formatRate(observer.rate, observer.baseCurrency.symbol)}
      {removing ? (
        <Spinner className={mc.badge__cross} size="small" />
      ) : (
        <Wrapper className={mc.badge__cross} onClick={onRemove}>
          <Icon16Cancel />
        </Wrapper>
      )}
    </div>
  );
};

export default ObserverBadge;
