import { AnyPlatform } from '../types/launch-params';
import vkBridge from '@vkontakte/vk-bridge';

/**
 * Определяет, поддерживается ли таптик (на Android не включаем, плохо работает)
 * @param platform платформа из параметров запуска
 */
 export function isIOSTapticSupported(platform: AnyPlatform) {
    return (
      ['mobile_iphone', 'mobile_iphone_messenger'].includes(platform) &&
      vkBridge.supports('VKWebAppTapticNotificationOccurred')
    );
  }