import React, { memo, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import { L } from '../../../lang/L';

const useStyles = makeStyles({
  target: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    cursor: 'initial',
  },
  layer: {
    transform: 'translate3d(-50%,-50%,0)',
    opacity: 0.4,
    position: 'fixed',
    zIndex: -1,
    height: '200vh',
    width: '200vw',
  },
  layerTarget: {
    height: '100%',
    width: '100%',
  },
  overlay: {
    width: 300,
    marginTop: -2,
  },
  tooltip: {
    background: 'var(--blue_bright)',
    borderRadius: 8,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16)',
    padding: '8.5px 12px 9.5px',
    boxSizing: 'border-box',
    display: 'block',
    position: 'relative',
    '&:before': {
      content: '""',
      // eslint-disable-next-line
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='8px' viewBox='0 0 20 8' version='1.1'%3E%3Ctitle%3Etooltip_tip%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs/%3E%3Cg id='Master-Kit' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M10,0 C13.5,0 15.5,8 20,8 L0,8 C4.5,8 6.5,0 10,0 Z' id='tooltip_tip' fill='%235c9ce6' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E")`,
      width: 20,
      height: 8,
      display: 'block',
      position: 'absolute',
      top: -8,
      left: 23,
    },
  },
  tooltip__content: {
    color: 'var(--white)',
    fontSize: 14,
    lineHeight: '18px',
    textAlign: 'left',
  },
  tooltip__header: {
    fontWeight: 600,
    whiteSpace: 'initial',
  },
  tooltip__text: {
    whiteSpace: 'initial',
  },
  tooltip__dismiss: {
    float: 'right',
    color: 'var(--white)',
    opacity: 0.7,
    cursor: 'pointer',
    marginTop: -2,
    marginRight: -6,
    '&:hover': {
      opacity: 1,
    },
  },
});

interface FeedHintDesktopProps {
  onOpen(): void;
  onDismiss(): void;
}

const FeedHintDesktop = memo((props: FeedHintDesktopProps) => {
  const { onOpen, onDismiss } = props;

  useEffect(() => {
    const initialOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = initialOverflow;
    };
  }, []);

  const mc = useStyles();

  return (
    <div className={mc.target} onClick={(e) => e.stopPropagation()}>
      <div className={mc.layer} onClick={onDismiss} />
      <div className={mc.layerTarget} onClick={onOpen} />
      <div className={mc.overlay}>
        <div className={mc.tooltip} onClick={onOpen}>
          <div className={mc.tooltip__content}>
            <Icon24Cancel className={mc.tooltip__dismiss} onClick={onDismiss} />
            <div className={mc.tooltip__header}>{L.t('feed_tooltip_header')}</div>
            <div className={mc.tooltip__text}>{L.t('feed_tooltip_text')}</div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default FeedHintDesktop;
