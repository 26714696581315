import React, { memo } from 'react';
import { MiniPostAttachmentTypePhoto } from '../../../types/miniPost';
import { makeStyles } from '@material-ui/styles';
import RatioBlock from '../RatioBlock';

const useStyles = makeStyles({
  attachment__photo: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'block',
    backgroundColor: 'var(--content_tint_background)',
  },
});

const MiniPostAttachmentPhoto = memo((props: MiniPostAttachmentTypePhoto) => {
  const { image } = props;

  const mc = useStyles();

  return (
    <RatioBlock h={image.height} w={image.width}>
      <div className={mc.attachment__photo} style={{ backgroundImage: `url(${image.url})` }} />
    </RatioBlock>
  );
});

export default MiniPostAttachmentPhoto;
