import React, { useCallback, useState, useEffect, memo } from 'react';
import { useSelector } from '../../hooks/useSelector';
import { getDigitsAfterDotLimit } from '../../utils/formatter';
import useBaseCurrency from '../../hooks/currency/useBaseCurrency';
import { getCurrency } from '../../redux/selectors';
import { useActions } from '../../hooks/useActions';
import { currencyActions } from '../../redux/reducers/currency';
import { Subtract } from '../../types/utility';
import CurrencySelectModal from '../layout/CurrencySelectModal';
import { useRoute } from 'react-router5';
import { delay } from '../../utils/misc';
import { throttle } from 'throttle-debounce';
import { Taptic } from 'src/utils/taptic';
import { useAppContext } from '../AppContext';

const FALLBACK_FIRST_CURRENCY_ID = 'USD';

const CONVERTER_SELECT_CURRENCY_MODAL_KEY = 'converter-select-currency';

const DELAY_AFTER_SELECT = 200;

// Работаем с float значением в строковом формате
// Повышает точность и упрощает конкатенацию при наборе числа
const floatStringCalc = (cb: (value: number) => number) => (value: string): string => {
  const calcedValue = cb(parseFloat(value || '0'));

  const digitsAfterDot = getDigitsAfterDotLimit(String(calcedValue));

  const parts = String(calcedValue.toFixed(digitsAfterDot)).split('.');
  parts[1] = parts[1].replace(/0*$/, '');

  if (parts[1] === '') {
    parts.pop();
  }

  return parts.join('.');
};

interface CurrencyData {
  id: string;
  value: string;
  onClick(): void;
  onChange(value: string): void;
}

type FocusedCurrency = 'first' | 'second';

interface WithConverterComponentProps {
  firstCurrency: CurrencyData;
  secondCurrency: CurrencyData;
  onSwitchClick(): void;
  onInput(fromCurrencyId: string, toCurrencyId: string): void;
  focusedCurrency: FocusedCurrency;
  onFocusChange(focused: FocusedCurrency): void;
}

function withConverter<P extends WithConverterComponentProps>(
  Component: React.FC<P>
): React.FC<Subtract<P, WithConverterComponentProps>> {
  return memo((props) => {
    const { route, router } = useRoute();

    const { statEvents } = useAppContext();

    const throttledSendStatEvents = useCallback(throttle(500, statEvents.push), []);

    const firstDefaultCurrencyId = useSelector(
      (state) => (state.currency.sortedCurrencyIds && state.currency.sortedCurrencyIds[1]) || FALLBACK_FIRST_CURRENCY_ID
    );

    const { baseCurrency } = useBaseCurrency();
    const secondDefaultCurrencyId = baseCurrency.id;

    const initConverterState = useSelector((state) => ({
      firstCurrencyId: state.currency.converterState?.firstCurrencyId || firstDefaultCurrencyId,
      secondCurrencyId: state.currency.converterState?.secondCurrencyId || secondDefaultCurrencyId,
      firstCurrencyValue: state.currency.converterState?.firstCurrencyValue || '1',
      secondCurrencyValue: state.currency.converterState?.secondCurrencyValue,
      focusedCurrency: state.currency.converterState?.focusedCurrency || 'first',
    }));

    const setConverterState = useActions(currencyActions.setConverterState);

    /* Выбранные валюты конвертера */

    const [firstCurrencyId, setFirstCurrencyId] = useState(initConverterState.firstCurrencyId);
    const [secondCurrencyId, setSecondCurrencyId] = useState(initConverterState.secondCurrencyId);

    const firstCurrency = useSelector(getCurrency(firstCurrencyId))!; // eslint-disable-line
    let secondCurrency = useSelector(getCurrency(secondCurrencyId));

    // Если secondCurrency валюта не найдена, ставим firstCurrency валюту, которая точно есть
    if (!secondCurrency) {
      setSecondCurrencyId(firstCurrency.id);
      secondCurrency = firstCurrency;
    }

    /* Значения полей конвертера */

    const currencyRatio = firstCurrency.rate / secondCurrency.rate;

    // храним значения в строке, для удобства конкатенации и избежания потерь в точности
    // вычисления выполняем с помощью floatStringCalc
    const [firstCurrencyValue, setFirstCurrencyValue] = useState(initConverterState.firstCurrencyValue);
    const [secondCurrencyValue, setSecondCurrencyValue] = useState(
      initConverterState.secondCurrencyValue || floatStringCalc((x) => x / currencyRatio)(firstCurrencyValue)
    );

    // обновляем первое поле с автоматическим пеперасчетом второго
    const updateValuesByFirst = useCallback(
      (value: string) => {
        setFirstCurrencyValue(value);
        setSecondCurrencyValue(floatStringCalc((x) => x / currencyRatio)(value));
      },
      [currencyRatio]
    );

    // обновляем второе поле с автоматическим пеперасчетом первого
    const updateValuesBySecond = useCallback(
      (value: string) => {
        setSecondCurrencyValue(value);
        setFirstCurrencyValue(floatStringCalc((x) => x * currencyRatio)(value));
      },
      [currencyRatio]
    );

    const [focused, setFocused] = useState<'first' | 'second'>('first');

    useEffect(() => {
      if (focused === 'first') {
        updateValuesByFirst(firstCurrencyValue);
      } else {
        updateValuesBySecond(secondCurrencyValue);
      }
    }, [currencyRatio, focused]); // eslint-disable-line

    /* Сохраняем состояние конвертера в стор при анмаунте */

    useEffect(
      () => (): void => {
        setConverterState({
          firstCurrencyId,
          secondCurrencyId,
          firstCurrencyValue,
          secondCurrencyValue,
          focusedCurrency: 'first',
        });
      },
      [firstCurrencyId, secondCurrencyId, firstCurrencyValue, secondCurrencyValue, setConverterState]
    );

    /* Обработчики */

    // если выбирается противоположная валюта, то меняем местами
    const updateFirstCurrencyId = useCallback(
      (newCurrencyId: string) => {
        if (secondCurrencyId === newCurrencyId) {
          setSecondCurrencyId(firstCurrencyId);
        }
        setFirstCurrencyId(newCurrencyId);
      },
      [secondCurrencyId, firstCurrencyId]
    );

    const updateSecondCurrencyId = useCallback(
      (newCurrencyId: string) => {
        if (firstCurrencyId === newCurrencyId) {
          setFirstCurrencyId(secondCurrencyId);
        }
        setSecondCurrencyId(newCurrencyId);
      },
      [firstCurrencyId, secondCurrencyId]
    );

    const openFirstCurrencyChooseModal = useCallback(() => {
      router.navigate(route.name, { ...route.params, modal: CONVERTER_SELECT_CURRENCY_MODAL_KEY, order: 'first' });
    }, [router, route]);

    const openSecondCurrencyChooseModal = useCallback(() => {
      router.navigate(route.name, { ...route.params, modal: CONVERTER_SELECT_CURRENCY_MODAL_KEY, order: 'second' });
    }, [router, route]);

    const switchClickHandler = useCallback(() => {
      Taptic.impactOccured('light');
      statEvents.push('converter_swap', {});
      setSecondCurrencyId(firstCurrencyId);
      setFirstCurrencyId(secondCurrencyId);
    }, [firstCurrencyId, secondCurrencyId, statEvents]);

    return (
      <>
        <Component
          {...(props as P)}
          firstCurrency={{
            id: firstCurrency.id,
            value: firstCurrencyValue,
            onChange: updateValuesByFirst,
            onClick: openFirstCurrencyChooseModal,
          }}
          onInput={(from, to) => {
            throttledSendStatEvents('converter_input', { from, to });
          }}
          secondCurrency={{
            id: secondCurrency.id,
            value: secondCurrencyValue,
            onChange: updateValuesBySecond,
            onClick: openSecondCurrencyChooseModal,
          }}
          onSwitchClick={switchClickHandler}
          onFocusChange={setFocused}
          focusedCurrency={focused}
        />
        <CurrencySelectModal
          show={route.params.modal === CONVERTER_SELECT_CURRENCY_MODAL_KEY}
          id="select-converter-currency"
          selectedCurrencyId={route.params.order === 'second' ? secondCurrency.id : firstCurrency.id}
          onSearchFocus={() => statEvents.push('search_click', { screen: 'converter_select' })}
          onSearchChange={(text) => throttledSendStatEvents('search', { screen: 'converter_select', text })}
          onClose={(currencyId): void => {
            if (currencyId) {
              Taptic.selectionChanged();
              statEvents.push('convert_select', {
                name: currencyId,
                type: route.params.order === 'second' ? 'to' : 'from',
              });
              route.params.order === 'second' ? updateSecondCurrencyId(currencyId) : updateFirstCurrencyId(currencyId);
              delay(DELAY_AFTER_SELECT).then(() => window.history.back());
            } else {
              window.history.back();
            }
          }}
        />
      </>
    );
  });
}

export default withConverter;
