
function isLogTraceEnabled() {
  return true;
}

export function logTrace(message: string, ...any: any[]) {
  if (isLogTraceEnabled()) {
    console.log(message, ...any);
  }
}
