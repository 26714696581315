import React, { memo, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import CurrenciesSectionDesktop from '../layout/Currencies/CurrenciesSectionDesktop';
import ConverterSectionDesktop from '../layout/Converter/ConverterSectionDesktop';
import FeedSectionDesktop from '../layout/Feed/FeedSectionDesktop';
import { Tabs, TabsItem, HoverTultip, PanelHeaderButton } from '@overrided-vkui';
import { RootRoute } from '../../router/routes';
import { useRoute } from 'react-router5';
import { L } from '../../lang/L';
import Icon24GearOutline from '@vkontakte/icons/dist/24/gear_outline';
import Icon24NotificationOutline from '@vkontakte/icons/dist/24/notification_outline';
import FeedHintDesktop from '../atomic/FeedHintDesktop';
import { withFeedHint, WithFeedHintInjectedProps } from '../hocs/withFeedHint';
import { useSelector } from '../../hooks/useSelector';
import { ContainerProps } from './index';
import c from 'classnames';
import { useAppContext } from '../AppContext';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: '100%',
    alignItems: 'flex-start',
    background: 'var(--steel_gray_60)',
  },
  container_layer: {
    padding: 24,
  },
  main: {
    border: '1px solid #DCE1E6',
    borderRadius: 6,
    background: '#FFFFFF',
    flexGrow: 1,
    minWidth: 0,
    '& .Panel .Panel__in, .Panel::after': {
      backgroundColor: 'transparent',
    },
  },
  mainTabs: {
    position: 'sticky',
    top: 0,
    zIndex: 30,
    overflow: 'visible',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: -1,
      left: -1,
      right: -1,
      bottom: 0,
      zIndex: -1,
      background: 'var(--steel_gray_60)',
    },
    '& .Tabs__in': {
      borderRadius: '6px 6px 0 0',
      background: 'var(--steel_gray_10)',
      border: '1px solid #DCE1E6',
      borderBottom: 0,
      margin: -1,
      marginBottom: 0,
    },
    '& .TabsItem': {
      paddingLeft: 12,
      paddingRight: 12,
      '&:after': {
        left: 12,
        width: 'calc(100% - 24px)',
      },
      '&:first-child': {
        paddingLeft: 24,
        '&:after': {
          left: 24,
          width: 'calc(100% - 36px)',
        },
      },
    },
    '& .TabsItem__in': {
      padding: '16.5px 0 17.5px',
    },
  },
  mainActions: {
    marginLeft: 'auto',
    color: 'var(--steel_gray_200)',
    marginRight: 8,
    display: 'flex',
  },
  aside: {
    flexShrink: 0,
    width: 230,
    marginLeft: 12,
    overflow: 'hidden',
    background: '#FFFFFF',
    border: '1px solid #DCE1E6',
    borderRadius: 6,
    zIndex: 1,
    position: 'sticky',
    top: 0,
  },
});

const ContainerDesktop = memo((props: ContainerProps & WithFeedHintInjectedProps) => {
  const {
    openSettingsModal,
    openCurrencyInfoModal,
    openObserversModal,
    openCurrencyObserversModal,
    hintOpened,
    closeHint,
    showFeed,
  } = props;
  const mc = useStyles();

  const isLayer = useSelector((state) => state.appConfig.isLayer);

  const { statEvents } = useAppContext();

  const { router, route } = useRoute();

  const onSectionChange = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const nextSection = e.currentTarget.dataset.section as RootRoute;
      router.navigate(nextSection);
    },
    [router]
  );

  const activeSection = route.name as RootRoute;

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={c(mc.container, { [mc.container_layer]: isLayer })} ref={containerRef}>
      <div className={mc.main}>
        <Tabs className={mc.mainTabs}>
          <TabsItem
            onClick={onSectionChange}
            selected={activeSection === RootRoute.CURRENCY_MAIN}
            data-section={RootRoute.CURRENCY_MAIN}
          >
            {L.t('tab', { context: 'currencies' })}
          </TabsItem>
          {showFeed && <TabsItem onClick={onSectionChange}
                                 selected={activeSection === RootRoute.FEED}
                                 data-section={RootRoute.FEED}>
            {hintOpened && (
              <FeedHintDesktop
                onDismiss={() => {
                  closeHint();
                  statEvents.push('feed_tooltip_dismiss', {});
                }}
                onOpen={() => {
                  closeHint();
                  router.navigate(RootRoute.FEED);
                  statEvents.push('feed_tooltip_go', {});
                }}
              />
            )}
            {L.t('tab', { context: 'feed' })}
          </TabsItem>}
          <div className={mc.mainActions}>
            <HoverTultip title={L.t('observers_page_title')} placement="bottom" margin={4}>
              <PanelHeaderButton onClick={openObserversModal}>
                <Icon24NotificationOutline />
              </PanelHeaderButton>
            </HoverTultip>
            <HoverTultip title={L.t('settings')} placement="bottom" margin={4}>
              <PanelHeaderButton onClick={openSettingsModal}>
                <Icon24GearOutline />
              </PanelHeaderButton>
            </HoverTultip>
          </div>
        </Tabs>
        <div>
          {activeSection === RootRoute.CURRENCY_MAIN && (
            <CurrenciesSectionDesktop
              route={RootRoute.CURRENCY_MAIN}
              onCurrencyClick={openCurrencyInfoModal}
              onCurrencyObserversClick={openCurrencyObserversModal}
            />
          )}
          {activeSection === RootRoute.FEED && <FeedSectionDesktop />}
        </div>
      </div>
      <div className={mc.aside}>
        <ConverterSectionDesktop />
      </div>
    </div>
  );
});

export default withFeedHint(ContainerDesktop);
