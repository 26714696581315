import React, { useCallback, memo } from 'react';
import Icon28CoinsOutline from '@vkontakte/icons/dist/28/coins_outline';
import Icon28ArticleOutline from '@vkontakte/icons/dist/28/article_outline';
import { ReactComponent as Icon28Currency } from '../../assets/currency_28.svg';
import CurrenciesPanelMobile from '../layout/Currencies/CurrenciesPanelMobile';
import ObserversPanelMobile from '../layout/Observers/ObserversPanelMobile';
import ConverterPanelMobile from '../layout/Converter/ConverterPanelMobile';
import SettingsPanelMobile from '../layout/Settings/SettingsPanelMobile';
import FeedPanelMobile from '../layout/Feed/FeedPanelMobile';
import { View, Epic, Root, Tabbar, TabbarItem } from '@vkontakte/vkui';
import { useRoute } from 'react-router5';
import { RootRoute } from '../../router/routes';
import { L } from '../../lang/L';
import FeedHintMobile from '../atomic/FeedHintMobile';
import { withFeedHint, WithFeedHintInjectedProps } from '../hocs/withFeedHint';
import { ContainerProps } from './index';
import { makeStyles } from '@material-ui/styles';
import { useAppContext } from '../AppContext';

const useStyles = makeStyles({
  tabbar: {
    background: 'var(--background_content)',
    '&:before': {
      top: 0,
      bottom: 'initial',
    },
  },
});

const ContainerMobile = memo((props: ContainerProps & WithFeedHintInjectedProps) => {
  const {
    openCurrencyInfoModal,
    openSelectBaseCurrencyModal,
    openCurrencyObserversModal,
    openLegalModal,
    hintOpened,
    closeHint,
    showFeed,
  } = props;

  const { router, route } = useRoute();

  const routeName = route.name;
  const activeStory = routeName.split('.')[0];

  const { statEvents } = useAppContext();

  /* Прочая навигация */

  const openCurrencySettings = useCallback(() => router.navigate(RootRoute.CURRENCY_SETTINGS), [router]);
  const openCurrencyObservers = useCallback(() => router.navigate(RootRoute.CURRENCY_OBSERVERS), [router]);

  const onStoryChange = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const nextStory = e.currentTarget.dataset.story as RootRoute;
      router.navigate(nextStory, {});
    },
    [router]
  );

  const goBack = useCallback(() => window.history.back(), []);

  const tabbar = (
    <Tabbar shadow itemsLayout={ showFeed ? 'vertical' : 'horizontal'} className={useStyles().tabbar}>
      <TabbarItem
        onClick={onStoryChange}
        selected={activeStory === RootRoute.CURRENCY}
        data-story={RootRoute.CURRENCY}
        text={L.t('tab', { context: 'currencies' })}
      >
        <Icon28CoinsOutline />
      </TabbarItem>
      {showFeed && <TabbarItem
        onClick={onStoryChange}
        selected={activeStory === RootRoute.FEED}
        data-story={RootRoute.FEED}
        text={L.t('tab', { context: 'feed' })}
      >
        {hintOpened && (
          <FeedHintMobile
            size={65}
            offsetY={8}
            onDismiss={() => {
              closeHint();
              statEvents.push('feed_tooltip_dismiss', {});
            }}
            onOpen={() => {
              closeHint();
              router.navigate(RootRoute.FEED);
              statEvents.push('feed_tooltip_go', {});
            }}
          />
        )}
        <Icon28ArticleOutline />
      </TabbarItem>}
      <TabbarItem
        onClick={onStoryChange}
        selected={activeStory === RootRoute.CONVERTER}
        data-story={RootRoute.CONVERTER}
        text={L.t('tab', { context: 'converter' })}
      >
        <Icon28Currency style={{ display: 'block' }} />
      </TabbarItem>
    </Tabbar>
  );

  return (
    <>
      <Epic activeStory={activeStory} tabbar={tabbar}>
        <Root id={RootRoute.CURRENCY} activeView={routeName}>
          <View id={RootRoute.CURRENCY_MAIN} activePanel="main.panel">
            <CurrenciesPanelMobile
              id="main.panel"
              route={RootRoute.CURRENCY_MAIN}
              onSettingsClick={openCurrencySettings}
              onCurrencyClick={openCurrencyInfoModal}
              onObserversClick={openCurrencyObservers}
              onCurrencyObserversClick={openCurrencyObserversModal}
              onLegalClick={openLegalModal}
            />
          </View>
          <View id={RootRoute.CURRENCY_SETTINGS} activePanel="settings.panel">
            <SettingsPanelMobile
              id="settings.panel"
              onBack={goBack}
              openSelectBaseCurrencyModal={openSelectBaseCurrencyModal}
            />
          </View>
          <View id={RootRoute.CURRENCY_OBSERVERS} activePanel="observers.panel">
            <ObserversPanelMobile
              id="observers.panel"
              onBack={goBack}
              openCurrencyObserversModal={openCurrencyObserversModal}
              openCurrencyModal={openCurrencyInfoModal}
            />
          </View>
        </Root>
        <FeedPanelMobile id={RootRoute.FEED} />
        <ConverterPanelMobile id={RootRoute.CONVERTER} />
      </Epic>
    </>
  );
});

export default withFeedHint(ContainerMobile);
