import React, { memo, useState, useEffect } from 'react';
import { Panel, PanelHeader, Div, Placeholder, Spinner, PullToRefresh, Button } from '@vkontakte/vkui';
import { L } from '../../../lang/L';
import { makeStyles } from '@material-ui/styles';
import MiniPost from '../../atomic/MiniPost';
import { withFeed, WithFeedInjectedProps } from '../../hocs/withFeed';
import InfiniteScroll from 'react-infinite-scroll-component';
import { openWallPost } from '../../../utils/openWallPost';
import { useAppContext } from '../../AppContext';

const useStyles = makeStyles({
  panel: {
    '& .Panel__in': {
      background: 'var(--background_light)',
    },
    '&:before': {
      content: '""',
      background: 'var(--background_light)',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
    },
  },
  panelHeader: {
    '& .FixedLayout': {
      position: 'relative',
    },
  },
  scrollContainer: {
    overflow: 'visible!important',
  },
  ptrContainer: {
    '& .PullToRefresh__content': {
      overflow: 'visible!important',
    },
  },
  postsGrid: {
    paddingTop: 4,
    '& > * + *': {
      marginTop: 12,
    },
  },
  statusPlaceholder: {
    background: 'transparent',
    minHeight: 200,
  },
});

interface FeedPanelMobileProps extends WithFeedInjectedProps {
  id: string;
}

const FeedPanelMobile = memo((props: FeedPanelMobileProps) => {
  const { id: panelId, feedError, refetchFeed, feedLoading, loadFeedNextPage, feed } = props;

  const { statEvents } = useAppContext();

  useEffect(() => {
    statEvents.push('navigation_go', { screen: 'feed' });
  }, []); // eslint-disable-line

  const posts = feed || [];

  const mc = useStyles();

  const [prtLoading, setPtrLoading] = useState(false);

  useEffect(() => {
    !feedLoading && setPtrLoading(false);
  }, [feedLoading]);

  return (
    <Panel id={panelId} className={mc.panel}>
      <PanelHeader separator={false} transparent visor={false} className={mc.panelHeader}>
        {L.t('app_name')}
      </PanelHeader>
      <PullToRefresh
        onRefresh={() => {
          refetchFeed();
          setPtrLoading(true);
        }}
        isFetching={prtLoading}
        className={mc.ptrContainer}
      >
        <InfiniteScroll
          dataLength={posts.length}
          className={mc.scrollContainer}
          hasMore={true}
          next={loadFeedNextPage}
          loader={null}
        >
          <Div className={mc.postsGrid}>
            {posts.map((miniPost, index) => (
              <MiniPost
                key={miniPost.id}
                post={miniPost}
                onClick={() => {
                  openWallPost(miniPost.ownerId, miniPost.id);
                  statEvents.push('feed_post_open', { index, owner_id: miniPost.ownerId, post_id: miniPost.id });
                }}
              />
            ))}
          </Div>
          {!prtLoading && (
            <>
              {feedLoading ? (
                <Placeholder className={mc.statusPlaceholder}>
                  <Spinner size="regular" />
                </Placeholder>
              ) : feedError ? (
                <Placeholder
                  className={mc.statusPlaceholder}
                  action={<Button onClick={loadFeedNextPage}>{L.t('common:error_try_again_btn')}</Button>}
                >
                  {L.t('feed_fetch_error')}
                </Placeholder>
              ) : (posts.length === 0) ? (
                <Placeholder className={mc.statusPlaceholder}>
                  {L.t('feed_has_no_posts')}
                </Placeholder>
              ) : null}
            </>
          )}
        </InfiniteScroll>
      </PullToRefresh>
    </Panel>
  );
});

export default withFeed(FeedPanelMobile);
