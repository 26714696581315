import React, { memo, useRef, useState, useEffect } from 'react';
import useWindowSizes from '../../../hooks/useWindowSizes';
import { makeStyles } from '@material-ui/styles';
import { Link } from '@overrided-vkui';
import { L } from '../../../lang/L';
import { formatMiniPostText } from './utils';

const useStyles = makeStyles({
  postText__text: {
    color: 'var(--text_primary)',
    display: '-webkit-box',
    overflow: 'hidden',
    fontSize: 15,
    lineHeight: '20px',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 5,
  },
  postText__emoji: {
    fontSize: '1.1em',
    width: '1.1em',
    display: 'inline-block',
    height: '1.1em',
  },
  postText__more: {
    fontSize: 15,
    lineHeight: '20px',
    pointerEvents: 'none',
    verticalAlign: 'middle',
  },
});

interface MiniPostTextProps {
  text: string;
}

const MiniPostText = memo((props: MiniPostTextProps) => {
  const { text } = props;

  const textRef = useRef<HTMLDivElement>(null);

  const [showMore, setShowMore] = useState(false);
  const width = useWindowSizes().innerWidth;

  useEffect(() => {
    const textEl = textRef.current;

    if (textEl) {
      setShowMore(textEl.scrollHeight > textEl.clientHeight);
    }
  }, [textRef, width]); // eslint-disable-line

  const mc = useStyles();

  return (
    <>
      <div className={mc.postText__text} ref={textRef}>
        {formatMiniPostText(text).map((node, index) => {
          switch (node.type) {
            case 'break':
              return <br key={index} />;
            case 'text':
              return <span key={index}>{node.text}</span>;
            case 'emoji':
              return (
                <span key={index} className={mc.postText__emoji}>
                  {node.emoji}
                </span>
              );
            case 'link':
              return (
                <Link key={index} Component="span">
                  {node.text}
                </Link>
              );
            default:
              return null;
          }
        })}
      </div>
      {showMore && (
        <Link Component="span" className={mc.postText__more}>
          {L.t('common:expand_text')}
        </Link>
      )}
    </>
  );
});

export default MiniPostText;
