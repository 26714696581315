import React, { memo, useEffect } from 'react';
import { ConfigProvider as VKConfigProvider } from '@vkontakte/vkui';
import { useSelector } from '../../../hooks/useSelector';
import vkBridge from '@vkontakte/vk-bridge';
import { isDesktop } from '@overrided-vkui';

/**
 * HOC VKUI ConfigProvider
 * Устанавливает тему приложению и оключает анимацию переходов на десктопе.
 */
const ConfigProvider = memo(({ children }) => {
  const { scheme, viewportHeight, isLayer } = useSelector((state) => ({
    scheme: state.appConfig.scheme,
    viewportHeight: state.appConfig.viewportHeight,
    isLayer: state.appConfig.isLayer,
  }));

  useEffect(() => {
    // Изменяем высоту desktop фрейма под высоту активной вкладки браузера за вычетом шапки и футера ВК
    // (только в том случае, когда приложение открыто не из модалки)

    if (viewportHeight > 0 && isDesktop && !isLayer) {
      const vkInterfaceOffset = 60;
      vkBridge.send('VKWebAppResizeWindow', { height: viewportHeight - vkInterfaceOffset, width: 0 }).catch(() => null);
    }
  }, [viewportHeight, isLayer]);

  return (
    <VKConfigProvider scheme={scheme} isWebView={!isDesktop} transitionMotionEnabled={!isDesktop}>
      {children}
    </VKConfigProvider>
  );
});

export default ConfigProvider;
