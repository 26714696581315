import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  /** Возвращает информацию о текущем пользователе */
  currentUser: CurrentUser;
};

/** Текущий пользователь */
export type CurrentUser = {
  __typename?: 'CurrentUser';
  /** Список наблюдателей за изменениями валюты */
  observers: Array<CurrencyObserver>;
};

/** Наблюдатель за изменениями валюты */
export type CurrencyObserver = {
  __typename?: 'CurrencyObserver';
  /** Идентификатор наблюдателя */
  id: Scalars['String'];
  /** Идентификатор базовой валюты */
  baseCurrencyId: Scalars['String'];
  /** Идентификатор обозреваемой валюты */
  observedCurrencyId: Scalars['String'];
  /** Курс валюты */
  rate: Scalars['Float'];
  /** Больше или меньше значения курса небходимо отслеживать */
  direction: DynamicDirection;
};

/** Направление изменения курса */
export enum DynamicDirection {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Регистрирует пользователя */
  register: Scalars['Boolean'];
  /** Добавляет наблюдатель за изменениями валюты */
  addCurrencyObserver: Scalars['String'];
  /** Удаляет наблюдателей за изменениями валюты */
  removeCurrencyObservers: Scalars['Boolean'];
};


export type MutationAddCurrencyObserverArgs = {
  input: AddCurrencyObserverInput;
};


export type MutationRemoveCurrencyObserversArgs = {
  observerIds: Array<Scalars['String']>;
};

/** Инпут для добавления отслеживания валюты */
export type AddCurrencyObserverInput = {
  /** Идентификатор базовой валюты */
  baseCurrencyId: Scalars['String'];
  /** Идентификатор обозреваемой валюты */
  observedCurrencyId: Scalars['String'];
  /** Значение курса */
  rate: Scalars['Float'];
  /** Направление изменения */
  direction: DynamicDirection;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}


export type RegisterMutationVariables = Exact<{ [key: string]: never; }>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'register'>
);

export type GetObserversQueryVariables = Exact<{ [key: string]: never; }>;


export type GetObserversQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'CurrentUser' }
    & { observers: Array<(
      { __typename?: 'CurrencyObserver' }
      & Pick<CurrencyObserver, 'id' | 'baseCurrencyId' | 'observedCurrencyId' | 'rate' | 'direction'>
    )> }
  ) }
);

export type AddObserverMutationVariables = Exact<{
  input: AddCurrencyObserverInput;
}>;


export type AddObserverMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCurrencyObserver'>
);

export type RemoveObserverMutationVariables = Exact<{
  observerId: Scalars['String'];
}>;


export type RemoveObserverMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCurrencyObservers'>
);


export const RegisterDocument = gql`
    mutation register {
  register
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const GetObserversDocument = gql`
    query getObservers {
  currentUser {
    observers {
      id
      baseCurrencyId
      observedCurrencyId
      rate
      direction
    }
  }
}
    `;

/**
 * __useGetObserversQuery__
 *
 * To run a query within a React component, call `useGetObserversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObserversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObserversQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetObserversQuery(baseOptions?: Apollo.QueryHookOptions<GetObserversQuery, GetObserversQueryVariables>) {
        return Apollo.useQuery<GetObserversQuery, GetObserversQueryVariables>(GetObserversDocument, baseOptions);
      }
export function useGetObserversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetObserversQuery, GetObserversQueryVariables>) {
          return Apollo.useLazyQuery<GetObserversQuery, GetObserversQueryVariables>(GetObserversDocument, baseOptions);
        }
export type GetObserversQueryHookResult = ReturnType<typeof useGetObserversQuery>;
export type GetObserversLazyQueryHookResult = ReturnType<typeof useGetObserversLazyQuery>;
export type GetObserversQueryResult = Apollo.QueryResult<GetObserversQuery, GetObserversQueryVariables>;
export const AddObserverDocument = gql`
    mutation addObserver($input: AddCurrencyObserverInput!) {
  addCurrencyObserver(input: $input)
}
    `;
export type AddObserverMutationFn = Apollo.MutationFunction<AddObserverMutation, AddObserverMutationVariables>;

/**
 * __useAddObserverMutation__
 *
 * To run a mutation, you first call `useAddObserverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddObserverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addObserverMutation, { data, loading, error }] = useAddObserverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddObserverMutation(baseOptions?: Apollo.MutationHookOptions<AddObserverMutation, AddObserverMutationVariables>) {
        return Apollo.useMutation<AddObserverMutation, AddObserverMutationVariables>(AddObserverDocument, baseOptions);
      }
export type AddObserverMutationHookResult = ReturnType<typeof useAddObserverMutation>;
export type AddObserverMutationResult = Apollo.MutationResult<AddObserverMutation>;
export type AddObserverMutationOptions = Apollo.BaseMutationOptions<AddObserverMutation, AddObserverMutationVariables>;
export const RemoveObserverDocument = gql`
    mutation removeObserver($observerId: String!) {
  removeCurrencyObservers(observerIds: [$observerId])
}
    `;
export type RemoveObserverMutationFn = Apollo.MutationFunction<RemoveObserverMutation, RemoveObserverMutationVariables>;

/**
 * __useRemoveObserverMutation__
 *
 * To run a mutation, you first call `useRemoveObserverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveObserverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeObserverMutation, { data, loading, error }] = useRemoveObserverMutation({
 *   variables: {
 *      observerId: // value for 'observerId'
 *   },
 * });
 */
export function useRemoveObserverMutation(baseOptions?: Apollo.MutationHookOptions<RemoveObserverMutation, RemoveObserverMutationVariables>) {
        return Apollo.useMutation<RemoveObserverMutation, RemoveObserverMutationVariables>(RemoveObserverDocument, baseOptions);
      }
export type RemoveObserverMutationHookResult = ReturnType<typeof useRemoveObserverMutation>;
export type RemoveObserverMutationResult = Apollo.MutationResult<RemoveObserverMutation>;
export type RemoveObserverMutationOptions = Apollo.BaseMutationOptions<RemoveObserverMutation, RemoveObserverMutationVariables>;