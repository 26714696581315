import React, { memo, useCallback } from 'react';
import { ModalPage, ModalPageHeader, withModalPageOverlayWrapper } from '@overrided-vkui';
import ObserversContent from './_ObserversContent';
import { L } from '../../../lang/L';
import { useAppContext } from 'src/components/AppContext';

interface ObserversModalProps {
  id: string;
  onClose(): void;
  openCurrencyObserversModal(currencyId: string): void;
  openCurrencyModal(currencyId: string): void;
}

const ObserversModal = memo((props: ObserversModalProps) => {
  const { id: modalId, onClose, openCurrencyObserversModal, openCurrencyModal } = props;

  const { statEvents } = useAppContext();

  const wrappedOpenCurrencyObserversModal = useCallback(
    (currencyId: string) => {
      openCurrencyObserversModal(currencyId);
      statEvents.push('go_notifications', { name: currencyId, screen: 'notification_list' });
    },
    [openCurrencyObserversModal, statEvents]
  );

  return (
    <ModalPage
      id={modalId}
      onClose={onClose}
      fullHeight
      header={<ModalPageHeader>{L.t('observers_page_title')}</ModalPageHeader>}
    >
      <ObserversContent
        openCurrencyObserversModal={wrappedOpenCurrencyObserversModal}
        openCurrencyModal={openCurrencyModal}
      />
    </ModalPage>
  );
});

export default withModalPageOverlayWrapper(ObserversModal);
