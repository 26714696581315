import React, { memo, useMemo, useState } from 'react';
import { Currency } from '../../types/currency';
import { L } from '../../lang/L';
import { List } from '@vkontakte/vkui';
import { Placeholder } from '@overrided-vkui';
import CurrencyListItem from '../atomic/CurrencyListItem';
import InfiniteScroll from 'react-infinite-scroller';
import Fuse from 'fuse.js';
import { useSelector } from '../../hooks/useSelector';
import { getCurrencies } from '../../redux/selectors';

interface CurrenciesSearchListProps {
  search: string;
  skipBaseCurrency?: boolean;
  sorted?: boolean;
  onCurrencyClick?(currencyId: string): void;
  renderAfter?(currency: Currency): React.ReactNode;
  className?: string;
  scrollParentEl?: HTMLElement;
}

const INFINITE_PAGE_SIZE = 15;

const CurrenciesSearchList: React.FC<CurrenciesSearchListProps> = memo((props) => {
  const { search, scrollParentEl = null, renderAfter, className, onCurrencyClick, skipBaseCurrency, sorted } = props;

  const currencies = useSelector(getCurrencies({ skipBaseCurrency, sorted }));

  const q = search.trim();

  const foundCurrencies = useMemo(() => {
    return Boolean(q)
      ? new Fuse(currencies, { keys: ['id', 'title'], threshold: 0.3 }).search(q)
      : currencies.map((item, refIndex) => ({ item, refIndex }));
  }, [q, currencies]);

  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = Math.ceil(foundCurrencies.length / INFINITE_PAGE_SIZE);

  return (
    <List className={className}>
      <InfiniteScroll
        pageStart={1}
        hasMore={currentPage < pageLimit}
        useWindow={!scrollParentEl}
        getScrollParent={() => scrollParentEl}
        loadMore={setCurrentPage}
        threshold={750}
      >
        {foundCurrencies.length === 0 && <Placeholder>{L.t('not_found')}</Placeholder>}
        {foundCurrencies.slice(0, currentPage * INFINITE_PAGE_SIZE).map(({ item: currency }) => (
          <CurrencyListItem
            key={currency.id}
            onClick={onCurrencyClick && ((): void => onCurrencyClick(currency.id))}
            after={renderAfter && renderAfter(currency)}
            id={currency.id}
            title={currency.title}
          />
        ))}
      </InfiniteScroll>
    </List>
  );
});

export default CurrenciesSearchList;
