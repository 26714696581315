/**
 * Список полей, которые могут храниться в хранилище bridge.
 */
export enum StorageField {
  BaseCurrencyId = 'base-currency-id',
  SelectedCurrencyIds = 'selected-currency-ids',
  ApplicationVisited = 'application-visited',
  AddToHomeScreenSuggestShown = 'add-to-home-screen-suggest-shown',
  FeedHintShown = 'feed-hint-shown',
}

/**
 * Описывает то, какое поле имеет какой тип данных в хранилище bridge.
 */
export interface StorageValuesMap {
  [StorageField.BaseCurrencyId]: string;
  [StorageField.SelectedCurrencyIds]: string[];
  [StorageField.ApplicationVisited]: boolean;
  [StorageField.AddToHomeScreenSuggestShown]: boolean;
  [StorageField.FeedHintShown]: boolean;
}

/**
 * Возвращает тип данных для указанного поля хранилища.
 */
export type StorageValueType<T extends StorageField> = StorageValuesMap[T];
