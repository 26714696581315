import React, { memo, useRef, useEffect } from 'react';
import * as d3 from 'd3';

const MIN_POINTS_COUNT = 2;
const LARGE_CHART_WIDTH = 100;

interface RateChartPoint {
  date: number;
  rate: number;
}

interface MiniRateChartProps {
  points: RateChartPoint[];
  className?: string;
  height: number;
  width: number;
}

const MiniRateChart: React.FC<MiniRateChartProps> = memo((props) => {
  const { points, className, height, width } = props;

  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const svgEl = svgRef.current;

    if (!svgEl || points.length < MIN_POINTS_COUNT) return;

    const graph = d3.select(svgEl).text('');

    const dates = points.map((point) => point.date);
    const rates = points.map((point) => point.rate);

    const minXValue = Math.min.apply(null, dates);
    const maxXValue = Math.max.apply(null, dates);
    const minYValue = Math.min.apply(null, rates);
    const maxYValue = Math.max.apply(null, rates);

    const xAxisScale = d3.scaleLinear().range([0, svgEl.clientWidth]).domain([minXValue, maxXValue]);

    // диапазон значений по оси Y увеличиваем сверху и снизу на 20%,
    // чтобы график не упирался в верхнюю и нижнюю границы
    const valueYDelta = maxYValue - minYValue;

    const yAxisScale = d3
      .scaleLinear()
      .range([0, svgEl.clientHeight])
      .domain([maxYValue + valueYDelta * 0.2, minYValue - valueYDelta * 0.2]);

    // рисуем пунктирную линию текущего курса

    const isLargeRate = svgEl.clientWidth > LARGE_CHART_WIDTH;

    const currentYPosition = yAxisScale(points[points.length - 1].rate) || 0;

    graph
      .append('line')
      .attr('x1', 0)
      .attr('y1', currentYPosition)
      .attr('x2', '100%')
      .attr('y2', currentYPosition)
      .attr('stroke', 'var(--steel_gray_120)')
      .attr('stroke-width', isLargeRate ? 2 : 1)
      .attr('stroke-dasharray', isLargeRate ? '20 10' : '8 4')
      .attr('stroke-linejoin', 'round');

    // рисуем график

    const line = d3
      .line<RateChartPoint>()
      .x((d) => xAxisScale(d.date) || 0)
      .y((d) => yAxisScale(d.rate) || 0);

    graph
      .append('path')
      .datum(points)
      .attr('stroke', 'var(--accent)')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 2)
      .style('pointer-events', `none`)
      .attr('d', line)
      .attr('fill', 'transparent');
  }, [points]);

  return points.length >= MIN_POINTS_COUNT ? (
    <svg className={className} ref={svgRef} style={{ height, width }} />
  ) : null;
});

export default MiniRateChart;
