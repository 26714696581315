import React, { memo, useState, useEffect, useRef } from 'react';
import { Panel, PanelHeaderSubmit } from '@vkontakte/vkui';
import { PanelHeader } from '@overrided-vkui';
import { L } from '../../../lang/L';
import SettingsContent from './_SettingsContent';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  content: {
    '& .Search--ios': {
      top: 'calc(var(--panelheader_height_ios) + var(--safe-area-inset-top))',
    },
    '& .Search--android': {
      top: 'calc(var(--panelheader_height_android) + var(--safe-area-inset-top))',
    },
  },
});

interface SettingsPanelMobileProps {
  id: string;
  onBack(): void;
  openSelectBaseCurrencyModal(): void;
}

const SettingsPanelMobile: React.FC<SettingsPanelMobileProps> = memo((props) => {
  const { id: panelId, onBack, openSelectBaseCurrencyModal } = props;

  const [searchValue, setSearchValue] = useState('');

  const initied = useRef(false);

  useEffect(() => {
    if (initied.current) {
      window.scrollTo(0, 0);
    } else {
      initied.current = true;
    }
  }, [searchValue]); // eslint-disable-line

  return (
    <Panel id={panelId}>
      <PanelHeader
        left={<PanelHeaderSubmit onClick={onBack}>{L.t('common:done')}</PanelHeaderSubmit>}
        separator={false}
      >
        {L.t('settings')}
      </PanelHeader>
      <SettingsContent
        className={useStyles().content}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        openSelectBaseCurrencyModal={openSelectBaseCurrencyModal}
      />
    </Panel>
  );
});

export default SettingsPanelMobile;
