import React, { memo } from 'react';
import c from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as IncreaseSvg } from '../../assets/increase.svg';
import { formatPercent, parseDelta, formatRate } from '../../utils/formatter';
import { getPlatformClassName } from '@overrided-vkui';

const useStyle = makeStyles({
  dynamic: {
    color: 'var(--content_placeholder_icon)',
    '& $dynamic__icon': {
      display: 'none',
    },
    '&--desktop$dynamic_decrease': {
      color: 'var(--red)',
    },
    '&--desktop$dynamic_increase': {
      color: 'var(--green_dark)',
    },
  },
  dynamic__icon: {
    marginRight: '.33em',
    marginBottom: '-.075em',
    height: '.9em',
    width: '.9em',
  },
  dynamic_decrease: {
    color: 'var(--dynamic_red)',
    '& $dynamic__icon': {
      transform: 'rotate(180deg)',
      display: 'inline-block',
      marginBottom: '-.1em',
    },
  },
  dynamic_increase: {
    color: 'var(--dynamic_green)',
    '& $dynamic__icon': {
      display: 'inline-block',
    },
  },
});

type RateDynamicProps = React.HTMLAttributes<HTMLSpanElement> & {
  rate: number;
  prevRate: number;
  symbol: string;
};

const RateDelta: React.FC<RateDynamicProps> = memo((props) => {
  const { prevRate, rate, className, symbol, ...restProps } = props;

  const [absoluteValue, direction] = parseDelta(rate - prevRate);
  const percentText = rate ? `(${formatPercent(absoluteValue / rate)})` : '';

  const mc = useStyle();

  return (
    <span
      className={c(
        getPlatformClassName(mc.dynamic),
        { [mc.dynamic_decrease]: direction === 'decrease', [mc.dynamic_increase]: direction === 'increase' },
        className
      )}
      {...restProps}
    >
      <IncreaseSvg className={mc.dynamic__icon} />
      {`${formatRate(absoluteValue, symbol)} ${percentText}`}
    </span>
  );
});

export default RateDelta;
