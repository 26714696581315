import { Separator } from '@vkontakte/vkui';
import { SeparatorProps } from '@vkontakte/vkui/dist/components/Separator/Separator';
import styled from '@material-ui/styles/styled';

export default styled(Separator)({
  '&:not(.Separator--wide) .Separator__in': {
    marginLeft: '24px!important',
    marginRight: '24px!important',
    backgroundColor: 'var(--steel_gray_80)',
  },
}) as React.FC<SeparatorProps>;
