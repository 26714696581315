import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { L } from '../lang/L';

dayjs.extend(utc);

/************************* Валюты ****************************/

const MIN_DIGITS_AFTER_DOT = 2;
const SIGNIFICANT_DIGITS_AFTER_DOT = 2;
const MAX_DIGITS_AFTER_DOT = 8;

/**
 * Возвращает количество знаков после запятой, которое необходимо отображать
 * @param number значение курса
 */
export const getDigitsAfterDotLimit = (number: string) => {
  const [integerPart, realPart = ''] = number.split('.');

  if (Number(integerPart) !== 0) {
    return MIN_DIGITS_AFTER_DOT;
  }

  let digitsAfterDot = 0;

  while (digitsAfterDot < realPart.length && realPart[digitsAfterDot] === '0') {
    digitsAfterDot += 1;
  }

  digitsAfterDot += SIGNIFICANT_DIGITS_AFTER_DOT;

  while (digitsAfterDot > 0 && realPart[digitsAfterDot] === '0') {
    digitsAfterDot -= 1;
  }

  return Math.max(Math.min(digitsAfterDot, MAX_DIGITS_AFTER_DOT), MIN_DIGITS_AFTER_DOT);
};

/**
 * Форматирует число в строковом представлении с добавлением запятой и отступов между тысячными
 * @param number число представлении
 */
export const prettifyRateValue = (number: string): string => {
  const parts = number.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, L.t('config:numDelS'));

  return parts.join(L.t('config:numDec'));
};

/**
 * Форматирует значение курса валюты
 * @param number значение курса валюты
 * @param symbol символ курса валюты
 */
export const formatRate = (number: number, symbol: string): string => {
  const digitsAfterDot = getDigitsAfterDotLimit(String(number));

  return L.t('rate', { amount: prettifyRateValue(number.toFixed(digitsAfterDot)), symbol });
};

/**
 * Форматирует число к строке с процентом
 * @param ratio число в интервале от 0 до 1
 */
export const formatPercent = (ratio: number): string => {
  const number = ratio * 100;
  const digitsAfterDot = getDigitsAfterDotLimit(String(number));

  return `${prettifyRateValue(number.toFixed(digitsAfterDot))}%`;
};

/**
 * Вовращает функцию-форматтер для значений вертикальной оси графика в зависимости от размерности данных
 * @param minRate нижняя граница курса
 * @param maxRate верхняя граница курса
 */
export const formatRateAxisLabel = (minRate: number, maxRate: number) => {
  const [minRateInteger, minRateReal = '0'] = String(minRate).split('.');
  const [maxRateInteger, maxRateReal = '0'] = String(maxRate).split('.');
  const integerDelta = Number(maxRateInteger) - Number(minRateInteger);

  let digitsAfterDot = 0;

  if (integerDelta === 0) {
    while (
      minRateReal.length > digitsAfterDot &&
      maxRateReal.length > digitsAfterDot &&
      minRateReal[digitsAfterDot] === maxRateReal[digitsAfterDot]
    ) {
      digitsAfterDot++;
    }
    digitsAfterDot = Math.min(digitsAfterDot + SIGNIFICANT_DIGITS_AFTER_DOT, MAX_DIGITS_AFTER_DOT);
  } else if (integerDelta < 5) {
    digitsAfterDot = 2;
  } else if (integerDelta < 10) {
    digitsAfterDot = 1;
  }

  return (rate: number) => prettifyRateValue(rate.toFixed(digitsAfterDot));
};

/********************** Дата и время *************************/

/**
 * Возврщает дату в формате YYYY-MM-DD в UTC
 * @param date дата
 */
export const formatToUTCDate = (date: Date): string => {
  return dayjs(date).utc().format('YYYY-MM-DD');
};

/**
 * Возврщает строчку с датой для лэйбла при наведении на график
 * @param date дата в ISO формате в миллисекундах
 */
export const formatToLabelTime = (date: number): string => {
  return L.format.date(date, { alwaysDate: true });
};

const DAY = 60 * 60 * 24;
const WEEK = 7 * DAY;
const MONTH = 4 * WEEK;
const QUARTAL = 3 * MONTH;
const YEAR = 4 * QUARTAL;

/**
 * Форматирует значение даты для горизонтальной оси графика в зависимости от размерности данных
 * @param minDate нижняя граница даты в миллисекундах ISO
 * @param maxDate верхняя граница даты в миллисекундах ISO
 */
export const formatDateAxisLabel = (minDate: number, maxDate: number) => (date: number) => {
  const secondsDelta = (maxDate - minDate) / 1000;
  const dateObject = dayjs(date);

  if (secondsDelta < 2 * DAY) {
    return L.format.time(date);
  } else if (secondsDelta < WEEK) {
    return `${L.format.weekDay(dateObject.day(), { short: true })} ${L.format.time(date)}`;
  } else if (secondsDelta < QUARTAL) {
    return L.format.date(date, { shortMonth: true, alwaysDate: true, alwaysShort: true });
  } else if (secondsDelta < YEAR) {
    return L.format.monthOf(dateObject.month(), { short: true });
  }

  return `${L.format.monthOf(dateObject.month(), { short: true })}`;
};

/********************** Misc *************************/

type DeltaDirection = 'default' | 'increase' | 'decrease';

export const parseDelta = (delta: number) => {
  let direction: DeltaDirection = 'default';

  if (delta > 0) {
    direction = 'increase';
  } else if (delta < 0) {
    direction = 'decrease';
  }
  return [Math.abs(delta), direction] as const;
};

export function formatMediaDuration(seconds: number): string {
  const sec = seconds % 60;
  const min = Math.floor(seconds / 60) % 60;
  const hours = Math.floor(seconds / 60 / 60);

  return `${hours > 0 ? hours + ':' : ''}${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
}

export function fomatPostTime(datetime: number): string {
  const targetDatetime = dayjs(datetime);
  const dayDiff = targetDatetime.hour(0).minute(0).second(0).diff(dayjs().format('YYYY-MM-DD'), 'day');
  const isToday = dayDiff === 0;

  return isToday ? L.format.time(datetime) : L.format.date(datetime, { shortMonth: true });
}
