import { VkClientInstance } from './lib/vkClient/types';
import fallbackSuggest from "../assets/dictionaries/notificationSuggest.json";
import {logTrace} from "./logging";

export interface NotificationSuggest {
  currencies: string[];
}

/**
 * Получает список рекомендованных валют, чтобы включить уведомления
 */
export const getObserverSuggestedCurrencies = (vkClient: VkClientInstance): Promise<NotificationSuggest> => {
  logTrace("Start getObserverSuggestedCurrencies");
  return vkClient
    .call('execute.getNotificationSuggest')
    .then(res => {
      logTrace("Done getObserverSuggestedCurrencies");
      return res;
    })
    .catch(e => {
      console.warn("Fail load execute.getNotificationSuggest use default suggest", e)
      return fallbackSuggest;
    });
};

export async function getDefaultSuggested(): Promise<NotificationSuggest> {
  return fallbackSuggest
}
