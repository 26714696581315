interface PostLinkNode {
  type: 'link';
  text: string;
}

interface PostTextNode {
  type: 'text';
  text: string;
}

interface PostBreakNode {
  type: 'break';
}

interface PostEmojiNode {
  type: 'emoji';
  emoji: string;
}

type PostNode = PostLinkNode | PostTextNode | PostBreakNode | PostEmojiNode;

const BREAK_REGEXP = /\n/gi;
const HASHTAG_REGEXP = /#[a-zA-Zа-яА-ЯёЁ][0-9a-zA-Zа-яА-ЯёЁ]*(@[a-zA-Z][0-9a-zA-Z_]+)?/g;
const MENTION_REGEXP = /\[.+?\|.+?\]/g;
// eslint-disable-next-line
const URL_REGEXP = /(https?:\/\/)?(www\.)?[a-zA-Z0-9]+\.[a-zA-Z]{2,5}(\/[^\s]*)?/g;
// eslint-disable-next-line
const EMOJI_REGEXP = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu;

const COMMON_REGEXP = new RegExp(
  [BREAK_REGEXP.source, MENTION_REGEXP.source, HASHTAG_REGEXP.source, URL_REGEXP.source, EMOJI_REGEXP.source].join('|'),
  'ug'
);

const SEPARATOR = String.fromCharCode(2);

export function formatMiniPostText(text: string): PostNode[] {
  const textNodes = text.replace(COMMON_REGEXP, SEPARATOR).split(SEPARATOR);
  const regexpNodes = text.match(COMMON_REGEXP) || [];
  const result: PostNode[] = [];

  textNodes.forEach((textNode, index) => {
    result.push({ type: 'text', text: textNode });
    const regexpNode = regexpNodes[index];

    if (!regexpNode) return;

    if (regexpNode.match(BREAK_REGEXP)) {
      result.push({ type: 'break' });
    } else if (regexpNode.match(HASHTAG_REGEXP)) {
      result.push({ type: 'link', text: regexpNode });
    } else if (regexpNode.match(MENTION_REGEXP)) {
      const match = regexpNode.match(/\[.+\|(.+)\]/);
      match && match[1] && result.push({ type: 'link', text: match[1] });
    } else if (regexpNode.match(URL_REGEXP)) {
      result.push({ type: 'link', text: regexpNode });
    } else if (regexpNode.match(EMOJI_REGEXP)) {
      result.push({ type: 'emoji', emoji: regexpNode });
    }
  });

  return result;
}
