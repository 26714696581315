import React, { memo, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Spinner from '@vkontakte/vkui/dist/components/Spinner/Spinner';
import { getPlatformClassName } from '@overrided-vkui';
import vkBridge from '@vkontakte/vk-bridge';
import { useSelector } from '../../hooks/useSelector';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&--mobile': {
      background: '#4BC650',
      '& $spinner': {
        color: 'rgba(0, 0, 0, 0.36)',
      },
    },
  },
  spinner: {
    color: 'var(--activity_indicator_tint)',
  },
});

/**
 * Вью которая отображается в случае, когда приложение загружается.
 * @type {React.NamedExoticComponent<object>}
 */
const AppLoadingView = memo(() => {
  const mc = useStyles();
  const isDarkTheme = useSelector((state) => ['client_dark', 'space_gray'].includes(state.appConfig.scheme));

  useEffect(() => {
    vkBridge
      .send('VKWebAppSetViewSettings', { action_bar_color: '#4BC650', status_bar_style: 'light' })
      .catch(() => null);

    return () => {
      vkBridge
        .send(
          'VKWebAppSetViewSettings',
          isDarkTheme
            ? { action_bar_color: '#19191A', status_bar_style: 'light' }
            : { action_bar_color: '#FFFFFF', status_bar_style: 'dark' }
        )
        .catch(() => null);
    };
  }, [isDarkTheme]);

  return (
    <div className={getPlatformClassName(mc.root)}>
      <Spinner size={'large'} className={mc.spinner} />
    </div>
  );
});

export default AppLoadingView;
