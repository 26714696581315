import { ofType, unionize, UnionOf } from 'unionize';
import { unionizeConfig } from '../utils';
import { RelativeCurrency, CurrencySeries } from '../../types/currency';
import { MiniPost } from '../../types/miniPost';

type CurrencyDictionary = Record<string, RelativeCurrency>;

type ConverterState = {
  firstCurrencyId: string;
  secondCurrencyId: string;
  firstCurrencyValue: string;
  secondCurrencyValue: string;
  focusedCurrency: 'first' | 'second';
};

export interface CurrencyState {
  // Словарь данных валют
  currencyById: CurrencyDictionary;
  // Список всех идентификаторов валют
  currencyIds: string[];
  // Список всех идентификаторов валют, где первыми расположены указанные
  sortedCurrencyIds: string[];
  // Состояние конвертера
  converterState: ConverterState | null;
  // Записи в тематической ленте
  feed: MiniPost[];
  // Рекомендации валют для включения уведомлений
  observerSuggestedCurrencyIds: string[];
}

export const currencyActions = unionize(
  {
    // Устанавливает значение для currencyById и currencyIds
    setCurrencies: ofType<{ currencies: RelativeCurrency[] }>(),
    // Устанавливает переданные валюты первыми в sortCurrencyIds
    setHeadCurrencies: ofType<string[]>(),
    // Обновляет курсы валют в currencyById
    updateCurrencies: ofType<{ id: string; rate: number; prevRate: number }[]>(),
    // Обновляет серию данных валюты в currencyById
    setCurrencySeries: ofType<{ currencyId: string; series: CurrencySeries }[]>(),
    // Обновляет состояние конвертера
    setConverterState: ofType<ConverterState>(),
    // Перезаписывает записи в ленте
    setFeed: ofType<MiniPost[]>(),
    // Добавляет записи в конец ленты
    pushFeed: ofType<MiniPost[]>(),
    // Обновляет рекомендации валют для включения уведомлений
    setObserverSuggestedCurrencyIds: ofType<string[]>(),
  },
  unionizeConfig
);

type CurrencyAction = UnionOf<typeof currencyActions>;

const initialState: CurrencyState = {
  currencyById: {},
  currencyIds: [],
  sortedCurrencyIds: [],
  converterState: null,
  feed: [],
  observerSuggestedCurrencyIds: [],
};

export function currencyReducer(state: CurrencyState = initialState, action: CurrencyAction): CurrencyState {
  return currencyActions.match<CurrencyState>(action, {
    setCurrencies: ({ currencies }) => ({
      ...state,
      currencyIds: currencies.map((currency) => currency.id),
      currencyById: currencies.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {} as CurrencyDictionary),
    }),
    setHeadCurrencies: (headCurrencyIds) => ({
      ...state,
      sortedCurrencyIds: [...headCurrencyIds, ...state.currencyIds.filter((id) => !headCurrencyIds.includes(id))],
    }),
    updateCurrencies: (currencies) => ({
      ...state,
      currencyById: currencies.reduce(
        (acc, curr) => {
          if (acc[curr.id]) {
            acc[curr.id] = { ...acc[curr.id], rate: curr.rate, prevRate: curr.prevRate };
          }
          return acc;
        },
        { ...state.currencyById }
      ),
    }),
    setCurrencySeries: (currencySeries) => ({
      ...state,
      currencyById: currencySeries.reduce(
        (acc, { currencyId, series }) => {
          if (currencyId in acc) {
            acc[currencyId] = { ...acc[currencyId], series };
          }
          return acc;
        },
        { ...state.currencyById }
      ),
    }),
    setConverterState: (converterState) => ({ ...state, converterState }),
    setFeed: (feed) => ({ ...state, feed }),
    pushFeed: (feed) => ({ ...state, feed: state.feed.concat(feed) }),
    setObserverSuggestedCurrencyIds: (observerSuggestedCurrencyIds) => ({ ...state, observerSuggestedCurrencyIds }),
    default: () => state,
  });
}
