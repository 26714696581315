import React, { memo, useCallback } from 'react';
import Container from '../Container';
import { useRoute } from 'react-router5';
import TermsModal from '../layout/TermsModal';
import CurrencyInfoModal from '../layout/CurrencyInfoModal';
import SelectBaseCurrencyModal from '../layout/SelectBaseCurrencyModal';
import SettingsModal from '../layout/Settings/SettingsModal';
import ObserversModal from '../layout/Observers/ObserversModal';
import CurrencyObserversModal from '../layout/CurrencyObserversModal';
import { useAppContext } from '../AppContext';
import { useNotifications } from '../../hooks/useNotifications';

export enum Modal {
  CurrencyInfo = 'currency',
  Terms = 'terms',
  SelectBaseCurrency = 'select-base-currency',
  Settings = 'settings',
  CurrencyObservers = 'currency-observers',
  Observers = 'observers',
}

export const App = memo(() => {
  /* Общие вещи для модалок */

  const { route, router } = useRoute();

  const { statEvents } = useAppContext();

  const goBack = useCallback(() => window.history.back(), []);
  const [requestNotificationPermission] = useNotifications();

  const modalCurrencyId = route.params.currency;

  /* Модалка с информацией о валюте */

  const isCurrencyInfoModalOpened = Boolean(route.params.modal === Modal.CurrencyInfo && route.params.currency);

  const openCurrencyInfoModal = useCallback(
    (currency: string) => {
      statEvents.push('item_click', { name: currency });
      router.navigate(route.name, { ...route.params, modal: Modal.CurrencyInfo, currency });
    },
    [router, route, statEvents]
  );

  /* Модалка с положением */

  const isTermsModalOpened = route.params.modal === Modal.Terms;

  const termsWithBackButton = Boolean(route.params.from);

  const openTermsModal = useCallback(
    () => router.navigate(route.name, { ...route.params, modal: Modal.Terms, from: 'anywhere' }),
    [router, route]
  );

  /* Модалка с выбором основной валюты */

  const isSelecteBaseCurrencyModalOpened = route.params.modal === Modal.SelectBaseCurrency;

  const openSelectBaseCurrencyModal = useCallback(() => {
    router.navigate(route.name, { ...route.params, modal: Modal.SelectBaseCurrency });
  }, [router, route]);

  /* Модалка с настройками */

  const isSettingsModalOpened = route.params.modal === Modal.Settings;

  const openSettingsModal = useCallback(() => router.navigate(route.name, { ...route.params, modal: Modal.Settings }), [
    router,
    route,
  ]);

  /* Модалка со всеми обсерверами */

  const isObserversModalOpened = route.params.modal === Modal.Observers;

  const openObserversModal = useCallback(
    () => router.navigate(route.name, { ...route.params, modal: Modal.Observers }),
    [router, route]
  );

  /* Модалка с обсерверами конкретной валюты */

  const isCurrencyObserversModalOpened = Boolean(
    route.params.modal === Modal.CurrencyObservers && route.params.currency
  );

  const openCurrencyObserversModal = useCallback(
    (currency: string) => {
      return requestNotificationPermission()
        .then((result) => {
          if (result) {
            router.navigate(route.name, { ...route.params, modal: Modal.CurrencyObservers, currency });
          }
        })
        .catch(() => null);
    },
    [router, route, requestNotificationPermission]
  );

  /* Рендер */

  return (
    <>
      <Container
        openCurrencyInfoModal={openCurrencyInfoModal}
        openCurrencyObserversModal={openCurrencyObserversModal}
        openSelectBaseCurrencyModal={openSelectBaseCurrencyModal}
        openSettingsModal={openSettingsModal}
        openObserversModal={openObserversModal}
        openLegalModal={openTermsModal}
      />
      <TermsModal id={Modal.Terms} show={isTermsModalOpened} onClose={goBack} withBackButton={termsWithBackButton} />
      <CurrencyInfoModal
        id={Modal.CurrencyInfo}
        show={isCurrencyInfoModalOpened}
        onClose={goBack}
        currencyId={modalCurrencyId}
        openTerms={openTermsModal}
        openObservers={openCurrencyObserversModal}
      />
      <SelectBaseCurrencyModal id={Modal.SelectBaseCurrency} show={isSelecteBaseCurrencyModalOpened} onClose={goBack} />
      <SettingsModal
        id={Modal.Settings}
        show={isSettingsModalOpened}
        onClose={goBack}
        openSelectBaseCurrencyModal={openSelectBaseCurrencyModal}
      />
      <CurrencyObserversModal
        id={Modal.CurrencyObservers}
        show={isCurrencyObserversModalOpened}
        onClose={goBack}
        currencyId={modalCurrencyId}
      />
      <ObserversModal
        id={Modal.Observers}
        onClose={goBack}
        openCurrencyObserversModal={openCurrencyObserversModal}
        openCurrencyModal={openCurrencyInfoModal}
        show={isObserversModalOpened}
      />
    </>
  );
});
