import { ReduxState } from './types';
import { StorageField } from '../types';
import { RelativeCurrency, Currency, CurrencyObserver } from '../types/currency';

/**
 * Возвращает добавленные валюты
 */
export const getSelectedCurrencies = () => (state: ReduxState): RelativeCurrency[] => {
  const selectedCurrencyIds = state.storage[StorageField.SelectedCurrencyIds] || [];
  const currencyById = state.currency.currencyById;

  return selectedCurrencyIds.map((currencyId) => currencyById[currencyId]).filter(Boolean) as RelativeCurrency[];
};

interface GetGlobalCurrenciesOptions {
  // Не возвращать основную валюту
  skipBaseCurrency?: boolean;
  // Сначала показывать валюты, выбранные пользователем
  sorted?: boolean;
}

/**
 * Возвращает все валюты
 * @param options настройки
 */
export const getCurrencies = (options: GetGlobalCurrenciesOptions = {}) => (state: ReduxState): Currency[] => {
  const { sorted, skipBaseCurrency } = options;
  const currencyIds = sorted ? state.currency.sortedCurrencyIds : state.currency.currencyIds;

  if (!currencyIds) return [];

  const currencyById = state.currency.currencyById;

  const result = currencyIds.map((currencyId) => currencyById[currencyId]).filter(Boolean) as Currency[];

  if (skipBaseCurrency) {
    const baseCurrencyId = state.storage[StorageField.BaseCurrencyId];
    return result.filter((currency) => currency.id !== baseCurrencyId);
  }

  return result;
};

/**
 * Возвращает валюту по ее идентификатору
 * @param currencyId идентификатор валюты
 */
export const getCurrency = (currencyId: string) => (state: ReduxState): RelativeCurrency | null => {
  return state.currency.currencyById[currencyId] || null;
};

/**
 * Возвращает список уведомлений валюты
 * @param observedCurrencyId идентификатор валюты
 */
export const getCurrencyObservers = (observedCurrencyId: string) => (state: ReduxState): CurrencyObserver[] => {
  return state.user.observers.filter((observer) => observer.observedCurrencyId === observedCurrencyId);
};
