import React, { memo, useEffect } from 'react';
import { Placeholder, Div, Button } from '@overrided-vkui';
import { makeStyles } from '@material-ui/styles';
import MiniPost from '../../atomic/MiniPost';
import { withFeed, WithFeedInjectedProps } from '../../hocs/withFeed';
import { Spinner } from '@vkontakte/vkui';
import InfiniteScroll from 'react-infinite-scroll-component';
import { openWallPost } from '../../../utils/openWallPost';
import { L } from '../../../lang/L';
import { useAppContext } from 'src/components/AppContext';

const useStyles = makeStyles({
  postsGrid: {
    padding: 24,
    '& > * + *': {
      marginTop: 16,
    },
  },
  statusPlaceholder: {
    background: 'transparent',
    minHeight: 200,
  },
});

interface FeedSectionDesktopProps extends WithFeedInjectedProps {}

const FeedSectionDesktop = memo((props: FeedSectionDesktopProps) => {
  const { feedError, feedLoading, loadFeedNextPage, feed } = props;

  const { statEvents } = useAppContext();

  useEffect(() => {
    statEvents.push('navigation_go', { screen: 'feed' });
  }, []); // eslint-disable-line

  const posts = feed || [];

  const mc = useStyles();

  return (
    <InfiniteScroll dataLength={posts.length} hasMore={true} next={loadFeedNextPage} loader={null}>
      <Div className={mc.postsGrid}>
        {posts.map((miniPost, index) => (
          <MiniPost
            key={miniPost.id}
            post={miniPost}
            onClick={() => {
              openWallPost(miniPost.ownerId, miniPost.id);
              statEvents.push('feed_post_open', { index, owner_id: miniPost.ownerId, post_id: miniPost.id });
            }}
          />
        ))}
      </Div>
      {feedLoading ? (
        <Placeholder className={mc.statusPlaceholder}>
          <Spinner size="regular" />
        </Placeholder>
      ) : feedError ? (
        <Placeholder
          className={mc.statusPlaceholder}
          action={<Button onClick={loadFeedNextPage}>{L.t('common:error_try_again_btn')}</Button>}
        >
          {L.t('feed_fetch_error')}
        </Placeholder>
      ) : posts.length === 0 ? (
        <Placeholder className={mc.statusPlaceholder}>{L.t('feed_has_no_posts')}</Placeholder>
      ) : null}
    </InfiniteScroll>
  );
});

export default withFeed(FeedSectionDesktop);
