export enum DynamicDirection {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
}

export interface CurrencyObserver {
  id: string;
  baseCurrencyId: string;
  observedCurrencyId: string;
  rate: number;
  direction: DynamicDirection;
}

export interface Currency {
  id: string;
  title: string;
  symbol: string;
}

export interface RelativeCurrency extends Currency {
  rate: number;
  prevRate: number;
  series?: CurrencySeries;
}

export interface BaseRelatedCurrency extends Currency {
  rate: number;
  prevRate: number;
  series?: CurrencySeries;
  base: {
    id: string;
    symbol: string;
  };
}

export interface CurrencySeriesRate {
  date: number;
  rate: number;
}

export interface CurrencySeries {
  openRate: number;
  minRate: number;
  maxRate: number;
  rates: CurrencySeriesRate[];
  timestamp: number;
}
