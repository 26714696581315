import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import Input, { InputProps } from '@overrided-vkui/Input';
import { getDigitsAfterDotLimit } from '../../utils/formatter';

interface RateInputProps extends Omit<InputProps, 'onChange' | 'value' | 'defaultValue' | 'type'> {
  value: number;
  currentRate: number;
  onChange(value: number): void;
  rateSymbol: string;
}

const RateInput: React.FC<RateInputProps> = ({
  onChange,
  value,
  currentRate,
  rateSymbol,
  onFocus,
  onBlur,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);

  const [inputValue, setInputValue] = useState(String(value));

  useEffect(() => {
    focused && onChange(Number(inputValue));
  }, [inputValue, focused, onChange]);

  useEffect(() => {
    !focused && setInputValue(String(value));
  }, [value, focused]);

  return (
    <NumberFormat
      value={inputValue}
      onValueChange={({ value }) => setInputValue(value)}
      displayType="input"
      inputMode="decimal"
      thousandSeparator=" "
      customInput={Input}
      decimalSeparator=","
      decimalScale={getDigitsAfterDotLimit(String(value))}
      allowNegative={false}
      allowEmptyFormatting={false}
      isNumericString
      suffix={' ' + rateSymbol}
      isAllowed={(values) => {
        const { floatValue = 0 } = values;
        return floatValue >= 0 && floatValue <= 1000 * currentRate;
      }}
      onFocus={(e) => {
        onFocus && onFocus(e);
        setFocused(true);
      }}
      onBlur={(e) => {
        onBlur && onBlur(e);
        setFocused(false);
      }}
      {...rest}
    />
  );
};

export default RateInput;
