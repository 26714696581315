import React, { useCallback, useEffect, memo, useRef } from 'react';
import Icon28BackspaceOutline from '@vkontakte/icons/dist/28/backspace_outline';
import { makeStyles } from '@material-ui/styles';
import { Button, Div } from '@vkontakte/vkui';

const useStyles = makeStyles({
  // важно для адаптики, значения в относительных величинах (em)
  keyboard: {
    flex: 3,
    paddingTop: '0.675em',
    paddingBottom: '0.675em',
    boxSizing: 'border-box',
  },
  keyboard__in: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-0.225em',
    height: '100%',
  },
  keyboard__key: {
    color: 'var(--text_primary)',
    fontSize: 'inherit',
    flex: '1 0 30%',
    padding: '0.225em',
    boxSizing: 'border-box',
    '& .Button': {
      height: '100%',
      width: '100%',
      color: 'inherit',
      fontSize: 'inherit',
    },
    '& .Button__content': {
      fontSize: '1.35em',
      lineHeight: 0,
      fontFamily: 'var(--font-tt)',
    },
    '& .Button__in': {
      height: 0,
    },
  },
  keyboard__key_dot: {
    top: '-0.25em',
    position: 'relative',
    display: 'block',
    fontSize: '1.35em',
    '&:before': {
      content: `","`,
    },
  },
  keyboard__key_delete: {
    height: '1.2em!important',
    width: '1.2em!important',
    margin: '-.6em auto',
    '& > svg': {
      height: '1.2em',
      width: '1.2em',
    },
  },
});

const RESET_LONG_PRESS_DURATION = 400;

export enum ConverterKeyboardKey {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  DOT = 'dot',
  BACKSPACE = 'backspace',
}

interface KeyboardProps {
  onKeyPress(key: ConverterKeyboardKey): void;
  onReset(): void;
}

const ConverterKeyboard: React.FC<KeyboardProps> = memo(({ onKeyPress, onReset }) => {
  // вызываем onKeyPress с помощью системной клавиатуры

  useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      if (e.key === '.' || e.key === ',') {
        onKeyPress(ConverterKeyboardKey.DOT);
      } else if (e.key === 'Backspace') {
        onKeyPress(ConverterKeyboardKey.BACKSPACE);
      } else if (e.key >= '0' && e.key <= '9') {
        onKeyPress(Number(e.key));
      }
    };

    window.addEventListener('keydown', keyDownListener);
    return () => window.removeEventListener('keydown', keyDownListener);
  }, [onKeyPress]);

  // вызываем onKeyPress с помощью виртуальной клавиатуры

  const onButtonClickHandler = useCallback(
    (e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
      e.preventDefault();
      onKeyPress(e.currentTarget.dataset.key as ConverterKeyboardKey);
    },
    [onKeyPress]
  );

  // вызываем onReset по долгому нажатию backspace виртуальной клавиатуры

  const backspaceRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const backspaceEl = backspaceRef.current;

    if (!backspaceEl) {
      return;
    }

    let timeoutId: NodeJS.Timeout | null = null;

    const onTouchStart = (e: TouchEvent) => {
      if (e.touches.length === 1) {
        timeoutId = setTimeout(onReset, RESET_LONG_PRESS_DURATION);
      }
    };

    const onTouchEnd = (e: TouchEvent) => {
      if (e.touches.length !== 1) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      }
    };

    const onMouseDown = () => {
      timeoutId = setTimeout(onReset, RESET_LONG_PRESS_DURATION);
    };

    const onMouseUp = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };

    backspaceEl.addEventListener('touchstart', onTouchStart);
    backspaceEl.addEventListener('touchend', onTouchEnd);
    backspaceEl.addEventListener('mousedown', onMouseDown);
    backspaceEl.addEventListener('mouseup', onMouseUp);

    return () => {
      backspaceEl.removeEventListener('touchstart', onTouchStart);
      backspaceEl.removeEventListener('touchend', onTouchEnd);
      backspaceEl.removeEventListener('mousedown', onMouseDown);
      backspaceEl.removeEventListener('mouseup', onMouseUp);
    };
  }, [onReset, backspaceRef]);

  // рендер

  const mc = useStyles();

  const renderButton = useCallback(
    (children: string, key: ConverterKeyboardKey) => {
      return (
        <div className={mc.keyboard__key}>
          <Button
            mode="secondary"
            data-key={key}
            onTouchEnd={onButtonClickHandler}
            onMouseUp={onButtonClickHandler}
            stopPropagation={false}
          >
            {children}
          </Button>
        </div>
      );
    },
    [onButtonClickHandler, mc.keyboard__key]
  );

  return (
    <Div className={mc.keyboard}>
      <div className={mc.keyboard__in}>
        {renderButton('1', ConverterKeyboardKey.ONE)}
        {renderButton('2', ConverterKeyboardKey.TWO)}
        {renderButton('3', ConverterKeyboardKey.THREE)}
        {renderButton('4', ConverterKeyboardKey.FOUR)}
        {renderButton('5', ConverterKeyboardKey.FIVE)}
        {renderButton('6', ConverterKeyboardKey.SIX)}
        {renderButton('7', ConverterKeyboardKey.SEVEN)}
        {renderButton('8', ConverterKeyboardKey.EIGHT)}
        {renderButton('9', ConverterKeyboardKey.NINE)}
        <div className={mc.keyboard__key}>
          <Button
            mode="secondary"
            data-key={ConverterKeyboardKey.DOT}
            onTouchEnd={onButtonClickHandler}
            onMouseUp={onButtonClickHandler}
            stopPropagation={false}
          >
            <span className={mc.keyboard__key_dot} />
          </Button>
        </div>
        {renderButton('0', ConverterKeyboardKey.ZERO)}
        <div className={mc.keyboard__key}>
          <Button
            mode="secondary"
            data-key={ConverterKeyboardKey.BACKSPACE}
            onTouchEnd={onButtonClickHandler}
            onMouseUp={onButtonClickHandler}
            stopPropagation={false}
            getRootRef={(ref) => {
              backspaceRef.current = ref;
            }}
          >
            <Icon28BackspaceOutline className={mc.keyboard__key_delete} />
          </Button>
        </div>
      </div>
    </Div>
  );
});

export default ConverterKeyboard;
