import createRouter5 from 'router5';
import loggerPlugin from 'router5-plugin-logger';
import browserPlugin from 'router5-plugin-browser';
import { routes, RootRoute } from './routes';

export const createRouter = () => {
  const router = createRouter5(routes, {
    defaultRoute: RootRoute.CURRENCY,
  });

  router.usePlugin(loggerPlugin);
  router.usePlugin(
    browserPlugin({
      useHash: true,
      base: window.location.search,
    })
  );

  return router;
};
