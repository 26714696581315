import React, { memo } from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

const useStyles = makeStyles({
  box: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  box__content: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
});

interface RatioBlockProps {
  w: number;
  h: number;
}

const RatioBlock: React.FC<RatioBlockProps> = memo((props) => {
  const { children, w, h } = props;
  const mc = useStyles();

  return (
    <div className={mc.box} style={{ paddingTop: `${(h / w) * 100}%` }}>
      <div className={mc.box__content}>{children}</div>
    </div>
  );
});

export default RatioBlock;
