import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import CurrencyIcon from '../../atomic/CurrencyIcon';
import c from 'classnames';
import { getPlatformClassName } from '@overrided-vkui';

const useStyles = makeStyles({
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    width: '100%',
    justifyContent: 'flex-end',
    '&--desktop $label__name': {
      fontSize: 15,
    },
    '&--desktop $label__title': {
      color: 'var(--steel_gray_400)',
    },
  },
  label__content: {
    marginLeft: 12,
    overflow: 'hidden',
  },
  label__name: {
    fontSize: 17,
    lineHeight: '22px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label__title: {
    fontSize: 13,
    lineHeight: '16px',
    color: 'var(--segmented_control_tint)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

interface CurrencyLabelProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  title: string;
}

const CurrencyLabel: React.FC<CurrencyLabelProps> = memo((props) => {
  const { id, title, className, ...restProps } = props;
  const mc = useStyles();

  return (
    <div className={c(getPlatformClassName(mc.label), className)} {...restProps}>
      <div className={mc.label__content}>
        <div className={mc.label__name}>{id}</div>
        <div className={mc.label__title}>{title}</div>
      </div>
      <CurrencyIcon id={id} size={40} />
    </div>
  );
});

export default CurrencyLabel;
