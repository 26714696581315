import { CurrencySeries, RelativeCurrency } from '../../types/currency';
import { oerProxyApi } from '../oer-proxy-api';
import { formatToUTCDate } from '../formatter';
import { isCryptoCurrency } from '../crypto';
import { L } from '../../lang/L';
import currencySymbolMap
  from '../../assets/dictionaries/currencySymbolMap.json';
import { ApolloClient } from '@apollo/client';
import {
  AddCurrencyObserverInput,
  AddObserverDocument,
  AddObserverMutation,
  AddObserverMutationVariables, RemoveObserverDocument,
  RemoveObserverMutation,
  RemoveObserverMutationVariables
} from '../../gql/generated/types';
import { VkClientInstance } from './vkClient/types';

interface IConProps {
  apolloClient: ApolloClient<any>;
  vkClient: VkClientInstance;
  isOkClient: boolean;
  isCryptoCurrencyIncluded: boolean;
}

const DAY_MS = 1000 * 60 * 60 * 24;

// Интервал получаемых данных для графика (30 дней).
const SERIES_PERIOD = 30 * DAY_MS;

// Валюта, относительно которой получаем курсы валют.
const RELATIVE_CURRENCY_BASE = 'BTC';

/**
 * Текущая дата (запоминаем на период сессии).
 */
const today = new Date();

/**
 * Класс, который отвечает за работу с API.
 */
export class API {
  private readonly apolloClient: ApolloClient<any>;
  private readonly vkClient: VkClientInstance;
  private readonly isOkClient: boolean;
  private readonly isCryptoCurrencyIncluded: boolean;

  constructor(props: IConProps) {
    const { apolloClient, vkClient, isOkClient, isCryptoCurrencyIncluded } = props;
    this.apolloClient = apolloClient;
    this.vkClient = vkClient;
    this.isOkClient = isOkClient;
    this.isCryptoCurrencyIncluded = isCryptoCurrencyIncluded;
  }

  /**
   * Оставляет только те идентификаторы валют, которые доступны пользователю.
   * @param ids
   * @private
   */
  private filterCurrencyIds = (ids: string[]): string[] => {
    return ids.filter(this.isCurrencyAllowed);
  };

  /**
   * Возвращает true если использование этой валюты пользователем разрешено.
   * @param id
   */
  private isCurrencyAllowed = (id: string): boolean => {
    return this.isCryptoCurrencyIncluded || !isCryptoCurrency(id);
  }

  /**
   * Добавляет новый наблюдатель за валютой.
   * @param input
   */
  addCurrencyObserver = async (input: AddCurrencyObserverInput) => {
    const { data } = await this
      .apolloClient
      .mutate<AddObserverMutation, AddObserverMutationVariables>({
        mutation: AddObserverDocument,
        variables: { input }
      });

    if (!data) {
      throw new Error('Unreachable data');
    }
    return data.addCurrencyObserver;
  };

  /**
   * Получает информацию о всех валютах. Исключает криптовалюты если это
   * необходимо.
   */
  getCurrencies = async (): Promise<RelativeCurrency[]> => {
    const dayAgo = new Date(today.getTime() - DAY_MS);
    const twoDaysAgo = new Date(today.getTime() - 2 * DAY_MS);

    const [currencyRates, prevCurrencyRates] = await Promise.all([
      // Получаем данные касательно последних курсов.
      oerProxyApi('latest.json', {
        base: RELATIVE_CURRENCY_BASE,
        show_alternative: 1
      }),
      // Получаем курсы валют за предыдущий день.
      oerProxyApi('historical/:date.json', {
        date: formatToUTCDate(dayAgo),
        base: RELATIVE_CURRENCY_BASE,
        show_alternative: 1
      })
        // TODO: Хз о чем тут речь. Кажется, что есть кейсы, когда за
        //  предыдущий день курсы получить нельзя, поэтому получаем курсы за 2
        //  дня назад.
        .catch(() => oerProxyApi('historical/:date.json', {
          date: formatToUTCDate(twoDaysAgo),
          base: RELATIVE_CURRENCY_BASE,
          show_alternative: 1
        }))
    ]);

    // Если криптовалюты запрещены, то их необходимо отбросить.
    return Object
      .entries(currencyRates.rates)
      .reduce<RelativeCurrency[]>((acc, [abbr, rate]) => {
        if (this.isCryptoCurrencyIncluded || !isCryptoCurrency(abbr)) {
          acc.push({
            id: abbr,
            title: L.t(['item_title', ''], { context: abbr }),
            symbol: currencySymbolMap[abbr as keyof typeof currencySymbolMap] || abbr,
            rate,
            prevRate: prevCurrencyRates.rates[abbr] || rate
          });
        }
        return acc;
      }, []);
  };

  /**
   * Получает информацию о динамике валют за последние 30 дней
   * @param currencyIds список валют
   */
  getCurrencySeries = async (
    currencyIds: string[]
  ): Promise<(CurrencySeries | null)[]> => {
    const filteredCurrencies = this.filterCurrencyIds(currencyIds);

    if (filteredCurrencies.length === 0) {
      return new Array(currencyIds.length).fill(null);
    }
    const periodAgo = new Date(today.getTime() - SERIES_PERIOD);
    const currencySeries = await oerProxyApi('time-series.json', {
      symbols: filteredCurrencies.join(','),
      start: formatToUTCDate(periodAgo),
      end: formatToUTCDate(today),
      base: RELATIVE_CURRENCY_BASE,
      show_alternative: 1
    });

    return currencyIds.map(currencyId => {
      if (
        typeof currencySeries.rates[currencySeries.end_date][currencyId] === 'undefined'
      ) {
        return null;
      }
      const timestamp = currencySeries.timestamp
        ? currencySeries.timestamp * 1000
        : Date.now();
      const rates = Object
        .entries(currencySeries.rates)
        .map(([date, { [currencyId]: rate }]) => ({
          date: new Date(date).getTime(),
          rate
        }));
      const openRate = rates[0].rate;

      const { minRate, maxRate } = rates.reduce(
        (acc, { rate }) => {
          acc.minRate = Math.min(acc.minRate, rate);
          acc.maxRate = Math.max(acc.maxRate, rate);
          return acc;
        },
        { minRate: openRate, maxRate: openRate }
      );

      return { rates, timestamp, openRate, maxRate, minRate };
    });
  };

  /**
   * Удаляет наблюдатель за валютой.
   * @param observerId
   */
  removeCurrencyObserver = async (observerId: string) => {
    const { data } = await this
      .apolloClient
      .mutate<RemoveObserverMutation, RemoveObserverMutationVariables>({
        mutation: RemoveObserverDocument,
        variables: { observerId }
      });

    if (!data) {
      throw new Error('Unreachable data');
    }
    return data.removeCurrencyObservers;
  };

  /**
   * Устанавливает валюты в виджет.
   * @param currencyIds
   * @param baseCurrencyId
   */
  setWidgetCurrencies = async (
    currencyIds: string[],
    baseCurrencyId: string
  ) => {
    if (this.isOkClient) {
      // В одноклассниках нельзя вызвать установку виджета, автоматически считаем действие успешным,
      // чтобы не вызвать ошибку в работе приложения
      return Promise.resolve(true);
    }
    await this.vkClient.call('exchangeRates.setUserCurrencyList', {
      currencies: this.filterCurrencyIds(currencyIds).join(),
      base_currency: baseCurrencyId
    });
  };
}