import { getDigitsAfterDotLimit } from '../../../utils/formatter';

export const isRateInputValueAllowed = (value: string): boolean => {
  const [integerPart = '0', realPart = ''] = value.split('.');

  // Не более 10 знаков в целой части.
  // Если целая часть меньше единицы, то дробная часть содержит не более 2 значащих знаков,
  // иначе максимум 2 знака в дробной части.
  return integerPart.length <= 10 && realPart.length <= getDigitsAfterDotLimit(value);
};
