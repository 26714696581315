import React, { memo, useCallback } from 'react';
import { Panel, PanelHeaderSubmit } from '@vkontakte/vkui';
import { PanelHeader } from '@overrided-vkui';
import { L } from '../../../lang/L';
import ObserversContent from './_ObserversContent';
import { useAppContext } from 'src/components/AppContext';

interface ObserversPanelMobileProps {
  id: string;
  onBack(): void;
  openCurrencyObserversModal(currencyId: string): void;
  openCurrencyModal(currencyId: string): void;
}

const ObserversPanelMobile = memo((props: ObserversPanelMobileProps) => {
  const { id: panelId, onBack, openCurrencyObserversModal, openCurrencyModal } = props;

  const { statEvents } = useAppContext();

  const wrappedOpenCurrencyObserversModal = useCallback(
    (currencyId: string) => {
      openCurrencyObserversModal(currencyId);
      statEvents.push('go_notifications', { name: currencyId, screen: 'notification_list' });
    },
    [openCurrencyObserversModal, statEvents]
  );

  return (
    <Panel id={panelId}>
      <PanelHeader left={<PanelHeaderSubmit onClick={onBack}>{L.t('common:done')}</PanelHeaderSubmit>}>
        {L.t('observers_page_title')}
      </PanelHeader>
      <ObserversContent
        openCurrencyObserversModal={wrappedOpenCurrencyObserversModal}
        openCurrencyModal={openCurrencyModal}
      />
    </Panel>
  );
});

export default ObserversPanelMobile;
