import { ReduxState } from './types';
import { Store, createStore, combineReducers } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';

import { appConfigReducer } from './reducers/app-config';
import { storageReducer } from './reducers/storage';
import { currencyReducer } from './reducers/currency';
import { userReducer } from './reducers/user';

const reducers = combineReducers<ReduxState>({
  appConfig: appConfigReducer,
  storage: storageReducer,
  currency: currencyReducer,
  user: userReducer,
});

function createReduxStore(state?: Partial<ReduxState>): Store<ReduxState> {
  return createStore(reducers, state, devToolsEnhancer({}));
}

export default createReduxStore;
