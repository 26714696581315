import React, { useState, useCallback, memo, useMemo } from 'react';
import { useSelector } from '../../hooks/useSelector';
import { getCurrency } from '../../redux/selectors';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import { makeStyles } from '@material-ui/styles';
import ObserverBadgeList from '../atomic/ObserverBadgeList';
import useBaseCurrency from '../../hooks/currency/useBaseCurrency';
import { userActions } from '../../redux/reducers/user';
import { useActions } from '../../hooks/useActions';
import RateInput from '../atomic/RateInput';
import DynamicInput from '../atomic/DynamicInput';
import { DynamicDirection } from '../../types/currency';
import {
  Button,
  Div,
  ModalPage,
  ModalPageHeader,
  isDesktop,
  PanelHeaderBack,
  PanelHeaderButton,
  withModalPageOverlayWrapper,
  getPlatformClassName,
} from '@overrided-vkui';
import c from 'classnames';
import useAsync from '../../hooks/useAsync';
import useAPI from '../tools/APIProvider/useAPI';
import { AddCurrencyObserverInput } from '../../gql/generated/types';
import { formatRate } from '../../utils/formatter';
import { L } from '../../lang/L';
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss';
import { Taptic } from '../../utils/taptic';
import withCurrencyObservers, { WithCurrencyObserversInjectedProps } from '../hocs/withCurrencyObservers';
import { useAppContext } from '../AppContext';

const useStyles = makeStyles({
  list: {
    paddingBottom: 0,
    '&--desktop': {
      paddingTop: 24,
    },
  },
  inputLayout: {
    '& .FormLayout__container': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: 12,
      '& .FormLayout__row': {
        gridColumn: '1 / -1',
        '&:nth-child(1),&:nth-child(2)': {
          gridColumn: 'initial',
          paddingBottom: 0,
        },
        '&:nth-child(3)': {
          paddingTop: 0,
        },
      },
    },
  },
  inputGroup: {
    padding: 0,
    paddingBottom: 0,
    '& .Input': {
      margin: '0!important',
    },
  },
  inputGroup__title: {
    fontSize: 14,
    lineHeight: '18px',
    color: 'var(--text_subhead)',
    marginBottom: 8,
  },
  inputGroup__bottom: {
    fontSize: 13,
    lineHeight: '16px',
    color: 'var(--text_secondary)',
    marginTop: 8,
  },
  inputGroup__bottom_error: {
    color: 'var(--destructive)',
  },
  inputLayout__button: {
    margin: '0!important',
    // width: '100%!important',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    '&--mobile': {
      paddingTop: 0,
    },
    '&--desktop': {
      borderTop: '1px solid var(--steel_gray_80)',
    },
    alignItems: 'center',
  },
  modalHeader: {
    '& .ModalPageHeader__left,& .ModalPageHeader__right': {
      alignSelf: 'flex-start',
      flexGrow: 1,
      flexBasis: 0,
    },
    '& .ModalPageHeader__content': {
      width: 'auto',
    },
    '& .ModalPageHeader__content-in': {
      display: 'block',
      textAlign: 'center',
    },
  },
});

const OBSERVERS_COUNT_LIMIT = 10;

interface CurrencyObserversModalProps extends WithCurrencyObserversInjectedProps {
  id: string;
  currencyId: string;
  onClose(): void;
}

const CurrencyObserversModal = memo((props: CurrencyObserversModalProps) => {
  const { id: modalId, onClose, currencyId, currencyObservers, removeObserver, removingObserverIds } = props;

  const addObserverAction = useActions(userActions.addObserver);
  const { addCurrencyObserver } = useAPI();
  const { statEvents } = useAppContext();
  const { baseCurrency, calcBaseRelatedCurrency } = useBaseCurrency();

  const foundCurrency = useSelector(getCurrency(currencyId));
  const currency = foundCurrency && calcBaseRelatedCurrency(foundCurrency);

  const [rateValue, setRateValue] = useState(currency?.rate || 1);
  const [dynamicValue, setDynamicValue] = useState(0); // 0..100

  const updateValuesByRate = useCallback(
    (newRateValue: number) => {
      if (!currency) return;
      setRateValue(newRateValue);
      setDynamicValue((newRateValue / currency.rate - 1) * 100);
    },
    [currency]
  );

  const updateValuesByDynamic = useCallback(
    (newDynamicValue: number) => {
      if (!currency) return;
      setDynamicValue(newDynamicValue);
      setRateValue(currency.rate * (1 + newDynamicValue / 100));
    },
    [currency]
  );

  const observers = useMemo(
    () => currencyObservers.find((currencyObserver) => currencyObserver.currencyId === currencyId)?.observers || [],
    [currencyObservers, currencyId]
  );

  const [addObserverError, setAddObserverError] = useState<string | null>(null);
  const clearAddObserverError = useCallback(() => setAddObserverError(null), []);
  const addObserverInputStyle = addObserverError !== null ? 'error' : 'default';

  const [addObserver, addObserverStatus] = useAsync(() => {
    if (observers.length >= OBSERVERS_COUNT_LIMIT) {
      setAddObserverError(
        L.t('observers_limit_message', {
          observers: L.t('observers', { count: OBSERVERS_COUNT_LIMIT }),
        })
      );
      return Promise.resolve();
    }

    const input: AddCurrencyObserverInput = {
      baseCurrencyId: baseCurrency.id,
      observedCurrencyId: currencyId,
      rate: rateValue,
      direction: dynamicValue >= 0 ? DynamicDirection.Positive : DynamicDirection.Negative,
    };

    if (observers.find((observer) => observer.rate === input.rate && observer.direction === input.direction)) {
      setAddObserverError(L.t('observer_already_added_error'));
      return Promise.resolve();
    }

    return addCurrencyObserver(input)
      .then((id) => addObserverAction({ ...input, id }))
      .then(() => {
        statEvents.push('notifications_added', { name: currencyId });
      })
      .then(() => {
        updateValuesByDynamic(0);
      })
      .catch(() => setAddObserverError(L.t('observer_add_error')));
  });

  /* Рендер */

  const mc = useStyles();

  return (
    <ModalPage
      id={modalId}
      onClose={onClose}
      header={
        <ModalPageHeader
          className={mc.modalHeader}
          left={isDesktop && <PanelHeaderBack onClick={onClose} />}
          right={
            !isDesktop && (
              <PanelHeaderButton onClick={onClose}>
                <Icon24Dismiss />
              </PanelHeaderButton>
            )
          }
        >
          {L.t('observers_title', { currency: currencyId })}
        </ModalPageHeader>
      }
    >
      {observers.length > 0 && (
        <Div className={getPlatformClassName(mc.list)}>
          <ObserverBadgeList
            removeObserver={(observerId) => removeObserver(observerId, currencyId)}
            removingObserverIds={removingObserverIds}
            observers={observers}
          />
        </Div>
      )}
      {currency && (
        <>
          <Div>
            <FormLayout className={mc.inputLayout}>
              <Div className={mc.inputGroup}>
                <div className={mc.inputGroup__title}>{L.t('observer_rate')}</div>
                <RateInput
                  value={rateValue}
                  currentRate={currency.rate}
                  onChange={updateValuesByRate}
                  rateSymbol={baseCurrency.symbol}
                  status={addObserverInputStyle}
                  onFocus={clearAddObserverError}
                />
              </Div>
              <Div className={mc.inputGroup}>
                <div className={mc.inputGroup__title}>{L.t('observer_dynamic')}</div>
                <DynamicInput
                  value={dynamicValue}
                  onChange={updateValuesByDynamic}
                  status={addObserverInputStyle}
                  onFocus={clearAddObserverError}
                  onSwap={() => {
                    Taptic.impactOccured('light');
                    statEvents.push('notifications_swap', { name: currencyId });
                  }}
                />
              </Div>
              <div>
                {addObserverInputStyle === 'error' ? (
                  <div className={c(mc.inputGroup__bottom, mc.inputGroup__bottom_error)}>{addObserverError}</div>
                ) : (
                  <div className={mc.inputGroup__bottom}>
                    {L.t('observer_current_rate', {
                      current_rate: formatRate(currency.rate, currency.base.symbol),
                    })}
                  </div>
                )}
              </div>
            </FormLayout>
          </Div>
          <Div className={getPlatformClassName(mc.footer)}>
            <Button
              size={isDesktop ? 'l' : 'xl'}
              onClick={addObserver}
              className={mc.inputLayout__button}
              disabled={addObserverStatus === 'loading'}
              onFocus={clearAddObserverError}
            >
              {L.t('observer_add')}
            </Button>
          </Div>
        </>
      )}
    </ModalPage>
  );
});

export default withCurrencyObservers(withModalPageOverlayWrapper(CurrencyObserversModal));
