import { ofType, unionize, UnionOf } from 'unionize';
import { unionizeConfig } from '../utils';
import { CurrencyObserver } from '../../types/currency';

export type UserState = {
  areNotificationsEnabled: boolean;
  isFirstVisit: boolean;
  observers: CurrencyObserver[];
};

export const userActions = unionize(
  {
    setObservers: ofType<CurrencyObserver[]>(),
    addObserver: ofType<CurrencyObserver>(),
    removeObserver: ofType<string>(),
    setAreNotificationsEnabled: ofType<boolean>(),
  },
  unionizeConfig
);

type UserAction = UnionOf<typeof userActions>;

const initialState: UserState = {
  isFirstVisit: false,
  areNotificationsEnabled: false,
  observers: [],
};

export function userReducer(state: UserState = initialState, action: UserAction) {
  return userActions.match(action, {
    default: () => state,
    setObservers: (observers) => ({ ...state, observers }),
    addObserver: (observer) => ({
      ...state,
      observers: state.observers.concat(observer),
    }),
    removeObserver: (observerId) => ({
      ...state,
      observers: state.observers.filter((observer) => observer.id !== observerId),
    }),
    setAreNotificationsEnabled: (areNotificationsEnabled) => ({ ...state, areNotificationsEnabled }),
  });
}
