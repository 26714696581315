import React, { memo } from 'react';
import { MiniPostAttachment as MiniPostAttachmentProps } from '../../../types/miniPost';
import MiniPostAttachmentPhoto from './MiniPostAttachmentPhoto';
import MiniPostAttachmentVideo from './MiniPostAttachmentVideo';
import MiniPostAttachmentArticle from './MiniPostAttachmentArticle';

const miniPostAttachmentTypeComponentMap = {
  photo: MiniPostAttachmentPhoto,
  video: MiniPostAttachmentVideo,
  article: MiniPostAttachmentArticle,
};

const MiniPostAttachment = memo((props: MiniPostAttachmentProps) => {
  const MiniPostAttachmentComponent =
    miniPostAttachmentTypeComponentMap[props.type as keyof typeof miniPostAttachmentTypeComponentMap];

  if (!MiniPostAttachmentComponent) {
    return null;
  }

  return <MiniPostAttachmentComponent {...(props as any)} />;
});

export default MiniPostAttachment;
