import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import Icon24CoinsOutline from '../../assets/coins_outline_24.svg';
import { getCurrencyIconUrl } from '../../utils/currency-icon-url';
import { getPlatformClassName } from '@overrided-vkui';

const useStyles = makeStyles({
  currencyIcon: {
    position: 'relative',
    height: '1em',
    width: '1em',
    background: `url(${Icon24CoinsOutline}) no-repeat`,
    backgroundColor: 'var(--input_background)',
    backgroundPosition: '50% 50%',
    backgroundSize: '60%',
    borderRadius: '50%',
    '& > div': {
      height: '1em',
      width: '1em',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      borderRadius: '50%',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      border: `.5px solid var(--field_border)`,
      borderRadius: '50%',
      display: 'block',
      zIndex: 2,
    },
    '&--desktop:before': {
      border: `.5px solid rgba(0, 0, 0, 0.04)`,
    },
  },
});

interface CurrencyIconProps {
  id: string;
  size: number | string;
}

const CurrencyIcon: React.FC<CurrencyIconProps> = memo(({ id, size }) => (
  <div className={getPlatformClassName(useStyles().currencyIcon)} style={{ fontSize: size }}>
    <div style={{ backgroundImage: `url(${getCurrencyIconUrl(id)})` }} />
  </div>
));

export default CurrencyIcon;
