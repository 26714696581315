import {ApolloClient, HttpLink, InMemoryCache} from '@apollo/client';

interface CreateApolloClientOptions {
  httpURI: string;
  launchParams: string;
  useOldAuthHeader: boolean;
}

/**
 * Creates ApolloClient with websocket- and http-link
 * @param {CreateApolloClientOptions} options
 * @returns {ApolloClient<any>}
 */
export function createApolloClient(options: CreateApolloClientOptions): ApolloClient<any> {
  const { httpURI, launchParams } = options;
  // We can authenticate users only with launch parameters sent from VKontakte.
  // To check them on server side, we send them in header
  const link = new HttpLink({
    uri: httpURI,
    headers: {[options.useOldAuthHeader ? 'x-launch-params' : 'Authorization']: launchParams},
  });

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
}
