import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import Icon24CheckCircleOn from '@vkontakte/icons/dist/24/check_circle_on';
import { Currency } from '../../types/currency';
import useForceUpdate from '../../hooks/useForceUpdate';
import { makeStyles } from '@material-ui/styles';
import {
  ModalPage,
  Search,
  ModalPageHeader,
  getPlatformClassName,
  withModalPageOverlayWrapper,
  PanelHeaderButton,
  isDesktop,
} from '@overrided-vkui';
import { L } from '../../lang/L';
import CurrenciesSearchList from '../partial/CurrenciesSearchList';
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss';

const useStyles = makeStyles({
  list: {
    margin: '4px 0',
    '&--desktop': {
      margin: '10px 0',
    },
  },
  search: {
    position: 'sticky',
    top: 0,
    zIndex: 9,
  },
  checkIcon: {
    '--accent': 'var(--blue_400)',
    '& > svg': {
      overflow: 'visible',
    },
  },
});

interface CurrencySelectModalPageProps {
  id: string;
  header?: React.ReactNode;
  onClose(selectedCurrencyId?: string): void;
  selectedCurrencyId?: string;
  onSearchFocus?(): void;
  onSearchChange?(value: string): void;
}

export const CurrencySelectModalPage: React.FC<CurrencySelectModalPageProps> = memo((props) => {
  const { id: modalId, header, onSearchChange, onClose, onSearchFocus, selectedCurrencyId } = props;

  const [searchValue, setSearchValue] = useState('');

  const mc = useStyles();

  const renderCurrencyAfter = useCallback(
    (currency: Currency) => (
      <div style={{ marginLeft: 10 }}>
        {currency.id === selectedCurrencyId && <Icon24CheckCircleOn fill="var(--accent)" className={mc.checkIcon} />}
      </div>
    ),
    [selectedCurrencyId, mc]
  );

  // Фиксируем высоту модалки независимо от высоты содержимого

  const contentRef = useRef<HTMLDivElement>(null);
  const scrollParentEl = contentRef.current?.parentElement?.parentElement || void 0;
  const forceUpdate = useForceUpdate();

  // форсим второй ререндер, чтобы пробросить scrollParentEl
  useEffect(forceUpdate, []); // eslint-disable-line

  return (
    <ModalPage
      id={modalId}
      fullHeight
      onClose={(): void => onClose(void 0)}
      header={
        header || (
          <ModalPageHeader
            noShadow={!isDesktop}
            right={
              !isDesktop && (
                <PanelHeaderButton onClick={() => onClose(void 0)}>
                  <Icon24Dismiss />
                </PanelHeaderButton>
              )
            }
          >
            {L.t('currency_selection')}
          </ModalPageHeader>
        )
      }
    >
      <div ref={contentRef} />
      <Search
        value={searchValue}
        placeholder={L.t('common:search_placeholder')}
        after={L.t('common:cancel')}
        className={mc.search}
        onFocus={onSearchFocus}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={(e): void => {
          onSearchChange && onSearchChange(e.target.value);
          setSearchValue(e.target.value);
        }}
      />
      <CurrenciesSearchList
        search={searchValue}
        skipBaseCurrency={false}
        sorted
        className={getPlatformClassName(mc.list)}
        onCurrencyClick={onClose}
        scrollParentEl={scrollParentEl}
        renderAfter={renderCurrencyAfter}
      />
    </ModalPage>
  );
});

export default withModalPageOverlayWrapper(CurrencySelectModalPage);
